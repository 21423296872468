
import { Vue, Component, Prop } from 'vue-property-decorator'

import { SelectOptions } from 'shared/types'
import { normalizeSelectOptions } from 'shared/util'

@Component
export default class MultiselectControl extends Vue {

	@Prop() label?: string
	@Prop() name!: string
	@Prop({ type: [Array, Object] }) options!: SelectOptions
	@Prop() value!: string[]

	get selectOptions() {
		return normalizeSelectOptions(this.options)
	}

	get checked() {
		return this.selectOptions.reduce((checked, option) => {
			checked[option.value] = this.value.includes(option.value)
			return checked
		}, {})
	}

	toggle(key: string) {
		const value = [...this.value]
		const i = value.indexOf(key)
		if(i === -1) {
			value.push(key)
		} else {
			value.splice(i, 1)
		}
		this.$emit('input', value)
	}

}
