
import { Component, Prop, Vue } from 'vue-property-decorator'
import { LogBookUser } from 'shared/state/LogBookUser'
import { Erg, ErgState } from 'shared/state/Erg'
import {
	RaceDefinition,
	Participant,
	RaceDefinitionBoat,
} from 'shared/types/erg/RaceDefinition'
import { GlobalConfig } from 'display/config'

@Component({ components: {} })
export default class Athlete extends Vue {
	@GlobalConfig() enableAnimation!: boolean
	@Prop() logbook_id!: string
	@Prop() fullInfo: any

	@Prop() boats!: RaceDefinitionBoat[]
	@Prop() boatName!: string // the Name to use if already looked up

	@ErgState() definition
	name = ''

	async created() {
		if (this.boatName) {
			this.name = this.boatName
		} else if (this.logbook_id) {
			LogBookUser.getUser(this.logbook_id)
				.then((result: any) => {
					this.name =
						result.data.first_name + ' ' + result.data.last_name
				})
				.catch(() => {
					const found: RaceDefinitionBoat[] = this.boats.filter(
						(b) => {
							return b.logbook_id === this.logbook_id
						}
					)
					this.name =
						found.length && found[0].name
							? found[0].name
							: 'Unknown'
				})
				.finally(() => {
					this.$emit('nameFound', this.logbook_id, this.name)
				})
		}
	}
}
