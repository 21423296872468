import { ClientType } from 'shared/types/config-server'

export function displayType(routeName?: string): ClientType {
	switch(routeName) {
		case 'race':
		case 'scroll':
		case 'participant':
			return 'race'
		case 'leaderboard':
		case 'summary':
			return 'leaderboard'
		default:
			return 'global'
	}
}
