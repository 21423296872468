import { config } from 'display/config'
import ViewportSize from 'display/ViewportSize'

export default function globalCssVars() {
	const globalTheme = config.vals.globalTheme

	return {
		'--global-font': globalTheme.font,
		'--viewonly-posX': ViewportSize.viewOnlyPosX,
		'--dark-blue': '#003A63',
		'--light-blue': '#0567C1',
		'--md-theme-default-accent': '#448aff',
		'--md-theme-default-accent-on-': 'rgba(68, 138, 255,0.38)'
	}
}
