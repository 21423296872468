
import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
import NewCompetitionForm from './NewCompetitionForm.vue'
import { Competitions, CompetitionsState } from 'shared/state/Competitions'

import { CompetitionType, RaceWithRac } from 'shared/types/competition'
import { LogBookUser } from 'shared/state/LogBookUser'
import { formatDate } from 'shared/util'
import TabRaceList from './TabRaceList.vue'
import TabRaceManagement from './TabRaceManagement.vue'
import { Erg, ErgState } from 'shared/state'
import { formatTime } from 'shared/util'
import { RaceListItem } from 'shared/types/racelistitem'
import { bus } from 'shared/state/Bus'
// import { config, GlobalConfig, GlobalTheme } from 'display/config'
import { config, GlobalConfig } from 'display/config'
import { displayType } from 'shared/util'
import Config from 'shared/components/config/_Config.vue'
import { AdminUser } from 'shared/types/logBook'

import globalCssVars from './../../cssVars'

import ServerRace from 'shared/components/ServerRace.vue'
import ServerPenConn from 'shared/components/ServerPenConn.vue'
import ConfigModal from 'display/components/ConfigModal.vue'
import { ServerPen, Server, ServerState } from 'shared/state'
import {
  RemoteControl,
  RemoteState,
  RemoteScreen,
} from 'shared/state/RemoteControl'
import { RemoteControlPen, RemoteStatePen } from 'shared/state/RemoteControlPen'
import {
  RemoteErg,
  RemoteErgState,
  RemoteErgPen,
  RemoteErgStatePen,
} from 'shared/state'
import { RaceDefinitionBoat } from 'shared/types/erg/RaceDefinition'
import { Instance, CompetitionInstance } from 'shared/types/logBook'
import { AthleteStatus } from 'shared/types/erg/AthleteStatus'
import { initialCap } from 'shared/util'
import DroppedModal from './DroppedModal.vue'

import {
  RemoteErgRaceAction,
  remoteErgRaceActions,
  RemoteErgRaceState,
  remoteErgRaceStates,
  RemoteErgRaceStatus,
  RaceDefinition,
} from 'shared/types/erg/index'
import { RaceState } from 'shared/types/erg'
import Race from './Race.vue'
import TabRaceListRaceDetail from './TabRaceListRaceDetail.vue'
import { eligable } from 'shared/util/eligable'
import { BootstrapVuePlugin, BvTableVariant } from 'bootstrap-vue'
import { formatUTCDate } from 'shared/util/formatDate'

interface ConnectionChoice extends AthleteStatus {
  choice: string
}

@Component({
  components: {
	NewCompetitionForm,
	TabRaceList,
	TabRaceManagement,
	ServerRace,
	ServerPenConn,
	ConfigModal,
	Config,
	DroppedModal
  },
})
export default class CompetitionDetail extends Vue {
  @Prop() competition!: CompetitionType
  @Prop() loading!: boolean

  @ServerState('state') serverState
  @GlobalConfig() lang
  @GlobalConfig() devMode
  @GlobalConfig() route
  @GlobalConfig() maxRacers!: number
  config = config.vals
  @CompetitionsState() currentInstance!: Instance
  @RemoteErgStatePen('state') penState!: RemoteErgRaceState
  @RemoteErgState() state!: RemoteErgRaceState
  @ErgState() definition!: RaceDefinition | null
  @ErgState() status!: string | null
  @RemoteErgState() ergsNumbered!: boolean
  @RemoteErgStatePen() athleteStatus!: AthleteStatus[]
  HP = RemoteErgPen

  ergMap: any = null
  race: RaceWithRac | null = null
  repeatPoll = true
  compState = ''
  get time() {
	return formatTime(Math.round(Erg.time))
  }

  liveUrl = '#'
  activeTab = 0
  connectedAthletes!: RaceDefinitionBoat[]
  runningRace: RaceDefinition | null = null
  updatingRace = false
  showRaceMgt = false
  raceServer = ''
  userAcceptedConnections: AthleteStatus[] = []
  userDroppedConnections: AthleteStatus[] = []
  problemConnections: AthleteStatus[] = []
  closingEntries = false
  thingToSend = {}

  get gotInstance(): boolean {
	return this.currentInstance.ergrace_url !== ''
  }

  get showPrepare() {
	return RemoteErg.state === 'loaded_race'
  }

  showJSON(o: object) {
		this.$bvModal.msgBoxOk(JSON.stringify(o, null, 4), {
			title: 'debug',
			size: 'lg',
			buttonSize: 'sm',
			okVariant: 'outline-primary',
			okTitle: 'Ok',
			bodyClass: 'bdy',
			footerClass: 'p-2',
			hideHeaderClose: false,
			centered: true
		})
	}

  onTab(race: RaceListItem) {
	this.activeTab = race ? 1 : 0
  }

  onConnectedAthletes(c: RaceDefinitionBoat[]) {
	this.connectedAthletes = Object.assign({}, c)
  }

  attemptManageRace(race: RaceWithRac) {
	  bus.$emit('attempt_manage_race', race)
	  // bus.$emit('attempt_manage_race',  ? raceId : this.racraceId?.id)
  }
  onRun(r: RaceWithRac) {
	while (this.userAcceptedConnections.length) {
	  this.userAcceptedConnections.pop()
	}
	while (this.userDroppedConnections.length) {
	  this.userDroppedConnections.pop()
	}

	this.showRaceMgt = true
	this.race = r
	this.activeTab = 1
	this.runningRace = r.rac.data.race_definition
	this.activeTab = r ? 1 : 0
	// running automatically sends everyone back to Pen
	// RemoteControl.remoteErgAction('holding_pen')
  }

  get teamRace() {
	return (this.race?.rac.data.race_definition.team_size || 1) > 1
  }
  closeResults() {
	if (this.status === 'final results') {
	  this.showRaceMgt = false
	  bus.$emit('close_results_btn_click')
	}
  }
  activateTab(newTabIndex, prevTabIndex, bvEvent) {
	if(this.devMode) { console.log(newTabIndex, prevTabIndex, bvEvent) }
	// const rm = this.$refs.rm.deactivate()
	bvEvent.preventDefault()
	if (!this.race) {
	  bvEvent.preventDefault()
	}
  }

  get badConnections() {
	// check for recent lost connections to give option whether to include
	const badConnections: any[] = []
	const d = new Date().getTime()

	this.runningRace?.boats.forEach((boat, boatIndex) => {
	  if (this.teamRace) {
		const participants = boat.participants || []
		const connectedBoatParticipants: any[] = []
		participants.forEach((participant) => {

		const found = RemoteErgPen.athleteStatus.find(
			(c) =>
			c.logbook_id === participant.logbook_id &&
			c?.com_stats!.quality <= this.config.global.comStatQualityThreshold
		)

		if (found) {
			const racer = Object.assign({}, found, {name: participant.name})
			badConnections.push(found)
		}

		})
	  } else {
		  	const found = RemoteErgPen.athleteStatus.find(
			(c) =>
			  c.logbook_id === boat.logbook_id &&
			  c?.com_stats!.quality <= this.config.global.comStatQualityThreshold
		  )

		   if (found) {
			const racer = Object.assign({}, found, {name: boat.name})
			badConnections.push(racer)
		  }
		}
	})
	return badConnections
	  .filter(
		(bc) =>
		  !this.userAcceptedConnections
			.map((uac) => uac.logbook_id)
			.includes(bc.logbook_id),
	  )
	  .filter(
		(bc) =>
		  !this.userDroppedConnections
			.map((udc) => udc.logbook_id)
			.includes(bc.logbook_id),
	  )
  }

  get noShows() {
	const nonparticipants: any = []
	const lane = 1
	this.runningRace?.boats.forEach((boat, boatIndex) => {
	  if (this.teamRace) {
		const participants = boat.participants || []

		participants.forEach((participant) => {
		  const connectionsToSearch = RemoteErgPen.athleteStatus.concat(
			this.userAcceptedConnections,
		  )
		  const found = connectionsToSearch.find(
			(c) => c.logbook_id === participant.logbook_id,
		  )
		  if (!(found && eligable(found))) {
			nonparticipants.push({
			  name: participant.name,
			  logbook_id: participant.logbook_id,
			})
		  }
		})
	  } else {
		const connectionsToSearch = RemoteErgPen.athleteStatus.concat(
		  this.userAcceptedConnections,
		)
		const found = connectionsToSearch.find(
		  (c) => c.logbook_id === boat.logbook_id,
		)
		if (!(found && eligable(found))) {
		  nonparticipants.push({ name: boat.name, logbook_id: boat.logbook_id })
		}
	  }
	})

	return nonparticipants
  }

  onPreCloseEntries() {
	if (!this.runningRace) {
	  return
	}
	const n = this.runningRace.boats.length * (this.teamRace ? this.runningRace.team_size! : 1) - this.noShows.length

	if (n > this.maxRacers) {
	  bus.$emit('race_dialog', `Number of participants exceeds limit of ${this.maxRacers}. Please reduce number of participants before continuing`, 'Error') // important: race logic depends on this text in message
	  bus.$emit('app_event', { error: false, detail: { clearTimer: true } })
	  return
	}

	if (!this.athleteStatus.length) {
	  bus.$emit('race_dialog', this.$t('errors.noOneConnected'), this.$t('titles.error'), 'holding_pen')
	  bus.$emit('app_event', { error: false, detail: { clearTimer: true } })
	  return
	}

	// alert('nonparticipants' + JSON.stringify(this.noShows))
	// alert('bad connections ' + JSON.stringify(this.badConnections))

	bus.$emit('non_participants', this.noShows)
	bus.$emit('bad_connections', this.badConnections)

	if (this.badConnections.length) {
	  return
	} else if (!this.noShows.length) {
	  // the dialog won't appear, so manually close entries...
	  bus.$emit('close entries')
	  return
	}
  }

  onCloseEntries() {
	this.compState = ''
	// create ergmap to send to Pen
	// do not include any boats without connected athletes
	if (!this.runningRace) {
	  return
	}

	bus.$emit('non_participants', this.noShows)
	bus.$emit('bad_connections', this.badConnections)
	// alert(JSON.stringify(this.badConnections))
	if (this.badConnections.length) {
	  return
	}

	bus.$emit('resend_sim')
	// const currNoShows = this.runningRace.noshows || []

	// console.log('currentNoShows: ', currNoShows)

	// this.runningRace.boats = [...new Set([...this.runningRace.boats, ...currNoShows])]
	// console.log('Set operation. boats: ', this.runningRace.boats)

	let connected: any[] = []
	let noshows: any[] = []
	let lane = 1

	if(this.devMode) { console.log('boats to search for connections: ', this.runningRace?.boats) }
	this.runningRace?.boats.forEach((boat, boatIndex) => {
	  boat.lane_number = lane
	  if (this.teamRace) {
		const participants = boat.participants || []
		const connectedBoatParticipants: any[] = []
		const tempLane = lane
		participants.forEach((participant) => {
		  const connectionsToSearch = RemoteErgPen.athleteStatus
			.concat(this.userAcceptedConnections)
			.filter(
			  (a) =>
				!this.userDroppedConnections
				  .map((udc) => udc.logbook_id)
				  .includes(a.logbook_id),
			)
		  const found = connectionsToSearch.find(
			(c) => c.logbook_id === participant.logbook_id,
		  )
		  if (found && eligable(found)) {
			connectedBoatParticipants.push({
			  boat,
			  participant,
			  erg: {
				connected: found.connected,
				lane_number: lane++,
				serial_number: parseInt(found.erg_status!.serial_number, 10),
				secondary_ergs: [],
			  },
			})
		  }
		})

		if (connectedBoatParticipants.length === participants.length) {
		  connected = connected.concat(connectedBoatParticipants)
		} else {
		  noshows = noshows.concat(boat)
		}
	  } else {
		const connectionsToSearch = RemoteErgPen.athleteStatus
		  .concat(this.userAcceptedConnections)
		  .filter(
			(a) =>
			  !this.userDroppedConnections
				.map((udc) => udc.logbook_id)
				.includes(a.logbook_id),
		  )

		const found = connectionsToSearch.find(
		  (c) => c.logbook_id === boat.logbook_id,
		)
		if (found && eligable(found)) {
		  connected.push({
			boat,
			erg: {
			  lane_number: lane,
			  connected: found.connected,
			  logbook_id: found.logbook_id,
			  serial_number: parseInt(found.erg_status!.serial_number, 10),
			  secondary_ergs: [],
			},
		  })
		  lane++
		} else {
		  noshows.push(boat)
		}
	  }
	})

	this.runningRace.noshows = noshows

	if (this.teamRace) {
	  const badIds = noshows.flatMap((ns) =>
		ns.participants.map((p) => p.logbook_id || ''),
	  )
	  this.runningRace.boats = this.runningRace.boats.filter((b) =>
		b.participants?.find(
		  (p) => !badIds.includes(p.logbook_id || ''),
		),
	  )
	  let i = 1
	  this.runningRace.boats.forEach(b => b.lane_number = i++)
	} else {
	  this.runningRace.boats = this.runningRace.boats.filter(
		(b) => !noshows.find((ns) => ns.logbook_id === b.logbook_id),
	  )
	}
	const names = noshows
	  .map((item) => {
		return item.name
	  })
	  .join(', ')
	bus.$emit('app_event', {
	  error: false,
	  detail: { checkbox: `Marked No Shows: ${names}` },
	})

	if(this.devMode) {
		console.log(
	  'running race while making ergMap: ',
	  JSON.stringify(this.runningRace, null, 4))
		console.debug('connected', connected)
	}

	if (!connected.length) {
	  bus.$emit('race_dialog', this.$t('errors.noOneConnected'), this.$t('titles.error'), 'holding_pen')
	  bus.$emit('app_event', { error: false, detail: { clearTimer: true } })
	  this.runningRace.boats = noshows
	  this.runningRace.noshows = []
	  return
	}
	this.closingEntries = true

	this.ergMap = {
	  map: {
		erg_map: connected.map((c) => c.erg),
	  },
	}
	RemoteErg.ergMap = this.ergMap
	if(this.devMode) {
		console.log(
	  'ergmap connections',
	  this.ergMap.map.erg_map ? this.ergMap.map.erg_map.length : 0)
	}

	// send ergmap to Pen
	RemoteControlPen.remoteErgAction('set_erg_map', this.ergMap)
	if(this.devMode) { console.log('sent ergmap to Pen', this.ergMap) }
	bus.$emit('app_event', {
	  error: false,
	  detail: { checkbox: 'Disconnecting Ergs from Holding Pen' },
	})
	bus.$emit('app_event', {
	  error: false,
	  detail: { checkbox: 'Created Erg Map' },
	})

	// and clear and send same ergmap to ErgRace
	RemoteControl.remoteErgAction('clear_erg_map')
	RemoteControl.remoteErgAction('set_erg_map', this.ergMap)
	if(this.devMode) { console.log('sent same ergMap to ErgRace') }
	RemoteErg.addMessage(
	  'sent same ergMap to ErgRace: ' + JSON.stringify(this.ergMap, null, 4),
	)
	// note: now waiting for the disover_done/Ok signal before proceeding to waitingForAthletes
	this.compState = 'waitingForAthletes'
  }

  onDiscoverDone(discoverStatus: string) {
	if(this.devMode) { console.log(`received discover_done. Status is ${discoverStatus}`) }
	RemoteErg.addMessage(`received discover_done. Status is ${discoverStatus}`)

	this.compState = this.closingEntries
	  ? discoverStatus === 'ok'
		? 'discover done'
		: 'discover done pending'
	  : ''
	RemoteErg.addMessage(`Status is ${this.compState}`)
  }

  async onAthleteStatusSentRace(athleteStatus) {
	if (!this.race) {
	  return
	}
	// console.log('athleteStatus:', JSON.stringify(athleteStatus, null, 4))
	if (this.compState === 'waitingForAthletes') {
	  bus.$emit('app_event', {
		error: false,
		detail: { checkbox: 'Connecting to ErgRace' },
	  })
	  if (
		!this.ergMap ||
		athleteStatus.length !== this.ergMap.map.erg_map.length
	  ) {
		if(this.devMode) { console.warn(
		  'ergMap not set or athletes not connected',
		  this.ergMap,
		  athleteStatus.length,
		  this.ergMap.map.erg_map.length,
		) }
		// console.log('here', JSON.stringify(athleteStatus, null, 4))
		RemoteErg.addMessage('ergMap not set or athletes not connected')
		return
	  }

	  const ineligable = athleteStatus.filter((a) => !eligable(a)).length
	  if(this.devMode) { console.log('ineligable', ineligable) }
	  if (ineligable || !this.ergsNumbered) {
		if(this.devMode) { console.warn('Not all ergs OK. Some are ineligable or not numbered') }
		RemoteErg.addMessage('Not all ergs OK. Some are ineligable or not numbered')
		return
	  }

	  if(this.devMode) { console.log('Passed Test: Ergmap lengths match.') }
	  RemoteErg.addMessage('Passed Test: Ergmap lengths match.')

	//   RemoteControl.remoteErgAction('discover')
	//   if(this.devMode) { console.log('issuing discover command...') }
	//   RemoteErg.addMessage('issuing discover command...')
	//   this.compState = 'discovering'
	  bus.$emit('app_event', {
		error: false,
		detail: { checkbox: 'All Ergs connected to ErgRace' },
	  })
	  this.compState = 'athletesConnected'
	}

	// if (this.compState === 'discover done pending') {
	//   // waiting for all connected athletes to have status ok
	//   const ineligable = athleteStatus.filter((a) => !eligable(a)).length
	//   if (!ineligable) {
	// 	RemoteErg.addMessage(
	// 	  'All ergs are now OK. Proceeding with discover_done',
	// 	)
	// 	this.compState = 'discover done'
	//   }
	// }
	if (this.compState === 'athletesConnected') {
	// if (this.compState === 'discover done') {
	  // if(this.devMode) { console.log('discover done') }
	  // RemoteErg.addMessage('discover done')
	  // when all are ready

	  // race boats must match ergMap boats and in same order!
	  const ergBoats: RaceDefinitionBoat[] = []
	  RemoteErg.addMessage(
		'ergMap boats: ' + JSON.stringify(this.ergMap?.map?.erg_map, null, 4),
	  )

	  if (!this.runningRace) {
		  bus.$emit('app_event', {error: true, message: 'NO RUNNING RACE'})
		  throw new Error('Expected a race to be running')
	  }

	  if (!this.runningRace?.c2_race_id) {
		bus.$emit('app_event', {error: true, message: 'NO C2_RACE_ID'})
		throw new Error('Expected race to have a C2 ID')
	  }

	  if(this.devMode) { console.log('Set operation. boats: ', this.runningRace.boats) }

	  RemoteErg.addMessage(
		'race definition boats before trimming out ineligable: ' +
		  JSON.stringify(this.runningRace.boats, null, 4),
	  )
	  let thingToSend
	  if (true) {
	const safeFilename =
		this.runningRace.c2_race_id +
		'-' +
		this.runningRace.name_long.replace(
			/[\\!"#%&'()*+,/:;<=>?[\]^`{|}]/g,
			'',
		) +
		'.rac2'

	thingToSend = {
		  race: {
			file_name: safeFilename,
			race_definition: {
			  ...this.runningRace,
			  event_name: Competitions.currentCompetition.name,
			  c2_race_id: this.runningRace.c2_race_id?.toString(),
			  race_id: this.runningRace.c2_race_id?.toString(),
			},
		  },
		}

	if (this.teamRace) {
		  thingToSend.race.race_definition.boats.forEach((b) => {
			b.participants = b.participants.map((p) => ({
			  logbook_id: p.logbook_id,
			  name: p.name,
			}))
		  })
		}
	  }
	  this.thingToSend = thingToSend
	  this.sendThing()
	}
  }

  async sendThing() {
	  const thingToSend: any = this.thingToSend
	  if(this.devMode) {
		  console.log('%cSAVE LOAD', 'font-size:large; color:blue; font-weight:bold;',
		   	JSON.stringify(thingToSend))
	  }
	  // console.log('thingToSend', thingToSend, JSON.stringify(thingToSend))
	  RemoteErg.addMessage(
		'thingToSend: ' + JSON.stringify(thingToSend, null, 4),
	  )
	  RemoteControl.remoteErgAction('change_setting', {
		'Stop Race On No Activity Minutes': config.vals.global.stopRaceMinutes
	  })
	  RemoteControl.remoteErgAction('save_load', thingToSend)
	  RemoteErg.addMessage('save_load sent')
	  RemoteControlPen.remoteErgAction('save_load', thingToSend)
	  bus.$emit('app_event', {
		error: false,
		detail: { checkbox: 'Disconnecting Ergs from Holding Pen' },
	  })
	  RemoteErg.addMessage('sent same save_load to the Pen')

	  this.compState = ''

	  this.race!.rac.data.race_definition! = thingToSend.race.race_definition

	  const result = await Competitions.saveRace(this.race!)

	  RemoteErg.addMessage(result.id ? 'save successful' : 'save failed.')
	  if (!result.id) {
		if(this.devMode) { console.log('%cSAVE FAILED', 'font-weight:bold, color:red', result) }
		return
	  }
	  this.race!.description = result.description
	  bus.$emit('app_event', {
		error: false,
		detail: { checkbox: 'Stored New Race File' },
	  })
  }

  onCloseResults(evt) {
	if (this.status === 'final results') {
	  // set race to false to destroy race components.
	  this.race = null
	  this.runningRace = null
	  this.activeTab = 0
	  this.requestErgraceAction('holding_pen')
	  this.requestErgraceAction('normal_mode')
	  RemoteErg.clearMessage()
	}
  }
  onCancelRunRace(evt) {
 	  this.race = null
	   this.runningRace = null
	   this.activeTab = 0
	   this.showRaceMgt = false
  }

  onRaceListForceFocus(evt) {
	  this.activeTab = 0
  }

  onHome() {
	this.race = null
	this.runningRace = null
  }

  onHoldingPenSent() {
	// RemoteControl.remoteErgAction('normal_mode')
	// if(this.devMode) { console.log('sending normal_mode') }
  }

  onSentVersion(version) {
	if(this.devMode) { console.log('version:', version) }
  }

  async onUpdateRace(race: any) {
	if (!this.updatingRace) {
	  this.updatingRace = true
	  if(this.devMode) { console.clear() }
	  // Make standalone deep copy, modify it to match array structure
	  const copyRace: any = Object.assign({}, race)
	  if(race.scheduled) { copyRace.projected = race.scheduled }
	  const pendingId = 0 // ID 0 changes status to pending
	  const allRaces = this.competition.races.data
	  const index = allRaces.findIndex((myR) => myR.id === race.id)
	  const editRace = allRaces[index]
	  copyRace.id = pendingId
	  copyRace.event_name = copyRace.rac.data.race_definition.event_name
	  copyRace.race_definition = copyRace.rac.data.race_definition
	  copyRace.name = copyRace.rac.data.race_definition.name_long
	  copyRace.description = copyRace.rac.data.description

	  copyRace.duration_type = copyRace.rac.data.race_definition.duration_type
	  copyRace.duration = copyRace.rac.data.race_definition.duration
	  copyRace.boats = copyRace.rac.data.race_definition.boats.length
	  copyRace.race_type = copyRace.rac.data.race_definition.race_type

	  allRaces.splice(index, 1, copyRace)

	  const result = await Competitions.saveRace(race).catch((r) => false)
	  const passed = result.id ? true : false
	  // passed = false
	  if (passed) {
		copyRace.id = result.id
		copyRace.description = result.description
		copyRace.name = result.name
		copyRace.scheduled = result.scheduled
		copyRace.projected = result.projected
	  } else {
		// if saveRace failed, replace with original race
		allRaces.splice(index, 1, editRace)
	  }
	  const msg = passed ? 'RACE SAVED' : 'RACE SAVE FAILED, TRY AGAIN.'
	  this.$root.$bvToast.toast(msg, {
		autoHideDelay: 1200,
		noCloseButton: true,
		bodyClass: passed ? 'toast-body ' : 'toast-body-failed',
	  })
	  if(passed) {
		bus.$emit('notify_others_race_list_stale')
		if(race.id === this.race?.id) {
			this.race = copyRace
			this.runningRace = copyRace.rac.data.race_definition
		}
	  }
	  this.updatingRace = false
	}
  }

  incrementName(name: string): string {
	let found: number
	let testname: string
	const parArray = name
	  .trim()
	  .toLowerCase()
	  .match(/\(\d+\)$/)
	let i = !parArray
	  ? 1
	  : parseInt(parArray[0].replace('(', '').replace(')', ''), 10)
	const nonparenthpart = name
	  .toLowerCase()
	  .replace(/\(\d+\)$/, '')
	  .trim()
	do {
	  testname = `${nonparenthpart} (${i})`
	  found = this.competition.races.data.findIndex(
		(myR) =>
		  myR.rac &&
		  myR.rac.data.race_definition.name_long.trim().toLowerCase() ===
			testname,
	  )
	  i++
	} while (found !== -1)
	return initialCap(testname)
  }

  copyRace(race: any) {
	// Make standalone deep copy
	const r = JSON.parse(JSON.stringify(race))
	if(r.rac?.data?.race_definition?.noshows) {
		delete(r.rac?.data?.race_definition?.noshows)
	}
	if(r.scheduled) { r.projected = r.scheduled }
	r.id = TabRaceList.NewRaceTempId
	const oldName = r.rac.data.race_definition.name_long
	const newName = this.incrementName(oldName)
	r.rac.data.race_definition.name_long = newName
	r.name = newName
	r.order =
	  Math.max.apply(
		Math,
		Competitions.currentCompetition.races.data.map((o) => {
		  return o.order
		}),
	  ) + 1,
	  this.onAddRace(r)
  }

  async onAddRace(race: any) {
	if(this.devMode) { console.log('onAddRace', race) }
	if (!this.updatingRace) {
	  this.updatingRace = true
	  // scroll to bottom of screen where the record will be added
	  setTimeout(() => {
		const bottom = document.getElementById('listbottom')
		bottom?.scrollIntoView()
	  }, 250)

	  // 	Push race to arraylist, save to DB, update ID returned from DB
	  const dbRace = Object.assign({}, race)
	  delete dbRace.results
	  delete dbRace.results_url
	  // 	Push to array
	  const arryRace = this.competition.races.data

	  arryRace.push(race)
	  // 	Save to DB
	  dbRace.id = null // Delete temp id, get new DB id

	  const result = await Competitions.saveRace(dbRace).catch((r) => false)
	  const passed = result.id ? true : false
	  // 	Update race ID from DB
	  if (passed) {
		// arryRace.push(JSON.parse(JSON.stringify(result)))

		const ind = arryRace.findIndex(
		  (arry) => arry.id === TabRaceList.NewRaceTempId,
		)
		if (ind !== -1) {
			const localRace: any = arryRace[ind]
			localRace.id = result.id
			if (!localRace.rac) {
				throw new Error('Expected rac property')
			}
			localRace.rac.data.race_definition.c2_race_id = result.id
			localRace.competition = parseInt(this.competition.code, 10)
			localRace.name = result.name
			localRace.description = result.description
			localRace.results = false
			localRace.results_url = result.result_url

			localRace.duration_type = result.duration_type
			localRace.duration = result.duration
			localRace.boats = result.boats
			localRace.race_type = result.race_type

			if(this.devMode) {
				console.log('table row: ', JSON.stringify(this.competition.races.data[ind]))
			}
		} else {
		  if(this.devMode) { console.error('CANT FIND NEW RACE TEMP ID', TabRaceList.NewRaceTempId) }
		}
	  } else {
		arryRace.pop()
	  }
	  const msg = passed ? 'RACE SAVED' : 'RACE SAVE FAILED, TRY AGAIN.'
	  this.$root.$bvToast.toast(msg, {
		autoHideDelay: 1200,
		noCloseButton: true,
		bodyClass: passed ? 'toast-body ' : 'toast-body-failed',
	  })
	  if(passed) { bus.$emit('notify_others_race_list_stale') }
	  this.updatingRace = false
	}
  }

  badConnectionDecisions(decisions: ConnectionChoice[]) {
	this.closingEntries = false
	this.compState = ''

	if (decisions.length) {
	  while (this.userAcceptedConnections.length) {
		this.userAcceptedConnections.pop()
	  }
	  while (this.userDroppedConnections.length) {
		this.userDroppedConnections.pop()
	  }
	}

	decisions.forEach((d) => {
	  const temp: any = Object.assign({}, d)
	  delete temp.choice
	  if (d.choice === 'keep') {
		this.userAcceptedConnections.push(temp)
	  } else {
		this.userDroppedConnections.push(temp)
	  }
	})
  }

  onAthleteStatusSentPen(a: AthleteStatus[]) {
	if (this.compState === 'close entries') {
	  // check to see if any changes to the connection status
	}
  }

  onHoldingPen() {
	this.closingEntries = false
	while (this.userAcceptedConnections.length) {
	  this.userAcceptedConnections.pop()
	}
	while (this.userDroppedConnections.length) {
	  this.userDroppedConnections.pop()
	}
	this.compState = ''
  }

  onBadConnections(bad_connections: AthleteStatus[]) {
	if (bad_connections.length) {
	  // this.transitioning = false
	  this.$bvModal.show('modal-dropped')
	}
	this.problemConnections = bad_connections
  }

  onNonParticipants(nonparticipants: any[]) {
	if (this.athleteStatus.length && nonparticipants.length) {
	  this.$bvModal.show('modal-dropped')
	}
  }

  onExit() {
	this.closingEntries = false
	this.showRaceMgt = false
	this.compState = ''
	this.race = null
	this.runningRace = null
	this.activeTab = 0
	this.requestErgraceAction('holding_pen')
	this.requestErgraceAction('normal_mode')
	RemoteErg.clearMessage()
  }

  onRemoveAthlete(a: AthleteStatus) {
	let findIndex = this.userAcceptedConnections.findIndex(
	  (uac) => uac.logbook_id === a.logbook_id,
	)
	if (findIndex !== -1) {
	  this.userAcceptedConnections.splice(findIndex, 1)
	}
	findIndex = this.userDroppedConnections.findIndex(
	  (udc) => udc.logbook_id === a.logbook_id,
	)
	if (findIndex !== -1) {
	  this.userDroppedConnections.splice(findIndex, 1)
	}
  }

  async timeChanged(id: number, value: Date) {
	// routine used to listen when the projected time was updated from the race screen.
		if(this.race?.id === id) {
			const utc = formatUTCDate(value)
			try {
				this.race.projected = utc
			} catch(e) { console.log(e) }
		}
	}

  created() {
	bus.$on('update_projected_time', this.timeChanged)
	bus.$on('tab', this.onTab)
	bus.$on('run', this.onRun)
	bus.$on('view', this.onRun)
	bus.$on('connectedAthletes', this.onConnectedAthletes)
	bus.$on('close entries', this.onCloseEntries)
	bus.$on('close_results_screen', this.onCloseResults)
	bus.$on('updateRace', this.onUpdateRace)
	bus.$on('copyRace', this.copyRace)
	bus.$on('addRace', this.onAddRace)
	// bus.$on('running_race_changed', this.onRunningRaceChanged)
	bus.$on('home', this.onHome)
	bus.$on('bad_connections_decisions', this.badConnectionDecisions)
	bus.$on('holding_pen', this.onHoldingPen)
	bus.$on('pre_close_entries', this.onPreCloseEntries)
	bus.$on('bad_connections', this.onBadConnections)
	bus.$on('non_participants', this.onNonParticipants)
	bus.$on('remove_athlete', this.onRemoveAthlete)
	bus.$on('racelist_force_focus', this.onRaceListForceFocus)
	bus.$on('send_thing', this.sendThing)

	RemoteErgPen.$on('athlete_status_sent', this.onAthleteStatusSentPen)
	RemoteErg.$on('athlete_status_sent', this.onAthleteStatusSentRace)
	RemoteErg.$on('holding_pen_sent', this.onHoldingPenSent)
	RemoteErg.$on('sent_version', this.onSentVersion)
	RemoteErg.$on('discover_done', this.onDiscoverDone)
	RemoteErg.$on('holding_pen', this.onHoldingPen)
	bus.$on('exit', this.onExit)
	bus.$on('cancel_run_race', this.onCancelRunRace)

	bus.$emit('attempt_manage_competition', this.competition.code.toString())

	if (
	  this.status !== 'inactive' &&
	  this.status !== 'race running' &&
	  this.status !== 'race running - sound horn'
	) {
	  // RemoteControl.remoteErgAction('holding_pen')
	}
  }

  beforeDestroy() {
	bus.$off('update_projected_time', this.timeChanged)
	bus.$off('tab', this.onTab)
	bus.$off('run', this.onRun)
	bus.$on('view', this.onRun)
	bus.$off('connectedAthletes', this.onConnectedAthletes)
	bus.$off('close entries', this.onCloseEntries)
	bus.$off('close_results_screen', this.onCloseResults)
	bus.$off('updateRace', this.onUpdateRace)
	bus.$off('copyRace', this.copyRace)
	bus.$off('addRace', this.onAddRace)
	// bus.$off('running_race_changed', this.onRunningRaceChanged)
	bus.$off('home', this.onHome)
	bus.$off('bad_connections_decisions', this.badConnectionDecisions)
	bus.$off('holding_pen', this.onHoldingPen)
	bus.$off('pre_close_entries', this.onPreCloseEntries)
	bus.$off('bad_connections', this.onBadConnections)
	bus.$off('non_participants', this.onNonParticipants)
	bus.$on('remove_athlete', this.onRemoveAthlete)
	bus.$off('cancel_run_race', this.onCancelRunRace)
	bus.$off('racelist_force_focus', this.onRaceListForceFocus)
	bus.$off('send_thing', this.sendThing)

	RemoteErgPen.$off('athlete_status_sent', this.onAthleteStatusSentPen)
	RemoteErg.$off('athlete_status_sent', this.onAthleteStatusSentRace)
	RemoteErg.$off('holding_pen_sent', this.onHoldingPenSent)
	RemoteErg.$off('sent_version', this.onSentVersion)
	RemoteErg.$off('discover_done', this.onDiscoverDone)
	RemoteErg.$off('holding_pen', this.onHoldingPen)
	bus.$off('exit', this.onExit)
  }

//   setRemoteId() {
// 	if (this.config.global.remoteId && this.$route.name) {
// 	  config.setRemoteId(
// 		this.config.global.remoteId,
// 		displayType(this.$route.name),
// 	  )
// 	}
//   }

  requestErgraceAction(action: string, config?: any) {
	RemoteControl.remoteErgAction(action, config)
  }

  identify(currentRaceServer) {
	this.raceServer = currentRaceServer
	Server.send({
	  remote_identify: { type: 'remote' },
	})

	RemoteControl.remoteErgAction('race_status')

	const displaySocket =
	  this.currentInstance.display_socket ||
	  `wss://live.ergrace.com/socket/${this.competition.code}`
	RemoteControl.remoteErgAction('change_setting', {
	  'Cloud%20Server%20URL': displaySocket,
	})
	RemoteControl.remoteErgAction('connect_display')
	RemoteControl.remoteErgAction('athlete_status')
	RemoteControl.remoteErgAction('get_version')
	RemoteControl.remoteErgAction('get_simulation')

	RemoteControl.remoteErgAction('change_setting', {
	  'Display%20Units%20Distance%20Race': 0,
	})
	RemoteControl.remoteErgAction('change_setting', {
	  'Display%20Units%20Time%20Race': 0,
	})
	RemoteControl.remoteErgAction('change_setting', {
	  'Display%20Units%20Calorie%20Race': 0,
	})
	RemoteControl.remoteErgAction('change_setting', {
	  'Display%20Units%20Time%20Race%20Calorie%20Score': 0,
	})

	RemoteControl.remoteErgAction('change_setting', {
	  'Wireless%20Channel%20Configuration': 14,
	})
  }

  identifyWithPen() {
	ServerPen.send({
	  remote_identify: { type: 'remote' },
	})
	RemoteControlPen.remoteErgAction('athlete_status')
	RemoteControlPen.remoteErgAction('get_simulation')
  }
  get preRaceVals() {
	if(!this.race || !this.runningRace) { return {}}
	return {
		id: this.race.id,
		name: this.race.name,
		duration: this.race.rac.data.race_definition.duration,
		duration_type: this.race.rac.data.race_definition.duration_type,
		scheduled: this.race.scheduled,
		projected: this.race.projected,
		entered: this.HP.acceptedFull.map((as) => ({ logbook_id:as.logbook_id, name: as.name }))
	 }
  }

  @Watch('penState', { immediate: true })
  penStateChanged(newVal) {
	if(this.devMode) { console.log('Pen ', newVal) }
  }
  @Watch('currentInstance', { immediate: true, deep: true })
  instanceChanged(newVal: Instance) {
	this.liveUrl = newVal.display_url + '/#/race'
  }

  @Watch('preRaceVals', { immediate: true, deep: true })
  raceOrAcceptedFullChanged(newVal: AthleteStatus[] | []) {
		RemoteControl.remoteErgAction('change_setting', { pre_race: this.preRaceVals } )
  }
//   @Watch('race', { immediate: true, deep: true })
//   raceChanged(newVal: RaceWithRac | null) {
// 	if(newVal) {
// 		const r = {
// 			name: newVal.name,
// 			duration: newVal.rac.data.race_definition.duration,
// 			duration_type: newVal.rac.data.race_definition.duration_type,
// 			scheduled: newVal.scheduled,
// 			projected: newVal.projected,
// 			race_definition: newVal.rac.data.race_definition,
// 			entered: RemoteErgPen.acceptedFull.map((as) => ({ logbook_id:as.logbook_id, name: as.name }))
// 		}
// 		RemoteControl.remoteErgAction('change_setting', { pre_race: r } )
// 	}
//   }

  @Watch('definition', { immediate: true, deep: true })
  definitionChanged(newVal: RaceDefinition | null) {
	// find which raceWithRac is running and set the property for child components
	if (newVal && newVal.c2_race_id) {
	  const r = this.competition.races.data.find(
		(def) => def.rac?.data.race_definition.c2_race_id === newVal.c2_race_id,
	  )
	  if (r) {
		this.compState = ''
	  }
	}
  }
}
