
import { Component, Prop, Vue } from 'vue-property-decorator'
import { annotatedLocales } from 'shared/services/languages'
import { SelectOptions } from 'shared/types'
import { defaultLanguages } from 'shared/services/languages'
import { bus } from 'shared/state/Bus'
import { config } from 'display/config'

@Component
export default class LocaleSwitcher extends Vue {
  @Prop() label?: string
	@Prop() name!: string
	@Prop({ type: [Array, Object] }) options!: SelectOptions
	@Prop() value!: string
	locales = annotatedLocales

  localesChanged(e) {
	bus.$emit('changeLang', e)
  }
  setLang(lang: string) {
	this.$i18n.locale = lang
  }
  created() {
	bus.$on('setLang', this.setLang)
  }
  beforeDestroy() {
	bus.$off('setLang', this.setLang)
  }
}
