
import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
import NewCompetitionForm from './NewCompetitionForm.vue'
import { Competitions, CompetitionsState } from 'shared/state/Competitions'
import { Competition } from 'shared/types/competition'
import { CompetitionType } from 'shared/types/competition'
import { LogBookUser } from 'shared/state/LogBookUser'
import { formatDate } from 'shared/util'

import { RemoteControl } from 'shared/state/RemoteControl'
import { RemoteErg, RemoteErgState, Erg, ErgState, RemoteErgPen, RemoteErgStatePen } from 'shared/state'

import { RaceDefinition, RaceDefinitionWrapper } from 'shared/types/erg/'
import {
	RemoteErgRaceAction,
	remoteErgRaceActions,
	RemoteErgRaceState,
	remoteErgRaceStates,
	RemoteErgRaceStatus
} from 'shared/types/erg/index'
import { RaceState } from 'shared/types/erg'
import { DurationType, RaceType } from 'shared/types/erg/index'
import RaceForm from './RaceForm.vue'
import FilePicker from './FilePicker.vue'
import { formatTime } from 'shared/util'
import { RaceListItem } from 'shared/types/racelistitem'
import date from 'date-and-time'
import { bus } from 'shared/state/Bus'
import { RacType, RacOnCompetition, RaceWithRac } from 'shared/types/competition'
import Results from './Results.vue'
import Skeleton from './Skeleton.vue'
import { LogBookUserDefinition } from 'shared/types/logBook'
import TabRaceList from './TabRaceList.vue'
import { config, GlobalConfig } from 'display/config'
import ProjectedTime from './ProjectedTime.vue'
import LogbookIdField from './LogbookIdField.vue'
import { formatUTCDate } from 'shared/util/formatDate'
import { Debounce } from 'vue-debounce-decorator'
import { RaceFormType } from 'shared/types/competition'


@Component({ components: { NewCompetitionForm, Results, Skeleton, ProjectedTime } })
export default class TabRaceListRaces extends Vue {
	@GlobalConfig() enableAnimation!: boolean
	@Prop() race!: any
	@Prop() competition!: Competition
	@Prop() status!: string
	@Prop() loading!: boolean
	@GlobalConfig() devMode!: boolean
	@RemoteErgStatePen() raceId

	file = {}
	myRaces: RaceWithRac[] = []
	myResults: any[] = []
	runningRacesIdList: number[] = []
	@CompetitionsState() currentCompetition
	@ErgState() definition!: RaceDefinition | null
	result: any = {}
	showResult: boolean = false
	showThis: boolean = false
	manualSorting = false

	blah: any = []
	raceListUpdating = false
	raceAborting = false
	getOptions() {
		return {animations: 200}
	}

	showRaceJSON(race) {
		// alert(JSON.stringify(race))
		this.$bvModal.msgBoxOk(JSON.stringify(race, null, 4), {
			title: 'debug',
			size: 'lg',
			buttonSize: 'sm',
			okVariant: 'outline-primary',
			okTitle: 'Ok',
			bodyClass: 'bdy',
			footerClass: 'p-2',
			hideHeaderClose: false,
			centered: true
		})
	}

	get fields() {
		const f = [
			{ key: 'name', label: 'race' },
			{ key: 'scheduled', label: 'Race Time' },
			{ key: 'projected', label: 'Projected Time' },
			{ key: 'type', label: 'Type' },
			{ key: 'duration', label: 'Duration' },
			{ key: 'boats', label: 'Entries' },
			{ key: 'action', label: 'Action' },
			{ key: 'race status', label: 'Race Status' }
		]
		if(config.vals.global.devMode) {
			f.push({ key: 'id', label: 'id' })
			f.push({ key: 'order', label: 'order' } )
		}
		return f
	}

	async timeChanged(id: number, value: Date) {
		if(this.raceListUpdating) { return }
		const i = this.myRaces.findIndex(m => m.id === id)
		if(i !== -1) {
			const d = new Date(this.myRaces[i].scheduled + 'Z')
			if(value < d) {
				this.myRaces[i].projected = this.myRaces[i].scheduled
			} else {
				const cpy = Object.assign({}, this.myRaces[i])
				const projected = value
				const utc = formatUTCDate(projected)
				try {
					cpy.projected = utc
					const result = await LogBookUser.saveProjected(this.myRaces[i].id, utc)
					this.myRaces.splice(i, 1, cpy)
					bus.$emit('notify_others_race_list_stale')
				} catch(e) { console.log(e) }
			}
		}
	}

	get raceRunning() {
		return this.status && !(['inactive', 'final results'].includes(this.status))
	}
	raceStatus(r: RaceWithRac) {
		if(r.id === 0) { return 'pending' }
		if(!r.results && (this.isRunning(r.id) && this.status === 'final results')) { return 'race stopped' }
		if(r.results || (this.isRunning(r.id) && this.status === 'final results')) { return 'completed' }

  if(this.isRunning(r.id)) {
			return 'in progress'
		}

		return 'ready'
	}
	async refreshRaceList() {
		if(!this.raceListUpdating) { // debounce...
			this.raceListUpdating = true
			this.blah = await Competitions.getRaces()
			this.myRaces = this.blah.sort((a, b) => ('' + a.scheduled).localeCompare(b.scheduled))

			this.$root.$bvToast.toast('RACE LIST UPDATED', {
						autoHideDelay: 1500, noCloseButton:true, bodyClass:'toast-body' })
			this.raceListUpdating = false
		}
	}

	get currentlyRunningRace() {
		return this.definition?.c2_race_id ?  parseInt(this.definition?.c2_race_id, 10) : -1
	}

	isRunning(id: number): boolean {
		return this.currentlyRunningRace === id
	}
	isSelected(id: number): boolean {
		return this.raceId === id
	}
	get myStatus(): string {
		return this.status
	}

	requestRun(r: RaceWithRac) {
		bus.$emit('attempt_manage_race', r)
	}
	attemptViewOnly(r: RaceWithRac) {
		bus.$emit('attempt_manage_race', r, 'view')
		// this.deleteRace(r.id)
	}

	onRun(r: RaceWithRac) {
		this.runningRacesIdList[0] = r.id
	}
	// change event handler. drag event handler to update the order of the race list.
	// also called on a delete for the same purpose. When called in this way, begin and end will be provided
	// async change(event, begin: number, end: number) {
	// 	this.manualSorting = true
	// 	this.raceListUpdating = true
	// 	const start = typeof(begin) === 'number' ? begin :
	// 		event.newIndex > event.oldIndex ? event.oldIndex : event.newIndex
	// 	const finish = typeof(end) === 'number' ? end :
	// 		event.newIndex > event.oldIndex ? event.newIndex : event.oldIndex
	// 	const promises: Array<Promise<any>> = []
	// 	let j = 0
	// 	for(let i = start; i <= finish; i++) {
	// 		const raceId = this.myRaces[i].id
	// 		promises[j++] = LogBookUser.saveRaceOrder(raceId, i + 1)
	// 	}
	// 	// allow a 1 second delay after all XHR requests
	// 	// before numbering the list
	// 	// allowing the control to settle
	// 	Promise.all(promises).then(() => setTimeout(() => {
	// 		this.number()
	// 		this.raceListUpdating = false
	// 		this.manualSorting = false
	// 		bus.$emit('notify_others_race_list_stale')
	// 	}, 1000)
	// 	)
	// }
	// number() {
	// 	this.myRaces.map((race, index) => {
	// 		race.order = index + 1
	// 	})
	// }
	attemptDelete(r: RaceWithRac) {
		bus.$emit('attempt_manage_race', r, 'delete')
	}
	async deleteRace(r: RaceWithRac) {
		const raceId = r.id
		this.$bvModal
			.msgBoxConfirm(
				`Please confirm that you want to delete this race. This action cannot be undone.`,
				{
					title: 'Confirm Removal',
					size: 'sm',
					buttonSize: 'sm',
					okVariant: 'success',
					cancelVariant: 'danger',
					okTitle: 'Remove',
					cancelTitle: 'Cancel',
					bodyClass: 'bdy',
					footerClass: 'p-2',
					hideHeaderClose: false,
					centered: true
				}
			)
			.then(async (confirm) => {
				if (confirm) {
					 const result = await Competitions.deleteRace(raceId)
					 return result
				}
			})
			.then((result) => {
				if (result) {
					if(raceId === this.runningRacesIdList[0]) {
						RemoteErgPen.setRunningRace('', '')
						this.runningRacesIdList.pop()
						bus.$emit('cancel_run_race')
					}
					this.$root.$bvToast.toast('RACE DELETED', {
						autoHideDelay: 1500, noCloseButton:true, bodyClass:'toast-body' })
					// this.change(null, 0, this.myRaces.length - 1)
					bus.$emit('notify_others_race_list_stale')
				}
			})
			.catch((err) => {
				this.$root.$bvToast.toast('DID NOT DELETE', {autoHideDelay: 1200,
				noCloseButton:true, bodyClass:'toast-body-failed' })
			})
	}

	getTime(utc_date: Date) {
		if (utc_date) {
			const zone = utc_date ? utc_date.toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2] : ''
			return date.format(utc_date, 'h:mm A', false) + ' ' + zone
		}
		return ''
	}
	get haveData(): boolean {
		return this.myRaces && this.myRaces.length ? true : false
	}

	closeResultsScreen() {
		this.result = false
		this.showResult = false
		bus.$emit('close_results_screen')
	}

	created() {
		bus.$on('close_results_btn_click', this.closeResultsScreen)
		bus.$on('run', this.onRun)
		bus.$on('delete_race', this.deleteRace)
		bus.$on('update_race_list', this.refreshRaceList)
		bus.$on('update_projected_time', this.timeChanged)
	}
	beforeDestroy() {
		bus.$off('close_results_btn_click', this.closeResultsScreen)
		bus.$off('run', this.onRun)
		bus.$off('delete_race', this.deleteRace)
		bus.$off('update_race_list', this.refreshRaceList)
		bus.$off('update_projected_time', this.timeChanged)
	}

	timeField(r: string) {
		if(!r) { return '' }
		const d = date.parse(r, 'YYYY-M-D HH:mm:ss', true)
		return r ? this.getTime(d) : ''
	}

	convertSecToHMS(totalSeconds: number) {
		const hours = Math.floor(totalSeconds / 3600)
		totalSeconds %= 3600
		const minutes = Math.floor(totalSeconds / 60)
		const minStr = hours > 0 ? minutes.toString().padStart(2, '0') : minutes
		const seconds =  (totalSeconds % 60).toString().padStart(2, '0')
		return `${hours > 0 ? hours + ':' : ''}${minStr}:${seconds}`
	}

	formatDuration(r: any) {
		if(!r || !r.duration) { return }
		if(r.duration_type.toString().includes('calorie')) {
			return r.duration.toString().includes('cals') ? r.duration : r.duration + 'cals'
		}
		if(r.duration_type.toString().includes('meters')) {
			return r.duration.toString().includes('m') ? r.duration : r.duration + 'm'
		}
		return r.duration.toString().includes(':') ? r.duration : this.convertSecToHMS(r.duration)
	}

	createRace() {
		this.$emit('edit')
	}

	importRace() {
		const el = (this.$refs.fileInput as Vue).$el as HTMLElement
		if (!el) {return}
		const input = el.querySelector('input[type="file"]') as HTMLElement
		if (input) { input.click() }
	}

	edit(race: RaceWithRac) {
		bus.$emit('attempt_manage_race', race, 'edit')
	}

	view(race: RaceWithRac) {
		bus.$emit('attempt_manage_race', race, 'view')
	}

	copy(race: RaceWithRac) {
		bus.$emit('copyRace', race)
	}

	results(r) {
		this.result = this.myResults.find(res => res.raceId === r.id)
		this.showResult = true
	}
	raceClick(r) {
		bus.$emit('tab', r)
	}
	getResults() {
		this.myResults = []
		this.myRaces.forEach(async race => {
		   const result = await Competitions.getResults(race.id)
		   if(result) {
			   this.myResults.push({raceId: race.id, result})
		   }
		})
	}
	async updateRaceResults(raceId) {
		// immediately update view state of aborted or completed race
		const u = this.myRaces.find(r => r.id === raceId)
		if(u) {
			u.results_url = `https://results.ergrace.com/competition/${this.competition.code}/race/${raceId}`
			u.results = true
		}
		// allow a few seconds for ER to post results
		// setTimeout(async () => {
		// 	const race = await Competitions.getRace(raceId).catch(() => {})
		// 	if(this.competition && race && race.id) {
		// 		const raceNeedingUpdate = this.myRaces.find(r => r.id === race.id)
		// 		if(raceNeedingUpdate) {
		// 			raceNeedingUpdate.results = race.results
		// 			// narrow when results_url is updated. only when race completed, not stopped.
		// 			if(!this.raceAborting) {
		// 				raceNeedingUpdate.results_url = race.results_url
		// 			}
		// 			this.raceAborting = false
		// 		}
		// 	}
		// }, 5000)

	}

	@Watch('currentCompetition', {immediate: true, deep: true})
	competitionChanged(newVal) {
		this.raceListUpdating = true
		if(!this.manualSorting) {
			this.myRaces = newVal.races.data.filter((d) => {
			return d.rac
			}).sort((a, b) => ('' + a.scheduled).localeCompare(b.scheduled))
		}
		setTimeout(() => this.raceListUpdating = false, 200)
		// draggable component appears to be mutating projected-time events.
		// the delay suppresses any projectedTime controls from unnecessarily firing the projChanged event,
		// an issue first observed when changes were made to improve load performance.
	}
	@Watch('status', {immediate: true, deep: true})
	// remove the race from the runningRacesIdList array if race completed
	async statusChanged(newVal: RaceState, oldVal: RaceState) {
		if(newVal === 'race aborted') {
			this.raceAborting = true
		}
		if(newVal === 'final results') {
			if (this.runningRacesIdList[0]) {
				this.updateRaceResults(this.runningRacesIdList[0])
				this.runningRacesIdList.pop()
			}
		} else if(this.definition && this.definition.c2_race_id) {
			const id = parseInt(this.definition.c2_race_id, 10)
			if(!this.runningRacesIdList.includes(id)) {
					this.runningRacesIdList.push(id)
				}
		}
	}

	async onFileUpload(evt) {
		this.$el.classList.remove('hover')
		const files = [...evt instanceof DragEvent ?
			evt.dataTransfer?.files : evt]
		console.debug({files})

		files.forEach(async (file, index) => {
		if(file) {
			const text = await file.text()
			const race = JSON.parse(text) as RaceDefinitionWrapper
			if (!race) {
					window.alert('error, file not in json format')
				}
			if(this.devMode) { console.log({race}) }
			setTimeout(
				() => this.$emit('import', race),
				1000 * index)


		}

		})
	}

	onDragOver(e) {
		this.cancelDefault(e)
		this.$el.classList.add('hover')
	}

	onDragLeave(e) {
		this.$el.classList.remove('hover')
	}

	cancelDefault(e) {
		e.preventDefault()
		e.stopPropagation()
		return false
	}

	isoDateString(d: Date): string {
		return d.getUTCFullYear() + '-'
			+ String((d.getUTCMonth() + 1)).padStart(2, '0')
			+ '-'
			+ String(d.getUTCDate()).padStart(2, '0')
	}

	schedDatePart(r: RaceWithRac) {
		if(!r.scheduled) { return '' }

		const d = date.parse(r.scheduled, 'YYYY-M-D HH:mm:ss', true)
		const datePart = `${
		 			(d.getMonth() + 1)
		 		}/${
		 			d.getDate()
				}/${
		 			d.getFullYear()
		 	}`
		return datePart
	}

}
