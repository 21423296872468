
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

import { Server, ServerState, Erg, Competitions, CompetitionsState } from 'shared/state'
import { Instance, CompetitionInstance } from 'shared/types/logBook'

import Reconnect from './Reconnect.vue'
import Spinner from './Spinner.vue'
import { GlobalConfig } from 'display/config'
import { getRaceServer } from 'shared/util/getRaceServer'

@Component({ components: { Reconnect, Spinner } })
export default class ServerRace extends Vue {
	server = Server
	erg = Erg

	@GlobalConfig() enableAnimation!: boolean
	@Prop({ default: 'en-US' }) lang!: string

	@ServerState('state') serverState
	@CompetitionsState('currentInstance') currentInstance

	@Watch('lang')
	languageChange(newLang: string) {
		Vue.loadLang(newLang)
	}

	connect() {
		if (this.ws && this.serverState !== 'connected') {
			Server.setWsUrl(this.ws)
		}
		Server.connect().then(() => this.$emit('ready', this.currentRaceServer))
	}

	beforeDestroy() {
		Server.disconnect()
	}

	get ws() {
		// console.log('ergrace ', Competitions.currentInstance)

		const port = Competitions.currentInstance.ergrace_url.includes('andrew') ?
			50001 : Competitions.currentInstance.ergrace_port
		return Competitions.currentInstance.ergrace_url + '/' + port
	}

	get currentRaceServer() {
		return getRaceServer(this.ws)
  	}

	@Watch('currentInstance', { deep: true, immediate: true })
	instanceChanged(newVal) {
		if (newVal) {
			this.connect()
			Erg.attachToServer(Server)
		}
	}
}
