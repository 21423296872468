
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class TextControl extends Vue {

	@Prop() label?: string
	@Prop({ default: '' }) value!: string
	@Prop() name!: string

}
