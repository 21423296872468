
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import Boat from './Boat.vue'
import draggable from 'vuedraggable'
import { MdCheckbox } from 'vue-material/dist/components'
import _, { isNull, isUndefined } from 'lodash'
import { Participant, RaceDefinition, RaceDefinitionBoat } from 'shared/types/erg'

Vue.use(MdCheckbox)

const boatFields = ['name', 'affiliation', 'class_name', 'logbook_id']
const boatTeamFields = ['name', 'affiliation', 'class_name']
const participantTeamFields = ['name', 'id']

interface TeamRaceDefinitionBoat extends RaceDefinitionBoat {
	participants: Participant[]
}

function selectElementContents(el) {
	const body = document.body
	const range = document.createRange()
	const sel = window.getSelection()
	if (!sel) {
		throw new Error('Error getting selection')
	}
	sel.removeAllRanges()
	range.selectNode(el)
	sel.addRange(range)
}


@Component({ components: { Boat, draggable } })
export default class Boats extends Vue {
	@Prop() race_definition!: RaceDefinition
	@Prop() teamSize!: number
	@Prop() classes!: any[]
	valid = true
	useclasses = true
	usecodes = true
	uselogbookid = true
	dupeLbIdError = false
	colors = ['#8dd3c7', '#ffffb3', '#bebada', '#fb8072', '#80b1d3', '#fdb462', '#b3de69', '#fccde5', '#d9d9d9', '#bc80bd', '#ccebc5', '#ffed6f']

	get invalidLbIds(): string[] {
		const lbIds = this.lbIds
		const lbIdCount = {}
		// tslint:disable-next-line
		for (let n = 0; n < lbIds.length; ++n) {
			const lbId = lbIds[n]
			if (!lbId) { continue }
			if (lbIdCount[lbId]) {
				++lbIdCount[lbId]
			} else {
				lbIdCount[lbId] = 1
			}
		}
		return Object.entries(lbIdCount).filter(([k, v]) => (v as number > 1)).map(([k, v]) => k)
	}

	get teamRace() {
		return this.teamSize > 1
	}

	number() {
		this.race_definition.boats.map((boat, index) => {
			boat.lane_number = index + 1
		})
	}
	change() {
		this.number()
	}
	removeBoat(index) {
		this.race_definition.boats.splice(index, 1)
		this.number()
	}

	addBoat() {
		const boats = this.race_definition.boats
		const teamSize = this.race_definition.team_size
		const boat: RaceDefinitionBoat = {
			name: '',
			lane_number: boats.length + 1,
			affiliation: '',
			class_name: '',
			logbook_id: '',
			participants: new Array(teamSize).fill(null).map(() => ({name:''}))
		}
		boats.push({...boat, lane_number: boats.length + 1})
	}

	*boatsFromTable(tableEl: HTMLElement | Document, teamSize: number): Generator<RaceDefinitionBoat> | undefined {
		// only works on team format but could easily be changed to support both
		const expectedWidth = boatTeamFields.length + participantTeamFields.length
		const tableRows = [...tableEl.querySelectorAll('tr')]
		const length = tableRows.length
		if (!length) { return }
		const rowZero = tableRows[0]
		if (!rowZero) { return }
		const cellsZero = [...rowZero.querySelectorAll('td, th')]
		const width = cellsZero.length
		if (!width) { return }

		if (width !== expectedWidth) {
			throw new Error(`Table must be ${expectedWidth} cols wide`)
		}

		const newBoat = (cells: HTMLTableDataCellElement[]): TeamRaceDefinitionBoat  => {
			const lane_number = 0
			const name = cells[0].textContent || ''
			const class_name = cells[1].textContent || ''
			const affiliation = cells[2].textContent || ''
			const participants = []
			return {class_name, lane_number, name, affiliation, participants}
		}

		const newParticipant = (cells: HTMLTableDataCellElement[]): Participant  => {
			const name = cells[ cells.length === 5 ? 3 : 0 ]
			const id = cells[ cells.length === 5 ? 4 : 1 ]
			return {
				name: name.textContent || '',
				logbook_id: id.textContent || ''
			}
		}

		let boat: TeamRaceDefinitionBoat | null = null

		for (const tableRow of tableRows) {
			if (
				tableRow.textContent?.toLowerCase()
				=== 'NameCodeClassLB Id'.toLowerCase()
				|| tableRow.textContent?.toLowerCase()
				=== 'NameCodeClassNameLB Id'.toLowerCase()
			) {
				continue
			}
			const cells = [...tableRow.querySelectorAll('td')]
			if (cells.length === expectedWidth || cells.length === participantTeamFields.length) {
				if (cells.length === expectedWidth && cells[0].textContent?.length) {
					if (!isNull(boat)) {
						for (let x = boat.participants.length; x < teamSize; x++) {
							boat.participants.push({id: '', name: ''})
						}
						yield boat
					}
					boat = newBoat(cells)
				}
			} else {
				throw new Error(`All rows must be ${expectedWidth} cols wide`)
			}
			if (isNull(boat)) { throw new Error('Boat should be defined before we get here') }
			if (boat.participants.length < teamSize) {
				boat.participants.push(newParticipant(cells))
			}
		}

		if (!isNull(boat)) {
			for (let x = boat.participants.length; x < teamSize; x++) {
				boat.participants.push({id: '', name: ''})
			}
			yield boat
		}
	}

	onPaste(event: ClipboardEvent) {
		const mimeType = 'text/html'
		const boats = this.race_definition.boats

		if (!(event.target instanceof HTMLInputElement)) {
			return
		}

		const data = event.clipboardData?.getData(mimeType)
		const doc = (new DOMParser()).parseFromString(data || '', mimeType)
		const table = [...doc.querySelectorAll('tr')].map(tr =>
			[...tr.querySelectorAll('td')].map(td => td.textContent)
		)
		if (!table.length) {
			// No table present in pasted data so let default handler get it.
			return
		}

		// Past this point we're sure we want to handle this event as pasted cells.
		event.preventDefault()
		const teamSize = this.race_definition.team_size || 1
		const col = parseInt(event.target.getAttribute('data-column') || '', 10)
		const row = parseInt(event.target.closest('div.boat')?.getAttribute('data-row') || '', 10)

		if (teamSize > 1) {
			// TODO handle team paste
			const newTeamBoats = [...this.boatsFromTable(doc, teamSize)]
			this.race_definition.boats = [
				...boats.slice(0, row),
				...newTeamBoats,
				...boats.slice(row + newTeamBoats.length, boats.length)
			]
			this.race_definition.boats.forEach((boat, idx) => {boat.lane_number = idx + 1})
			return
		}

		if (isNaN(col) || isNaN(row)) {
			throw new Error('Could not find valid col/boatRow offset')
		}

		const newRows = Math.max(0, row + table.length - this.race_definition.boats.length)

		// const participantRow = parseInt(event.target.closest('div.participant')?.getAttribute('data-row') || '');
		// const newBoats = Math.max(0, row + Math.ceil(table.length / teamSize) - this.race_definition.boats.length);
		for (let x = 0; x < newRows; x++) {
			this.addBoat()
		}
		const newboats = [
			...boats.slice(0, row),
			..._.zip(boats.slice(row, row + table.length), table).map(([boat, tableRow]) => {
				if (!tableRow) {
					return boat
				}
				const fields = boatFields.slice(col, col + tableRow.length)
				return {
					...boat,
					...Object.fromEntries(_.zip(fields, tableRow).filter(([key, value]) => key && value)),
				}
			}),
			...boats.slice(row + table.length, boats.length)
		]
		this.race_definition.boats = newboats
	}

	copyTable(evt) {
		const teamSize = this.race_definition.team_size || 1
		// const doc = new Document();
		const doc = document
		// const html = doc.createElement('html')
		// doc.appendChild(html)
		const div = doc.createElement('div')
		doc.body.appendChild(div)
		const table = doc.createElement('table')
		div.appendChild(table)
		try {
			{
				const tr = doc.createElement('tr')
				table.appendChild(tr)
				const headers = [
					'Name', 'Code', 'Class',
					...(teamSize > 1 ? ['Name'] : []),
					'LB Id'
				]
				headers.forEach(header => {
					const th = doc.createElement('th')
					th.textContent = header
					tr.appendChild(th)
				})
			}
			let idx = 0
			for (const boat of this.race_definition.boats) {
				const tr = doc.createElement('tr')
				if (idx % 2) {
					tr.style.backgroundColor = '#EAEAEA'
				}
				++idx
				table.appendChild(tr)
				const addBoatField = (textContent: string) => {
					const boatElement = doc.createElement('td')
					boatElement.textContent = textContent
					boatElement.setAttribute('rowspan', (this.race_definition.team_size || 1).toString())
					tr.appendChild(boatElement)
				}
				const addParticipantField = (tr, textContent: string) => {
					const element = doc.createElement('td')
					element.textContent = textContent
					tr.appendChild(element)
				}
				addBoatField(boat.name)
				addBoatField(boat.affiliation)
				addBoatField(boat.class_name)
				if (teamSize > 1 && boat.participants?.length) {
					const participant = boat.participants[0]
					addParticipantField(tr, boat.participants[0].name)
					addParticipantField(tr, boat.participants[0].id || '')
					for (let x = 1; x < boat.participants.length; ++x) {
						const {id, name} = boat.participants[x]
						const _tr = doc.createElement('tr')
						_tr.style.backgroundColor = tr.style.backgroundColor
						table.appendChild(_tr)
						addParticipantField(_tr, name)
						addParticipantField(_tr, id || '')
					}
				} else {
					addBoatField(boat.logbook_id || '')
				}
			}

			selectElementContents(div)
			doc.execCommand('copy')
		} finally {
			doc.body.removeChild(table)
		}
	}

	@Watch('teamSize', {deep: true})
	onTeamSizeChanged(teamSize: number | undefined) {
		const _teamSize = teamSize || 1
		this.race_definition.boats.forEach(boat => {
			if (!boat.participants) { return }
			boat.participants = [
				...boat.participants?.slice(0, _teamSize),
				..._.range(Math.max(0, _teamSize - boat.participants.length)).map(n => ({logbook_id: '', name: ''}))
			]
		})
	}

	get lbIds() {
		if (this.teamRace) {
			return this.race_definition.boats.flatMap((boat) =>
				(boat.participants || []).map((participant) => participant.logbook_id).filter(id => id)
			)
		} else {
			return this.race_definition.boats.map((b: RaceDefinitionBoat) => (b.logbook_id)).filter(id => id)
		}

	}

	get boatClasses() {
		const classSet = new Set(this.race_definition.boats.map(b => b.class_name).filter(c => c.length > 0))
		return [...classSet.values()]
	}

	boatClassColor(boatClass: string) {
		return this.colors[this.boatClasses.indexOf(boatClass)]
	}

	@Watch('race_definition.boats', {deep: true})
	onBoatsChanged(boats: RaceDefinitionBoat[]) {
		let lbIds
		if (this.teamRace) {
			lbIds = boats.flatMap((boat) =>
				(boat.participants || []).map((participant) => participant.logbook_id).filter(id => id)
			)
		} else {
			lbIds = boats.map((b: RaceDefinitionBoat) => (b.logbook_id)).filter(id => id)
		}
		const lbIdSet = new Set(lbIds)
		if (lbIdSet.size !== lbIds.length) {
			if (this.valid === true) {
				this.valid = false
				this.dupeLbIdError = true
				this.$emit('validity-changed', false)
			}
		} else if (this.valid === false) {
			this.valid = true
			this.dupeLbIdError = false
			this.$emit('validity-changed', true)
		}
	}
}
