
import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
import { Competitions, CompetitionsState } from 'shared/state/Competitions'
import { CompetitionType, RaceWithRac } from 'shared/types/competition'
import { LogBookUser } from 'shared/state/LogBookUser'
import { formatDate } from 'shared/util'
import { RaceListItem } from 'shared/types/racelistitem'
import Wizard from './Wizard.vue'
import type { RaceState } from 'shared/types/erg'

import { RemoteErg, RemoteErgState, ServerState, Erg, ErgState } from 'shared/state'

import Config from 'shared/components/config/_Config.vue'
import Select from 'shared/components/controls/Select.vue'

import { RemoteControl, RemoteState, RemoteScreen } from 'shared/state/RemoteControl'

import { Drag, Drop } from 'vue-drag-drop'

import ViewportSize from 'display/ViewportSize'

import { config, GlobalConfig } from 'display/config'
import { displayType } from 'shared/util'

import ServerRace from 'shared/components/ServerRace.vue'
import ServerPenConn from 'shared/components/ServerPenConn.vue'
import ConfigModal from 'display/components/ConfigModal.vue'
import { Server } from 'shared/state'
import { RemoteIdentify, ClientType } from 'shared/types/config-server'

import MainRaceArea from './MainRaceArea.vue'
import { RaceDefinition } from 'shared/types/erg'
import { bus } from 'shared/state/Bus'
import date from 'date-and-time'
import ProjectedTime from './ProjectedTime.vue'
import { formatUTCDate } from 'shared/util/formatDate'

@Component({
	components: {
		Wizard,
		MainRaceArea,
		ServerRace,
		ServerPenConn,
		ConfigModal,
		Config,
		SelectBox: Select,
		Drag,
		Drop,
		ProjectedTime
	}
})
export default class TabRaceManagement extends Vue {
	@Prop() race!: RaceWithRac

	@ErgState() status!: RaceState
	@ErgState() definition
	@ServerState() connected!: boolean
	@ServerState('state') serverState
	config = config.vals
	viewport = ViewportSize

	races: any[] = []

	clock: any = 0
	now = new Date()
	show = false

	requestAction(action: string, config?: any) {
		RemoteControl.remoteErgAction(action, config)
	}

	get fields() {
		return ['name', 'id', { key: 'online', label: 'Status' }]
	}

	get raceTitle() {
		return this.race?.description || ''
	}

	get raceName() {
		return this.race?.name || 'None'
	}

	get closeTime() {
		return this.race.rac.scheduled ? this.race.rac.scheduled : '5 min before'
	}
	get estimatedTime() {
		return this.race.rac.scheduled ? this.race.rac.scheduled : '5 min after'
	}

	get raceAreaId() {
		// unique id based on connection status and race id
		// ensures that if the connection is lost the view state
		// will be refreshed
		return this.serverState.concat(this.race.id.toString())
	}

	get raceState(): string {
		switch (this.status) {
			case 'inactive':
				return 'Athlete Status'
				break
			case 'warmup':
				return 'Warm Up'
				break
			case 'ready':
			case 'sit ready':
			case 'attention':
				return 'Prepare to Race'
				break
			case 'race running':
			case 'race running - sound horn':
				return 'race running'
				break
			case 'race complete':
			case 'final results':
				return 'Results'
				break
			case 'stop rowing':
				return 'stop rowing'
				break
			default:
				return this.status
		}
	}

	isoDateString(d: Date): string {
		return d.getUTCFullYear() + '-'
			+ String((d.getUTCMonth() + 1)).padStart(2, '0')
			+ '-'
			+ String(d.getUTCDate()).padStart(2, '0')
	}
	getTime(utc_date: Date) {
		if (utc_date) {
			const zone = utc_date ? utc_date.toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2] : ''
			return date.format(utc_date, 'h:mm A', false) + ' ' + zone
		}
		return ''
	}
	timeField(r: string) {
		if(!r) { return '' }
		const d = date.parse(r, 'YYYY-M-D HH:mm:ss', true)
		return r ? this.getTime(d) : ''
	}

	get currentLocalTime() {
		return this.timeField(formatUTCDate(this.now))
	}
	getNow() {
		this.now = new Date()
	}
	handler() {
		bus.$emit('holding_pen')
		this.requestAction('holding_pen')
	}
	created() {
		setTimeout(() => {
			this.requestAction('holding_pen')
			bus.$emit('holding_pen')}, 250)
		this.clock = setInterval(() => this.getNow(), 1000)
		// window.addEventListener('beforeunload', this.handler)
	}
	beforeDestroy() {
		clearInterval(this.clock)
	}
	cancelManagement() {
		bus.$emit('cancel_run_race')
	}
	@Watch('race.automatic', { immediate: true })
	raceChanged(newVal: boolean) {
		this.show = newVal
	}
}
