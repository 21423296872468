
import { Component, Vue, Prop } from 'vue-property-decorator'
import { makeComputedBinding } from 'shared/util'
import VueI18n, { LocaleMessages } from 'vue-i18n'
import { bus } from 'shared/state/Bus'

Vue.use(VueI18n)

export const defaultLanguages = [{
	label: 'English',
	value: 'en'
}]

export const annotatedLocales: any = []


function loadLocaleMessages(): LocaleMessages {
	const locales = require.context('../../_display/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
	const messages: LocaleMessages = {}
	locales.keys().forEach(key => {
	  const matched = key.match(/([A-Za-z0-9-_]+)\./i)
	  if (matched && matched.length > 1) {
		  const locale = matched[1]
		  messages[locale] = locales(key)
	  }
	})
	return messages
}

@Component
export class LanguageModule extends Vue {
	public supportedLanguages: any[] = defaultLanguages

	 I18n = new VueI18n({
		locale: process.env.VUE_APP_I18N_LOCALE || 'en',
		fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'zh-Hans-CN',
		messages: loadLocaleMessages()
	 })

	getSupportedLocales(): any {
		this.supportedLanguages = defaultLanguages

		Object.keys(this.I18n.messages.en.languages).forEach(key => {
			this.supportedLanguages.push({ label: this.I18n.messages.en.languages[key], value: key })
			annotatedLocales.push({
				code: key,
				name: this.I18n.messages.en.languages[key]
			  })

		})
		return this.supportedLanguages
	}

	created() {
		this.getSupportedLocales()
	}
}

export const Languages = new LanguageModule()
