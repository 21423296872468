import { SelectOption, SelectOptions } from 'shared/types'

export function normalizeSelectOptions(opts: SelectOptions): SelectOption[] {
	if(Array.isArray(opts)) {
		return opts.map(opt => typeof opt === 'string' ? { label: opt, value: opt } : opt)
	}

	return Object.keys(opts).map(label => ({
		label,
		value: opts[label]
	}))
}
