
import { Component, Prop, Vue } from 'vue-property-decorator'
import HeaderLinkAccount from './HeaderLinkAccount.vue'
import HeaderLinkCompetitions from './HeaderLinkCompetitions.vue'
import { bus } from 'shared/state/Bus'

@Component({ components: { HeaderLinkAccount, HeaderLinkCompetitions } })
export default class Header extends Vue {
	goHome() {
		bus.$emit('home')
		this.$router.push({ name: 'home', query: { id: this.myId } })
	}
	get myId() {
		return document.location.search.replace('?id=', '')
	}
}
