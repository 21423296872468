import { ConfigModule } from 'shared/types'
import { controls } from 'shared/components/controls'

export interface GlobalTheme {
	font: string
	headerTextColor: string
	headerBgColor: string
	headerHeight: number
	placeBgColor: string
	placeTextColor: string
	timeBgColor: string
	timeTextColor: string
	gutterColor: string
	colHeadingBg: string
	colHeadingText: string
	laneColor1: string
	laneColor2: string
	finishColor1: string
	finishColor2: string
}

import './blob'
declare module './blob' {
	interface ConfigBlob {
		globalTheme: GlobalTheme
	}
}

export const globalTheme: ConfigModule<GlobalTheme> = {
	page: '',
	panel: 'Theme',
	isTheme: true,
	fields: {
		font: {
			default: 'Proxima Nova Regular',
			control: controls.Text('Font'),
			private: true
		},
		headerBgColor: {
			default: '#0a002b',
			control: controls.Color(),
			group: 'Header Colors'
		},
		headerTextColor: {
			default: 'white',
			control: controls.Color(),
			group: 'Header Colors'
		},
		headerHeight: {
			default: 0.050925,
			control: controls.Number('Header Height %', 0, 0.001, 1)
		},
		placeBgColor: {
			default: '#a7e1f2',
			control: controls.Color(),
			group: 'Place Colors'
		},
		placeTextColor: {
			default: 'black',
			control: controls.Color(),
			group: 'Place Colors'
		},
		timeBgColor: {
			default: 'white',
			control: controls.Color(),
			group: 'Time Colors'
		},
		timeTextColor: {
			default: 'black',
			control: controls.Color(),
			group: 'Time Colors'
		},
		gutterColor: {
			default: '#80807e',
			control: controls.Color('Gutter'),
		},
		colHeadingBg: {
			default: '#b7b7b7',
			control: controls.Color(),
			group: 'Column Heading Colors'
		},
		colHeadingText: {
			default: 'black',
			control: controls.Color(),
			group: 'Column Heading Colors'
		},
		laneColor1: {
			default: '#002e5d',
			control: controls.Color(),
			group: 'Lane Colors'
		},
		laneColor2: {
			default: '#051c28',
			control: controls.Color(),
			group: 'Lane Colors'
		},
		finishColor1: {
			default: '#02294e',
			control: controls.Color(),
			group: 'Finished Lane Colors'
		},
		finishColor2: {
			default: '#003e84',
			control: controls.Color(),
			group: 'Finished Lane Colors'
		}
	}
}
