
import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
import Chevron from './Chevron.vue'
import { bus } from 'shared/state/Bus'
import { GlobalConfig } from 'display/config'
import VueI18n, { LocaleMessages } from 'vue-i18n'
Vue.use(VueI18n)

@Component({ components: { Chevron } })
export default class Wizard extends Vue {
	@GlobalConfig() enableAnimation!: boolean

	@Prop() raceState!: string
	transisitonState = ''

	get switchingToWarmingUp(): boolean {
		return (this.transisitonState === 'close entries')
	}
	get switchingToPrepare(): boolean {
		return (this.transisitonState === 'prepare')
	}
	get switchingToStart(): boolean {
		return (this.transisitonState === 'start')
	}
	get running(): boolean {
		return (this.raceState === 'race running')
	}

	closeEntries() {
		this.transisitonState = 'close entries'
	}
	raceDialog(message) {
		// the 'close entries' event will fires nearly simulaneously
		// wait 250ms before clearing the state to ensure state wins
		setTimeout(() => {this.transisitonState = ''}, 250)
	}
	loading() {
		this.transisitonState = 'close entries'
	}
	warmup() {
		this.transisitonState = 'warmup'
	}
	prepare() {
		this.transisitonState = 'prepare'
	}
	raceRunning() {
		this.transisitonState = 'race running'
	}
	holdingPen() {
		this.transisitonState = ''
	}
	start() {
		this.transisitonState = 'start'
	}

	onBadConnections(bad_connections) {
		if(bad_connections.length) {
			this.raceDialog('')
		}
	}
	created() {
		bus.$on('close entries', this.closeEntries)
		bus.$on('loading race', this.loading)
		bus.$on('race_dialog', this.raceDialog)

		bus.$on('warmup', this.warmup)

		bus.$on('prepare', this.prepare)

		bus.$on('start', this.start)

		bus.$on('race running', this.raceRunning)
		bus.$on('holding_pen', this.holdingPen)
		bus.$on('bad_connections', this.onBadConnections)

	}
	beforeDestroy() {
		bus.$off('close entries', this.closeEntries)
		bus.$off('loading race', this.loading)
		bus.$off('race_dialog', this.raceDialog)

		bus.$off('warmup', this.warmup)

		bus.$off('prepare', this.prepare)

		bus.$off('start', this.start)

		bus.$off('race running', this.raceRunning)
		bus.$off('holding_pen', this.holdingPen)
		bus.$off('bad_connections', this.raceDialog)

	}

	@Watch('raceState', {immediate: true})
	raceStateChanged(newVal) {
		if(newVal !== 'loading race') { this.transisitonState = '' }
	}
}
