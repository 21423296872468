
import { Vue, Component, Watch } from 'vue-property-decorator'
import { LogBookUser, LogBookUserState } from 'shared/state/LogBookUser'
import { LogBookUserDefinition } from 'shared/types/logBook'
import { Competitions } from 'shared/state/Competitions'
import { config, GlobalConfig } from 'display/config'

@Component({ components: {} })
export default class HeaderLinkAccount extends Vue {
  @LogBookUserState('logBookUserInfo') user!: LogBookUserDefinition
  @LogBookUserState() LogBookUserState
  @LogBookUserState() loggedIn!: boolean
  @GlobalConfig() devMode

  get avatar() {
  return (this.user.profile_image)
  ? this.user.profile_image
  : `/assets/img/cs-user.svg`
  }

  get classObject() {
	return !!(this.user.profile_image) ?
	{avatar: true} : {avatar: true, 'no-border-radius': true}
  }

  logMeOut() {
  Competitions.logout()

  }
  config() {
	if(this.$refs.popover) {
	const p = this.$refs.popover as Vue
	p.$emit('close')
  }
	this.$root.$emit('toggle-config')
  }
}
