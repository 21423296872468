import VueAnalytics from 'vue-analytics'

export default async function init(
	Vue: any,
	// server: { setWsUrl: (url: string) => void }
) {
	let env: any
	try {
		env = await fetch('/assets/environment.json').then((res) => res.json())
	} catch {
		console.log(
			'Could not either find or parse environment.json file.\n' +
				'Make sure that if present it is valid JSON and conforms to specifications.\n' +
				'Please see documentation.'
		)
	}

	if (env && env.global && env.global.googleAnalytics) {
		Vue.use(VueAnalytics, {
			id: env.global.googleAnalytics
		})
	}

	// if (env && env.global && env.global.websocketUri) {
	// 	server.setWsUrl(env.global.websocketUri)
	// } else {
	// 	console.log(
	// 		'Missing Web Socket Uri from environment.json. Should be a property of variable global.'
	// 	)
	// }

	return env
}
