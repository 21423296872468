/* tslint:disable:no-string-literal */
export const inspectlet = window['__insp']

export function identify(name: string, email: string) {
  if (inspectlet) {
	inspectlet.push(['identify', name])
	inspectlet.push(['tagSession', { email, userAgent: navigator.userAgent }])
  }
}

export function tag(tag: string, message: string) {
  inspectlet.push(['tagSession', { tag: message }])
}

export function debug(message: string) {
  if (inspectlet) {
	inspectlet.push(['tagSession', { debug: message }])
  }
}
