
import { Component, Prop, Vue } from 'vue-property-decorator'
import Pen from './Pen.vue'
import ButtonCol from './ButtonCol.vue'
import Race from './Race.vue'
import { Competitions, LogBookUser } from 'shared/state'
import { RemoteErg, RemoteErgPen, RemoteErgState } from 'shared/state'
import { Erg, ErgState } from 'shared/state/Erg'
import { bus } from 'shared/state/Bus'
import {
	MachineType,
	RaceDefinitionBoat,
	RemoteErgRaceAction,
	remoteErgRaceActions,
	RemoteErgRaceState,
	remoteErgRaceStates,
	RemoteErgRaceStatus,
} from 'shared/types/erg/index'
import {
	RemoteControl,
	RemoteState,
	RemoteScreen,
} from 'shared/state/RemoteControl'
import { formatTime } from 'shared/util/formatTime'
import { RaceDefinition } from 'shared/types/erg'
import { config, GlobalConfig } from 'display/config'
import { RaceWithRac } from 'shared/types/competition'
import VueClipboard from 'vue-clipboard2'

Vue.use(VueClipboard)

@Component({ components: {} })
export default class Results extends Vue {
	@Prop() race!: RaceWithRac
	@Prop() result: any
	@ErgState() definition!: RaceDefinition
	@ErgState() machines!: MachineType
	@GlobalConfig() devMode!: boolean

	ER = RemoteErg
	HP = RemoteErgPen

	items: any = []
	meta: any = {}
	sortBy = 'place'

	get ergRaceId() {
		return Erg.definition?.race_id
	}

	get resultsUrl() {
		return `https://results.ergrace.com/competition/${Competitions.currentCompetition.code}/race/${this.race.id}`
	}

	get ergResults() {
		return Erg.raceResults()
	}
	getResults() {
		if (this.result) {
			this.items = this.result.results.participants
		} else {
			this.items = this.ergResults
		}
	}
	requestAction(action: string, config?: any) {
		RemoteControl.remoteErgAction(action, config)
	}

	gotMachineResults(results) {
		this.items = this.ergResults
	}

	created() {
		this.getResults()
		Erg.$on('race_results', this.gotMachineResults)

		// check edge case where in final results state, user clicked on another race without returning to the race list,
		// for example, by refreshing the screen, or clicking on the competitions page,
		// then drilling back down to this competition
		if(this.race.id && this.ergRaceId && this.race.id.toString() !== this.ergRaceId.toString()) {
			this.requestAction('holding_pen')
		}
	}
	get errors() {
		let err = this.ER.errorMsg.replace(/\"/g, '')
		if(err === 'Error Uploading Workouts') {
			err += `<br/><span class="info">This may be due to no activity from a participant after ${config.vals.global.stopRaceMinutes} minute(s).</span>`
		}
		return err
	}
	get raceName() {
		return this.result
			? this.result.results.race_name
			: this.definition.name_short
	}

	get raceType() {
		return this.result
			? this.result.results.race_type
			: this.definition.race_type
	}

	get durationType() {
		return this.result
			? this.result.results.race_duration_type
			: this.definition && this.definition.duration_type
			? this.definition.duration_type
			: 'unknown'
	}

	get fields() {
		const f: any[] = []
		if (this.result) {
			// condition for full page.

			if (this.race.classes > 1) {
				f.push('place_in_class')
			}
			f.push({ key: 'place', label: 'Place' })
			f.push({ key: 'participant', label: 'Participant' })
			f.push({ key: 'avg_pace', label: 'Avg Pace' })
			f.push(
				this.durationType.includes('time')
					? { key: 'score', label: 'Score' }
					: { key: 'score', label: 'Score', formatter: 'score' }
			)

			return f
		} else {
			this.sortBy = 'Place'
			const r = Erg.raceResults()
			const keys = Object.getOwnPropertyNames(r[0])
			if(this.devMode) { console.log('right here', r[0], keys, 'items', this.items) }
			const f: any[] = [
				{ key: 'Place', label: 'Place' },
				{ key: 'Name', label: 'Name' },
				{ key: 'Average Pace', label: 'Avg Pace' },
			]

			if (this.race.classes > 1) {
				f.unshift({ key: 'Place in Class', label: 'Place in Class' })
				this.sortBy = 'Place in Class'
			}
			f.push({ key: 'Score', label: 'score', formatter: 'score' })
			if(this.devMode) { console.log('fields: ', f) }
			return f
		}
	}

	score(s: string) {
		let n = parseInt(s, 10)
		if(this.devMode) { console.log(n) }

		if (this.durationType.includes('time')) {
			return n ? n.toFixed(0) : ''
		} else {
			n = n / 10
			return n >= 3600 ? formatTime(Math.floor(n)) : formatTime(n, 1)
		}
		return ''
	}
	doCopy(text: string) {
		this.$copyText(text)
	}
	backToList() {
		bus.$emit('exit')
	}
}
