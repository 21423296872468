
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { RemoteErgStatePen } from 'shared/state'
import {
  AthleteStatus,
  RaceDefinition,
  Participant,
  RaceDefinitionBoat,
} from 'shared/types/erg/'
import { bus } from 'shared/state/Bus'
import { config, GlobalConfig } from 'display/config'
import { eligable } from 'shared/util/eligable'

interface ConnectionChoice extends AthleteStatus {
  choice: string
}

@Component({ components: {} })
export default class DroppedModal extends Vue {
  @Prop() problemConnections!: AthleteStatus[]
  @Prop() nonParticipants!: any[]
  @Prop() userDroppedConnections!: AthleteStatus[]
  @RemoteErgStatePen('athleteStatus') athleteStatus

  config = config.vals

  problemConnectionDecisions: ConnectionChoice[] = []

  reason(r: ConnectionChoice) {
	const found = this.athleteStatus.find((a) => {
		return a.logbook_id === r.logbook_id
	})
	// return found.erg_status?.status || 'nothing'
	// if (r?.erg_status && r.erg_status!.whenDisconnected) {
	// 	return 'disconnected'
	// }
	if (r?.com_stats?.quality === 0) { return this.$t('errors.PM5Error') }
	if (r?.erg_status?.status !== 'Ok' && found) {
		return found.erg_status?.status.replace('Offline', 'PM5 not connected')
	}
	if (r?.com_stats?.quality <= this.config.global.comStatQualityThreshold) {
		return this.$t('errors.poorQuality')
	}
	return this.$t('errors.notConnected')
  }

  onHide() {
	bus.$emit('bad_connections_decisions', this.problemConnectionDecisions)
	bus.$emit('close entries')
  }

  get allDropped() {
	return this.nonParticipants.concat(this.userDroppedConnections)
  }

  get warnings() {
	// collection of problem connections minus those erroring out
	return this.problemConnections.filter((c) => c.com_stats.quality)
  }

  @Watch('problemConnections', { immediate: true })
  problemConnectionsChanged(newVal: AthleteStatus[]) {
	const newlbIds = newVal.map((pc) => pc.logbook_id)
	const oldlbIds = this.problemConnectionDecisions.map(
		(pcd) => pcd.logbook_id,
	)

	// add any new
	newVal.forEach((c) => {
		if (!oldlbIds.includes(c.logbook_id)) {
		this.problemConnectionDecisions.push(
			Object.assign({}, c, { choice: 'drop' }),
		)
		}
	})

	// remove any that resolved
	this.problemConnectionDecisions.forEach((pcd) => {
		if (!newlbIds.includes(pcd.logbook_id)) {
		const foundIndex = this.problemConnectionDecisions.findIndex(
			(p) => p.logbook_id === pcd.logbook_id,
		)
		this.problemConnectionDecisions.splice(foundIndex, 1)
		}
	})
  }
  @Watch('athleteStatus', { immediate: true })
  // tslint:disable-next-line
  athleteStatusChanged(newVal, oldVal) {}
}
