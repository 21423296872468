
import { Component, Watch, Vue } from 'vue-property-decorator'

import { RaceState } from 'shared/types/erg/RaceState'
import { ErgState } from 'shared/state'
import { bus } from 'shared/state/Bus'
import { Machine } from 'shared/types/erg'
// tslint:disable-next-line
interface appEventType {
	error: boolean
	message: string
	detail: any | { lanes: number[] }
}

@Component({ components: {} })
export default class CtrlLogic extends Vue {
	// Implements CASE 6402
	@ErgState() numFinished!: number
	@ErgState() machines!: Machine[]
	@ErgState() status!: RaceState

	get remainingParticipantsTest() {
		if(!['race running', 'race running - sound horn'].includes(this.status)) { return false }
		if(!this.numFinished) { return false }
		if(!(!!(this.machines.find(m => !m.finished && (m.meters || m.calories))))) { return false }
		if(!this.machines.find(m => m.stopped)) { return false }
		return true
	}

	@Watch('remainingParticipantsTest', { immediate: true })
	remainingParticipantsTestChanged() {
		if(this.remainingParticipantsTest) {
			bus.$emit('app_event', { info: true, message: ' Remaining participants are not moving.  If the race is over, you must choose "Stop Race" to collect results.' })
			return
		}
		bus.$emit('app_event', { info: true })
	}

}
