
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import { Erg, ErgState } from 'shared/state/Erg'
import {
	RaceDefinition,
	Participant,
	RaceDefinitionBoat,
} from 'shared/types/erg/RaceDefinition'
import { wait } from 'shared/util'
import { config, GlobalConfig } from 'display/config'
import Config from 'shared/components/config/_Config.vue'
import ConfigModal from 'display/components/ConfigModal.vue'


@Component({ components: {ConfigModal, Config} })
export default class Sound extends Vue {
@ErgState() definition
@ErgState() status
@GlobalConfig() devMode
@GlobalConfig() testAudioTrigger!: number
@GlobalConfig() volume
audio = new Audio()
chromeStoppedAudio = false

	playAthleteEntered() {
		if(config.vals.global.playAudio) {
			this.playSound('chime.mp3')
		}
	}
	@Watch('testAudioTrigger')
	testAudio() {
		// make doubly sure this cannot happen during a race
		if(this.status === 'race running') { return }
		this.playSound('chime.mp3')
	}

	playSound(sound) {
		if(!this.audio.ended) {
			this.audio.pause()
		}
		this.audio.src = `/assets/sounds/${sound}`
		this.audio.volume = this.volume
		this.audio.play()
			.then(() => this.chromeStoppedAudio = false)
			.catch(() => this.chromeStoppedAudio = true)
	}

}
