const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']



export function formatDate(value: Date): string {
	return value.getMonth() + 1 + '/' + value.getDate() + '/' + value.getFullYear()
}

export function prettyDate(dt: string) {
	const d = new Date(dt)
	d.setTime(d.getTime() + (12 * 60 * 60 * 1000 + d.getTimezoneOffset() * 60 * 1000)) // make it 12 noon.
	const day = days[d.getDay()]
	return day + ' ' + months[d.getMonth()] + ' ' + d.getDate() + ', ' + d.getFullYear()
}

export function formatUTCDate(d: Date): string {
	return d.getUTCFullYear()
	+ '-' + (d.getUTCMonth() + 1)
	+ '-' + d.getUTCDate()
	+ ' ' + (d.getUTCHours() < 10 ? '0' +  d.getUTCHours() : d.getUTCHours())
	+ ':' +  (d.getUTCMinutes() < 10 ? '0' + d.getUTCMinutes() : d.getUTCMinutes())
	+ ':00'
}

