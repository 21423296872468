
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Competitions, CompetitionsState } from 'shared/state/Competitions'
import { CompetitionType } from 'shared/types/competition'

import { MdIcon, MdField } from 'vue-material/dist/components'
import { RaceDefinitionBoat } from 'shared/types/erg'
import LogbookIdField from './LogbookIdField.vue'
import { GlobalConfig } from 'display/config'

Vue.use(MdIcon)
Vue.use(MdField)

@Component({ components: {LogbookIdField} })
export default class Boat extends Vue {
	@Prop() boat!: RaceDefinitionBoat
	@Prop() index!: number
	@Prop() numBoats!: number

	@Prop() classes!: any[]
	@Prop() usecodes!: boolean
	@Prop() useclasses!: boolean
	@Prop() uselogbookid!: boolean
	@Prop() teamSize!: number
	@Prop() invalidLbIds!: string[]
	@Prop() color!: string
	@GlobalConfig() devMode!: boolean

	val: string = ''
	logbookIdClass: string = ''

	localBoat: any
	created() {
		this.localBoat = this.boat
	}

	get teamRace() {
		return (this.teamSize || 1) > 1
	}

	tabOffLastField(event) {
		const code = event.code
		if (!code) {
			if(this.devMode) { console.error('Keycode not found') }
		}

		if (code !== 'Tab') {
			return
		}

		if (this.boat.participants?.length) {
			const rowEl = event.target.closest('[data-row-participant]')
			if (!rowEl) {
				return
			}

			const rowNum = parseInt(rowEl.getAttribute('data-row-participant'), 10)
			// tslint:disable-next-line:use-isnan
			if (rowNum === NaN || rowNum < this.boat.participants.length - 1) {
				return
			}
		}

		const rowElBoat = event.target.closest('[data-row]')
		if (!rowElBoat) {
			return
		}

		const rowNumBoat = parseInt(rowElBoat.getAttribute('data-row'), 10)
		// tslint:disable-next-line:use-isnan
		if (rowNumBoat === NaN || rowNumBoat <  this.numBoats - 1) {
			return
		}

		this.$emit('add')
	}

	mounted() {
		if (this.boat.logbook_id) {
			this.logbookIdClass = ''
		} else {
			this.logbookIdClass = 'md-invalid'
		}
	}

	beforeUpdate() {
		if (this.boat.logbook_id) {
			this.logbookIdClass = ''
		} else {
			this.logbookIdClass = 'md-invalid'
		}
	}

	logbookIdChanged(value) {
		if (value && !this.invalidLbIds.includes(value)) {
			this.logbookIdClass = ''
		} else {
			this.logbookIdClass = 'md-invalid'
		}
	}

}
