
import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
import { Competitions, CompetitionsState } from 'shared/state/Competitions'
import { CompetitionType } from 'shared/types/competition'
import { LogBookUser, LogBookUserState } from 'shared/state/LogBookUser'
import { Instance, CompetitionInstance, AdminUser } from 'shared/types/logBook'
import RaceDetails from './RaceDetails.vue'


import { GlobalConfig } from 'display/config'
import { getRaceServer } from 'shared/util'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import LogbookIdField from './LogbookIdField.vue'
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

@Component({ components: {} })
export default class Admins extends Vue {
	@Prop() e!: CompetitionType
	@Prop() editMode!: boolean
	@Prop() admins!: AdminUser[]

	@LogBookUserState() logBookUserInfo
	@GlobalConfig() enableAnimation!: boolean

	newNum: string = ''
	delNum: number = -1
	adding = false
	adminNotFound = false

	get isAdmin() {
		return this.logBookUserInfo.roles.includes('ergrace-admin')
	}

	async addAdmin() {
		if(this.newNum) {
			this.adding = true
			const result: any = await LogBookUser.addAdmin(this.e.code, this.newNum)
			if(result.id) {
				this.admins.push(result)
				this.newNum = ''
			} else {
				this.adminNotFound = true
			}
			this.adding = false
		}
	}

	get delIconStyle() {
		return {
			float: 'right'

		}
	}

	async removeAdmin(logBookId: number) {
		this.adminNotFound = false
		this.newNum = ''
		if(this.admins.length === 1) {
			this.$bvModal.msgBoxOk(this.$t('text.oneOrganizerRequired').toString(), {
				title: this.$t('titles.alert').toString(),
				size: 'sm',
				buttonSize: 'sm',
				okVariant: 'outline-primary',
				okTitle: 'Ok',
				bodyClass: 'bdy',
				footerClass: 'p-2',
				hideHeaderClose: false,
				centered: true
			})
			return
		}
		if(logBookId === this.logBookUserInfo.id) {
			this.$bvModal.msgBoxConfirm(this.$t('text.removeMeAsAdmin').toString(), {
				title: this.$t('titles.alert').toString(),
				size: 'sm',
				buttonSize: 'sm',
				okVariant: 'outline-primary',
				okTitle: 'Ok',
				bodyClass: 'bdy',
				footerClass: 'p-2',
				hideHeaderClose: false,
				centered: true
			}).then(a => { if(a) {
				this.removeAdminConfirmed(logBookId)
				// refresh the competition list
				Competitions.getCompetitionsWithInstanceAndAdminsInfo()
			} })
		} else { this.removeAdminConfirmed(logBookId) }
	}
	async removeAdminConfirmed(logBookId) {
		this.delNum = logBookId
		const result = await LogBookUser.removeAdmin(this.e.code, logBookId)
		if(result) {
			const foundIndex = this.admins.findIndex(a => a.id === logBookId)
			if(foundIndex !== -1) { this.admins.splice(foundIndex, 1) }
		}
		this.delNum = -1
	}

	get myId() {
		return document.location.search.replace('?id=', '')
	}

}
