import Vue from 'vue'
import App from './App.vue'
import router from './router'

// Icons
import 'vue-awesome/icons/volume-off'
import 'vue-awesome/icons/volume-up'
import 'vue-awesome/icons/sign-in-alt'
import 'vue-awesome/icons/sign-out-alt'
import 'vue-awesome/icons/user-times'
import 'vue-awesome/icons/user'
import 'vue-awesome/icons/battery-quarter'
import 'vue-awesome/icons/wrench'
import 'vue-awesome/icons/chevron-up'
import 'vue-awesome/icons/chevron-down'
import 'vue-awesome/icons/sync-alt'
import 'vue-awesome/icons/exclamation-triangle'
import Icon from 'vue-awesome/components/Icon.vue'
Vue.component('icon', Icon)

// Bootstrap
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './scss/custom.scss'

Vue.use(BootstrapVue)

// Plugins
import { KeybindingsService } from 'shared/services'
// import { AnimationService } from 'display/services'
// Vue.use(TranslationService)
Vue.use(KeybindingsService)
// Vue.use(AnimationService)

Vue.config.productionTip = false

import { Server } from 'shared/state'
import init from 'shared/init'
import { config } from 'display/config'

import i18n from './i18n'

init(Vue).then(env => {
	config.init(env)
	new Vue({
		i18n,
		data: { env },
		router,
		render: (h) => h(App),
	}).$mount('#app')
})
