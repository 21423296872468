export interface RaceConfigType {
	durationMax: number,
	durationMin: number,
	durationDefault: number,
	splitValueDefault: (duration: number) => number,
	splitValueMin: number,
	units: string
}

export interface RaceConfigMapType {[index: string]: RaceConfigType}

export const RACE_CONFIG_MAP: RaceConfigMapType = {
	time: {
		durationMax: 604800,
		durationMin: 30,
		durationDefault: 240,
		splitValueDefault: (duration) => {
			if (duration <= 60) {
				return duration
			} else if (duration <= 60 * 20) {
				return 60
			} else {
				return Math.ceil(duration / 30 / 60 / 10) * 60 * 10
			}
		},
		splitValueMin: 60,
		units: 'seconds'
	},
	meters: {
		durationMax: 1000000,
		durationMin: 100,
		durationDefault: 2000,
		splitValueDefault: (duration) => {
			if (duration <= 1000) {
				return 100
			} else if (duration <= 15000) {
				return 500
			} else {
				return Math.ceil(duration / 30 / 1000) * 1000
			}
		},
		splitValueMin: 100,
		units: 'meters'
	},
	calories: {
		durationMax: 168000,
		durationMin: 5,
		durationDefault: 50,
		splitValueDefault: (duration) => {
			if (duration <= 50) {
				return 5
			} else {
				return Math.ceil(duration / 30 / 10) * 10
			}
		},
		splitValueMin: 5,
		units: 'calories'
	}
}

export const MAX_SPLITS = 50
export const MIN_SPLITS = 1
export const MAX_TIME_CAP = 86400
export const MIN_TIME_CAP = 30
