
import { Vue, Component, Prop } from 'vue-property-decorator'
import SimpleEncryptor from 'simple-encryptor'

const key = 'dfdsdlkjflsdjflsdjflsdjfljdsljfdslfsjldfjllsdflksdjfdskfjdslfdslfdssfl'
// tslint:disable-next-line:max-line-length
const answer = 'a6dfd91709d9c39ba7ba73cc34c45b8988684a4c62856b9568c049f7e1fa0afd1d1fc2ee9c1b356355537299fb6ef5f9hmw1+Cnu+qjw1ZQM0gNu/Q=='
const encryptor = SimpleEncryptor(key)
const PASS = encryptor.decrypt(answer)

@Component
export default class Login extends Vue {

	@Prop() value!: boolean

	showForm = false
	invalid = false
	password = ''

	submit() {
		if(this.password === PASS) {
			this.setLoginState(true)
			this.showForm = false
		} else {
			this.invalid = true
			setTimeout(() => { this.invalid = false}, 300)
		}
		this.password = ''
	}

	setLoginState(loggedIn: boolean) {
		localStorage.setItem('ERGLoggedIn', loggedIn ? 'true' : '')
		this.$emit('input', loggedIn)
	}

}
