import { ConfigModule } from 'shared/types'
import { controls } from 'shared/components/controls'
import { defaultLanguages } from 'shared/services/languages'
import { annotatedLocales } from 'shared/services/languages'

export interface GlobalConfig {
	websocketUri: string
	googleAnalytics: string
	lang: string
	theme: string
	devMode: boolean
	playAudio: boolean
	enableAnimation: boolean
	disableAlerts: boolean
	paceraceLockScore: boolean
	// remoteId: string
	logBookId?: string
	route: string
	testAudioTrigger: number
	recoverTime: number
	closeEntriesStatus: string[],
	volume: number,
	twoColThreshhold: number
	comStatQualityThreshold: number
	stopRaceMinutes: number
	maxRacers: number
	overrideRacePermissions: boolean
	suppressTachWarnings: boolean,
	storedRaceId: number
}

import './blob'
declare module './blob' {
	interface ConfigBlob {
		global: GlobalConfig
	}
}

export const global: ConfigModule<GlobalConfig> = {
	page: '',
	panel: 'Global',
	isTheme: false,
	fields: {
		websocketUri: {
			default: 'ws://localhost:443'
		},
		googleAnalytics: {
			default: ''
		},
		lang: {
			default: 'en',
			control: controls.LocaleSwitcher('Language', annotatedLocales),
			hideDuringRace: true
		},
		devMode: {
			default: false,
			private: true,
			control: controls.Checkbox('Development Mode')
		},
		playAudio: {
			default: true,
			control: controls.Checkbox('Play Audio')
		},
		enableAnimation: {
			default: true,
			control: controls.Checkbox('Enable Animations'),
		},
		disableAlerts: {
			default: false,
			control: controls.Checkbox('Disable Race Start Banners'),
			hideDuringRace: true
		},
		paceraceLockScore: {
			default: true
		},
		theme: {
			default: 'Default',
			control: { component: 'themeSelect', label: 'Theme' },
			hideDuringRace: true
		},
		// remoteId: {
		// 	default: '',
		// 	private: true
		// },
		route: {
			default: '',
			private: false
		},
		testAudioTrigger: {
			default: 0,
			control: { component: 'testAudio', label: 'Test Audio' },
			hideDuringRace: true
		},
		recoverTime: {
			default: 30,
			control: controls.Number('Recover Wait Time (seconds)', 3, 1, 60),
			hideDuringRace: false,
			private: true
		},
		closeEntriesStatus: {
			default: [],
		},
		volume: {
			default: 0.1,
			control: controls.Number('Volume', 0.1, 0.1, 1.0),
			hideDuringRace: true
		},
		twoColThreshhold: {
			default: 10,
			control: controls.Number('Two Col Threshhold', 0, 1, 40),
			hideDuringRace: true
		},
		comStatQualityThreshold: {
			default: 1,
			control: controls.Number('Com Quality Warning Threshold', 0, 1, 3),
			private: true
		},
		stopRaceMinutes: {
			default: 5,
			control: controls.Number('Stop Race Minutes', 0, 1, 60),
			hideDuringRace: true
		},
		maxRacers: {
			default: 32,
			control: controls.Number('Maximum Racers per Race', 0, 1, 150),
			private: true
		},
		overrideRacePermissions: {
			default: false,
			private: true,
			control: controls.Checkbox('Override Race Permissions')
		},
		suppressTachWarnings: {
			default: false,
			private: true,
			control: controls.Checkbox('Suppress Tach Warnings')
		},
		storedRaceId: {
			default: 0,
			private: true
		},

	}
}
