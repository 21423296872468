export interface RemoteErgRaceStatus {
	state: RemoteErgRaceState
	detail?: any
}

export type RemoteErgRaceState =
	| 'ready'
	| 'discovering'
	| 'numbering_all'
	| 'numbering_missing'
	| 'erg_status_sent'
	| 'starting_lane_set'
	| 'numbering_set'
	| 'saved_erg_map'
	| 'loaded_erg_map'
	| 'cleared_erg_map'
	| 'normal_mode_set'
	| 'done_numbering'
	| 'saved_race'
	| 'saved_loaded'
	| 'loaded_race'
	| 'sent_races'
	| 'sent_results'
	| 'merged_results'
	| 'status_summary_sent'
	| 'error'
	| 'closing'
	| 'sent_result'
	| 'athlete_status_sent'
	| 'sent_version'
	| 'erg_map_set'
	| 'prepare-syncRace'
	| 'start'
	| 'holding_pen_sent'
	| 'stop'
	| 'loading race'
	| 'discover_done'
	| 'setting_changed'
	| 'sent_version'
	| 'connect_display_sent'
	| 'error:save_load'
	| 'normal_mode_done'
	| 'prepare-syncRace'
	| 'simulation_on'
	| 'simulation_off'
	| 'sent_simulation'
	| 'sent_connections'
	| 'warmup'

export const remoteErgRaceStates: { [key: number]: RemoteErgRaceState } = {
	0: 'ready',
	1: 'discovering',
	2: 'numbering_all',
	3: 'numbering_missing',
	4: 'erg_status_sent',
	5: 'starting_lane_set',
	6: 'numbering_set',
	7: 'saved_erg_map',
	8: 'loaded_erg_map',
	9: 'cleared_erg_map',
	10: 'normal_mode_set',
	11: 'done_numbering',
	12: 'saved_race',
	13: 'saved_loaded',
	14: 'sent_races',
	15: 'loaded_race',
	16: 'sent_results',
	17: 'merged_results',
	18: 'status_summary_sent',
	19: 'error',
	20: 'closing',
	21: 'sent_result',
	22: 'athlete_status_sent',
	23: 'sent_version',
	24: 'erg_map_set',
	25: 'prepare-syncRace',
	26: 'start',
	27: 'holding_pen_sent',
	28: 'stop',
	29: 'loading race',
	30: 'discover_done',
	31: 'setting_changed',
	32: 'sent_version',
	33: 'connect_display_sent',
	34: 'error:save_load',
	35: 'normal_mode_done',
	36: 'prepare-syncRace',
	37: 'simulation_on',
	38: 'simulation_off',
	39: 'sent_connections',
	40: 'warmup',
	41: 'sent_simulation'
	// TO DO
	// ADD MORE
}
