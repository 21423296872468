
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { LogBookUser, LogBookUserState } from 'shared/state/LogBookUser'
import { CompetitionsState, Competitions } from 'shared/state/Competitions'
import { Competition, CompetitionType } from 'shared/types/competition'

@Component({ components: {} })
export default class HeaderLinkCompetitions extends Vue {
	@LogBookUserState() loggedIn!: boolean
	competitions: Competition[] = []
	get topCompetitions() {
		return this.loggedIn ? this.competitions.slice(0, 3) : []
	}

	async created() {
		if(this.loggedIn) {
			this.competitions =  await Competitions.getCompetitionsWithInstanceAndAdminsInfo()
		}
	}
	get myId() {
		return document.location.search.replace('?id=', '')
	}

	@Watch('loggedIn', {immediate: true})
	async loggedInChanged(newVal) {
		if(!newVal) {
			this.competitions = []
		} else {
			this.competitions =  await Competitions.getCompetitionsWithInstanceAndAdminsInfo()
		}
	}

}
