import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import {
	RemoteErgRaceState,
	remoteErgRaceStates,
	RemoteErgRaceStatus,
	RaceDefinition,
	RaceState,
	RemoteErgStatusMsg,
	AthleteStatus, ErgStatus, defaultAthleteStatus
} from 'shared/types/erg'

import { config } from 'display/config'

import { makeComputedBinding } from 'shared/util'
import deepClone from 'lodash'
import { RemoteControlPen } from './RemoteControlPen'
import { bus } from 'shared/state/Bus'

import { ErgState } from 'shared/state/Erg'
import { GlobalConfig } from 'display/config'



interface AcceptedType {
	connections: Array<{ serial_number: string }> | []
}
interface AcceptedExpandedType {
	connections: Array<{ logbook_id: string, name: string, serial_number: string }> | []
}


@Component
class RemoteErgModulePen extends Vue {


	public get raceID() {
		return this.raceId
	}

	public get anybodyOk() {
		return !!this.athleteStatus?.find(as => as.erg_status?.status === 'Ok')
	}
	@GlobalConfig() devMode!: boolean
	@GlobalConfig() suppressTachWarnings!: boolean
	@ErgState() status!: RaceState
	state: RemoteErgRaceState = 'ready'
	raceFiles: any = []
	resultsFiles: any = null
	ergMap: any = null
	ergStatus: any = null
	statusSummary: RemoteErgStatusMsg[] = []
	simulation: boolean = false
	race: RaceDefinition = {
		name_long: '',
		name_short: '',
		event_name: '',
		duration: 2000,
		duration_type: 'meters',
		race_type: 'individual',
		time_cap: 0,
		split_value: 0,
		boats: [],
		c2_race_id: ''
	}
	errorMsg: string = ''
	_athlete_status_sent_timeout
	preventPolling = false
	isRaceOrganizer = false
	connections
	lost_connections: AthleteStatus[] = []

	public athleteStatus: AthleteStatus[]  = []
	public acceptedFull: AthleteStatus[] = []
	competition: string = ''

	private raceId: string = ''
	private accepted: AcceptedType = { connections: [] }
	public setRunningRace(competition: string, raceId) {
		this.competition = competition
		this.raceId = raceId
	}

	public setRaceOrganizer(IsOrganizer: boolean) {
		this.isRaceOrganizer = IsOrganizer
		if (IsOrganizer) {
			RemoteControlPen.remoteErgAction('athlete_status')
		}
	}

	setAccepted(raceId, connections: AcceptedType, connectionsFull: AthleteStatus[]) {
		this.raceId = raceId
		this.accepted = connections
		if(JSON.stringify(this.acceptedFull) !== JSON.stringify(connectionsFull)) {
			this.acceptedFull = connectionsFull
		}
	}

	public setStatus(s: RemoteErgRaceStatus) {
		if (!Object.values(remoteErgRaceStates).includes(s.state)) {
			throw new Error('Invalid state: ' + s.state)
		}
		this.state = s.state

		switch (s.state) {
			case 'error':
				this.errorMsg = JSON.stringify(s.detail && s.detail.message ? s.detail.message : s.detail, null, 4)
				break
			case 'sent_connections':
				const c = s.detail.toString()
				.replace('Remote Config/Display Clients', '{"displayClients"')
				.replace('Mobile Clients', ', "mobileClients"') + '}'
				try {
					this.connections = JSON.parse(c)
				} catch {
					this.connections = { mobileClients: 0, displayClients: 0 }
				}
				break
			case 'sent_races':
				this.raceFiles = s.detail.races
				break
			case 'loaded_erg_map':
				this.ergMap = s.detail
				break
			case 'sent_results':
				this.resultsFiles = s.detail
				break
			case 'status_summary_sent':
				this.statusSummary = s.detail.status
				break
			case 'loaded_race':
				if (s.detail && s.detail.race_definition) {
					this.race = s.detail.race_definition
				}
				break
			case 'erg_status_sent':
				this.ergStatus = s.detail.erg_status
				break
			case 'cleared_erg_map':
				this.ergStatus = []
				break
			case 'saved_loaded':
				if (s.detail && s.detail.race_definition) {
					this.race = s.detail.race_definition
				}
				break
			case 'discover_done':
				this.$emit('discover_done', s.detail)  // 'Should be "ok"
				break
				case 'simulation_on':
					this.simulation = true
					this.$emit('simulation_on')
					break
			case 'simulation_off':
					this.simulation = false
					this.$emit('simulation_off')
				 break
			case 'sent_simulation':
				this.simulation = !!+s.detail
				this.$emit(this.simulation ? 'simulation_on' : 'simulation_off')
				break
			case 'athlete_status_sent':
				let _athleteStatus: AthleteStatus[]  = []

				_athleteStatus = s.detail.athlete_status
				_athleteStatus.forEach(as => {
					if(!as.erg_status) {
						as.erg_status = defaultAthleteStatus.erg_status
					} else {
						// as.com_stats.quality = 0
						// as.erg_status.status = 'Erg Not Ready'
						if (this.suppressTachWarnings && ['Low Battery', 'Tach wire issue'].includes(as.erg_status!.status)) {
							as.erg_status!.status = 'Ok'
						}
						const avg = parseInt(as.erg_status?.avg_rsp, 10)
						try {
							if (avg) {
								const lookup = this.athleteStatus.find(AS => AS.logbook_id === as.logbook_id)
								if(lookup && lookup.erg_status?.avg_rsp_log) {
									as.erg_status.avg_rsp_log = lookup.erg_status?.avg_rsp_log
									as.erg_status.avg_rsp_log?.push(avg)

									as.com_stats.bin_checksum =
										(as.com_stats.error_bin || 0) +
										(as.com_stats.warning_bin || 0) +
										(as.com_stats.ok_bin || 0) +
										(as.com_stats.good_bin || 0)
								} else {
									as.erg_status.avg_rsp_log = [avg]
								}
								if(as.erg_status.avg_rsp_log!.length > 150) { as.erg_status.avg_rsp_log!.shift() }
								as.erg_status.avg_rsp_long = Math.floor(as.erg_status.avg_rsp_log!
									.reduce((a, b) => (a + b)) / as.erg_status.avg_rsp_log!.length)
							}

						} catch(err) {
							if (this.devMode) { console.log('in catch', err, as) }
						}

					}
					// remove from lost_connections
					const foundLostIndex = this.lost_connections.findIndex(lost => lost.logbook_id === as.logbook_id)
					if(foundLostIndex !== -1) { this.lost_connections.splice(foundLostIndex, 1) }
				})
				// add any lost connections
				const lost = this.athleteStatus.filter(AS => !_athleteStatus.map(s => s.logbook_id).includes(AS.logbook_id))
				lost.forEach(l => {
					l.erg_status!.whenDisconnected = new Date()
					this.lost_connections.push(l)
				})

				this.athleteStatus = _athleteStatus

				this.$emit('athlete_status_sent', this.athleteStatus)
				if (this.devMode) { console.log('athlete_status_sent', this.athleteStatus) }

			default:
			break
		}
	}

	@Watch('status', {immediate: true, deep: true})
	statusChanged(newVal: RaceState) {
		this.preventPolling = (['ready',
		'sit ready',
		'attention',
		'race running',
		'race running - sound horn']
		.includes(newVal))

	}
	created() {
		this._athlete_status_sent_timeout = setInterval(
			() => {
				if((this.isRaceOrganizer || config.vals.global.overrideRacePermissions)  && !this.preventPolling) {
					if (this.devMode) {
						console.log('%cPen sending remoteErgAction',
							'font-size:large; color:green; font-weight:bold;',
							Object.assign({}, { race_id: this.raceId },
								this.accepted))
					}
					RemoteControlPen.remoteErgAction('athlete_status',
						Object.assign({},
							{ race_id: this.raceId },
							this.accepted))
				}
			},
			2000
		)
	}
	beforeDestroy() {
		clearInterval(this._athlete_status_sent_timeout)
	}
}

export const RemoteErgPen = new RemoteErgModulePen()
export const RemoteErgStatePen = makeComputedBinding(RemoteErgPen)
