const axios = require('axios').default
import { Vue, Component, Watch, Prop } from 'vue-property-decorator'
import { makeComputedBinding, formatTime } from 'shared/util'
import { CompetitionType, Competition, CompetitionCreationVals, RaceFormType, RaceWithRac, RaceWithoutRac } from '../types/competition'

import { LogBookUser } from './LogBookUser'
import { RaceServer } from 'shared/types/logBook'
import { LogBookRaceItemType } from '../types/racelistitem'

import { ServerType } from 'shared/types/logBook/logBookUser'
import { Instance, CompetitionInstance } from 'shared/types/logBook'
import { getCookie } from 'shared/util/cookie'
import { RaceDefinition, RaceDefinitionWrapper } from 'shared/types/erg'
import { getRaceServer } from 'shared/util/getRaceServer'
import { GlobalConfig } from 'display/config'
import { ServerModule } from 'shared/state/Server'
import ServerPermission from 'shared/components/ServerPermission.vue'
import ServerInstance from 'shared/types/ServerInstance'

// tslint:disable-next-line:class-name
interface resultRecord {
   raceId: number,
   result: any
}

@Component
class CompetitionsModule extends Vue {
  @GlobalConfig() devMode!: boolean
  competitions: Competition[] = []
  currentCompetitionCode = ''
  currentCompetition!: CompetitionType
  defaultInstance: Instance = {
	status: '',
	ergrace_url: '',
	ergrace_port: 0,
	holding_pen_port: 0,
	holding_pen_url: '',
	display_socket: '',
	display_url: ''
  }
  currentInstance: Instance = this.defaultInstance
  currentRaceServer = ''
  runningRaces: number[] = []
  results: resultRecord[] = []
  instances: Instance[] = []
  intialLoading: boolean = true
  lb = LogBookUser

  defaultRaceServerEndpoint = ''
	servers: RaceServer[] = []
	permissionServers: any = []

  clearCurrentCompetition() {
	this.currentCompetitionCode = ''
  }

  async getRunningInstances(s: RaceServer) {
	s.runningInstances = []
	const result = await axios.get(`https://${s.name}/instance`)
	if(result.status === 200 && result.data.success) {
		result.data.data.forEach((i: ServerInstance) => {
			s.runningInstances?.push(i)
		})
	}
  }

  async created() {
	if (!this.servers.length) {
		this.servers = await LogBookUser.getRaceServers()
		this.defaultRaceServerEndpoint = this.servers[0].name
		this.servers.forEach((s: RaceServer) => {
			s.wss = 'wss://admin.ergrace.com/secure-socket/' + s.name.split('.')[0] + '/60000'
			const m = new ServerPermission({ propsData: { wsUrl: s.wss } })
			this.permissionServers.push(m)
			this.getRunningInstances(s)
		})
	}

		// for local testing
		// const s = {ip: "", name: "localhost", type: <ServerType>"dev",
		// url: 'https://localhost', wss: 'wss://localhost:60000', ws: 'ws://localhost:60000'}
		// this.servers.push(s)
		// const ps = new ServerPermission({propsData: { wsUrl: s.wss }})
		// this.permissionServers.push(ps)
  }

  logout() {
	LogBookUser.logout()
	this.clear()
  }
  clear() {
	this.competitions = []
	this.currentCompetitionCode = ''
	this.currentInstance = this.defaultInstance
	this.runningRaces = []
	this.instances = []
	// location.replace('/')
  }
	// tslint:disable-next-line:max-line-length
  async createCompetition(competition: Competition | CompetitionCreationVals, server?: string): Promise<Instance | string> {
	let creatingInstance = false
	try {
		const result = await LogBookUser.createCompetition(competition)
		if (result.code) {
			this.competitions.unshift(result)
			creatingInstance = true
			const i = await this.createOrRenewInstance(result.code, server)
			if(typeof(i) === 'string')  { throw i }
			if (this.devMode) { console.log('i: ', i) }
			this.currentRaceServer = getRaceServer(i?.ergrace_url)
			this.currentCompetitionCode = result.code
			return i
		} else {
			throw new Error('Error recording in Logbook')
		}
	} catch(err) {
		return Promise.reject(err)
	}
  }

  async createOrRenewInstance(code: string, server?: string, forceNewPorts: boolean = false): Promise<Instance|string> {
	// check if already running
	const ER_Server = this.servers.find(s => s.name === server)
	const HP_Server = this.servers.find(s => s.name === server)
	const ER = ER_Server && ER_Server.runningInstances?.find(i => i.code === code && i.name.includes('ergrace'))
	const HP = HP_Server && HP_Server.runningInstances?.find(i => i.code === code && i.name.includes('holding_pen'))

	if(ER && HP) {
		return this.currentInstance
	}
	const api = server ? 'https://' + server : 'https://' + this.defaultRaceServerEndpoint

	try {
		const result = await axios
		.get(`${api}/instance/${forceNewPorts ? 'new/' :''}${code}?lb=${this.lb.logBookUserInfo.id}`, {
		headers: {
			Authorization: `Bearer ${getCookie('logbooktoken')}`
		}
		})
		if(!result.data.success) { return result.data.message }
		this.currentInstance = result.data.data
		this.currentInstance.competition_code = code
		this.instances.push(result.data.data)
		return result.data.data
	} catch (err) {
		return Promise.reject(false)
	}
  }

  async deleteInstance(code: string): Promise<boolean> {
	const i = this.instances.find(i => i.competition_code === code)
 if(!i) { return true }

	const server = getRaceServer(i.ergrace_url)

	const api = server ? 'https://' + server : this.defaultRaceServerEndpoint
	return await axios
		.get(`${api}/instance/delete/${code}`, {
		headers: {
			Authorization: `Bearer ${getCookie('logbooktoken')}`
		}
		})
		.then(async (result) => {
			const index = this.instances.findIndex(i => i.competition_code === code)
			if(index !== -1) {
				this.instances.splice(index, 1)
			}
			return result.data.success
		})
		.catch(err => false)
  }

  async getCompetitions() {
	const result = await LogBookUser.getCompetitions()
	this.competitions = result
	return result
  }
  async getCompetitionsWithInstanceAndAdminsInfo() {
	// called on intial page load. Gets everything
	Competitions.clear()
	this.intialLoading = true
	const result = await LogBookUser.getCompetitions('instance,admins')
	this.intialLoading = false
	if(result) {
		this.competitions = result
		this.instances = []
		result.forEach(async c => {
			const i = c.instance?.data
			if (i) {
				i.competition_code = c.code
				this.instances.push(i)
			}
		})
	}

	return result || []
  }

  async getInstances(code: string): Promise<Instance|null> {
	return LogBookUser.getInstances(code).then(r => r).catch(err => null)
  }

  async getCompetition(code: string): Promise<Competition | string> {
		this.currentCompetitionCode = code

		// set the current Instance at the same time

		// const result: Competition = await LogBookUser.getCompetition(code)
		// const instance = await this.getInstances(code)

  const [result, instance] = await Promise.all([
			LogBookUser.getCompetition(code),
			this.getInstances(code)
		])


		if(instance) {
			this.currentInstance = instance
		}
		this.currentRaceServer = getRaceServer(instance?.ergrace_url)

		this.currentCompetition = Object.assign({}, result)

		result.races.data.forEach(r => {
			let myRace = this.currentCompetition.races.data.find(cr => cr.id === r.id)
			if (!myRace) {
				this.currentCompetition.races.data.push(r)
			} else {
				myRace = r
			}
		})
		const i = await this.createOrRenewInstance(code, this.currentRaceServer)
		if (typeof (i) === 'string') { return i }
		if (i) {
			this.currentInstance = i
			this.currentInstance.competition_code = i.competition_code
		}

		return this.currentCompetition
  }
  async deleteCompetition(code: string): Promise<boolean> {
	  if(await this.deleteInstance(code))  {
		if(await LogBookUser.deleteCompetition(code)) {
		 return true
		}
		return false
	} else {
		return false
	}
  }
  async updateCompetition(competition: CompetitionType, server: string): Promise<CompetitionType> {
	const c = LogBookUser.updateCompetition(competition)
	c.then((success) => {
		if (success) {
		const i = this.competitions.findIndex((c) => {
			return c.code === competition.code
		})
		if (i !== -1) {
			this.competitions[i] = competition
			if (server) {
				this.currentRaceServer = server
				this.currentCompetitionCode = competition.code
			}
		}
		}
	})
	return c
  }
  async getRaces(): Promise<LogBookRaceItemType[]> {
	const result = await LogBookUser.getRaces(this.currentCompetitionCode, 'rac')
	return result
  }
  async getRace(raceId: number, include?: string): Promise<RaceWithRac | RaceWithoutRac> {
	return await LogBookUser.getRace(raceId, include)
  }
  async deleteRace(raceId: number) {
	const result = await LogBookUser.deleteRace(raceId)
	if(result && result.data && result.data.message && result.data.message === 'Race deleted successfully') {
		const index = this.currentCompetition.races.data.findIndex(r => r.id === raceId)
		if(index !== -1) {
		this.currentCompetition.races.data.splice(index, 1)
		}
	}
	return result
  }

	async saveRace(race: RaceWithRac): Promise<any> {
	const code = this.currentCompetition.code

	// convert the race.rac.data format for saving
	// race.rac_file.race_definition ...


	const newRace: any = {
		...race,
		rac_file: race.rac.data
	}

	if (!newRace.scheduled) {
		delete newRace.scheduled
		delete newRace.projected
	}

	if (newRace.projected as any === false) {
		delete newRace.projected
	}
	newRace.automatic = (newRace.automatic === 'true' || newRace.automatic === true)
	newRace.sweep = (newRace.sweep === 'true' || newRace.sweep === true)

	if (this.devMode) { console.log('race pre-save: ', newRace) }
	const result = await LogBookUser.saveRace(code, newRace)

	if (result && result.id) {
		if (this.devMode) { console.log('race result: ', result) }
		return result
	} else {
		return Promise.reject('did not save')
	}
  }

  async getRacFile(raceId: number): Promise<any> {
	return await LogBookUser.getRacFile(this.currentCompetitionCode)
  }
  async saveRacFile(raceId: number, race: RaceDefinition): Promise<RaceDefinitionWrapper> {

	return await LogBookUser.saveRacFile(raceId, race)
  }


  @Watch('currentCompetitions.rac.data.races', { deep: true, immediate: true })
  competitionsChanged(newVal) {
	// if(this.devMode) { console.log('current race changed', newVal)
  }
  async getResults(raceId: number ): Promise<any> {
	const r =  await LogBookUser.getResults(raceId)
	if(r) { return r }
	return
  }
}

export const Competitions = new CompetitionsModule()
export const CompetitionsState = makeComputedBinding(Competitions)
