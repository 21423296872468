
import { Vue, Component, Prop } from 'vue-property-decorator'

interface NumberOptions {
	step: number
	min: number | null
	max: number | null
}

@Component
export default class NumberControl extends Vue {

	@Prop() label?: string
	@Prop() name!: string
	@Prop() options!: NumberOptions
	@Prop() value!: number

	change(newVal: string) {
		const num = parseFloat(newVal)
		if(num === this.value) { return }
		if(this.options.min != undefined && num < this.options.min) { return }
		if(this.options.max != undefined && num > this.options.max) { return }
		this.$emit('input', num)
	}

}
