import Checkbox from './Checkbox.vue'
import Color from './Color.vue'
// import DisplayClasses from './DisplayClasses.vue'
import Multiselect from './Multiselect.vue'
import Number from './Number.vue'
import Radio from './Radio.vue'
import Select from './Select.vue'
import TestAudio from './TestAudio.vue'
import Text from './Text.vue'
// import ThemeSelect from './ThemeSelect.vue'
import VerticalSlider from './VerticalSlider.vue'
import LocaleSwitcher from './LocaleSwitcher.vue'

export const ControlRegistry: any = {
	checkbox: Checkbox,
	color: Color,
	// 'display-classes': DisplayClasses,
	multiselect: Multiselect,
	number: Number,
	radio: Radio,
	select: Select,
	testAudio: TestAudio,
	text: Text,
	// themeSelect: ThemeSelect,
	'vertical-slider': VerticalSlider,
	localeSwitcher: LocaleSwitcher
}

const genericControl = (component: string) => (label?: string, name?: string) => ({
	component,
	label,
	name
})
const controlWithOptions = (component: string) => (label?: string, options?: any, name?: string) => ({
	component,
	label,
	options,
	name
})

export const controls = {
	Checkbox: genericControl('checkbox'),
	Color: genericControl('color'),
	Number: (
		label: string,
		min: number | null = 0,
		step: number | null = 1,
		max: number | null = null,
		name?: string
	) => ({
		component: 'number',
		label,
		options: { min, step, max },
		name
	}),
	Multiselect: controlWithOptions('multiselect'),
	Radio: controlWithOptions('radio'),
	Select: controlWithOptions('select'),
	Text: genericControl('text'),
	LocaleSwitcher: controlWithOptions('localeSwitcher')
}
