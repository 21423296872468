
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Competitions, CompetitionsState } from 'shared/state/Competitions'
import { CompetitionType } from 'shared/types/competition'

import { MdIcon, MdField } from 'vue-material/dist/components'
import { Participant, RaceDefinitionBoat } from 'shared/types/erg'
import Boat from './Boat.vue'

Vue.use(MdIcon)
Vue.use(MdField)

@Component({ components: {} })
export default class LogbookIdField extends Vue {
	@Prop() participant!: Participant
	@Prop() invalidLbIds!: string[]

	logbookIdClass: string = ''

	tabOffLastField(event) {
		this.$emit('keydown', event)
	}

	mounted() {
		const value = this.participant.logbook_id
		if (value && !this.invalidLbIds.includes(value)) {
			this.logbookIdClass = ''
		} else {
			this.logbookIdClass = 'md-invalid'
		}
	}

	beforeUpdate() {
		const value = this.participant.logbook_id
		if (value && !this.invalidLbIds.includes(value)) {
			this.logbookIdClass = ''
		} else {
			this.logbookIdClass = 'md-invalid'
		}
	}

	@Watch('participant', {deep: true})
	participantChanged(newParticipant) {
		const value = this.participant.logbook_id
		if (value && !this.invalidLbIds.includes(value)) {
			this.logbookIdClass = ''
		} else {
			this.logbookIdClass = 'md-invalid'
		}
	}

}
