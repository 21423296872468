import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { makeComputedBinding } from 'shared/util'
import { ServerModule } from 'shared/state'
import { GrantResponse } from 'shared/types/grants'

import { bus } from 'shared/state/Bus'


@Component
export class PermissionClass extends Vue  {
	@Prop() Server!: ServerModule


	server = this.Server
	settings: any = {}

	private handle_grant_response(action: GrantResponse) {
		bus.$emit('permission', action)
	}

	private handle_got_store(store: any[]) {
		bus.$emit('store', store)
	}

	private handle_got_settings(settings: any) {
		this.settings = settings.settings
		bus.$emit('settings', settings.settings)
	}

	private created() {
			this.server.addListener((type, packet) => {
				if (typeof this['handle_' + type] === 'function') {
					this['handle_' + type](packet)
				}
			})

	}
}

// export const Permission = new PermissionClass()
// export const PermissionState = makeComputedBinding(Permission)
