
import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
import { Competitions, CompetitionsState } from 'shared/state/Competitions'
import { RacType, RacOnCompetition, RaceWithRac } from 'shared/types/competition'
import { bus } from 'shared/state/Bus'
import date from 'date-and-time'
import { Debounce } from 'vue-debounce-decorator'
import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'
import VueMaterialDateTimePicker from '../../../node_modules/vue-material-date-time-picker/dist/vue-material-date-time-picker.esm'

Vue.use(VueMaterial)


@Component({ components: {} })
export default class ProjectedTime extends Vue {
	@Prop() controlClass!: string
	@Prop() race!: RaceWithRac
	@Prop() disabled!: boolean
	@Prop() errorMessage!: string
	@Prop() suppress!: boolean

	projTime: boolean | string = this.projTimeFormatted
	inputChanged = false
	delay: any = 0

	isoDateString(d: Date): string {
		return d.getUTCFullYear() + '-'
			+ String((d.getUTCMonth() + 1)).padStart(2, '0')
			+ '-'
			+ String(d.getUTCDate()).padStart(2, '0')
	}
	@Debounce(1000)
	onInput(newValue: string) {
		if(this.inputChanged) { clearTimeout(this.delay) }
		this.inputChanged = true
		this.delay = setTimeout(() => {
			this.inputChanged = false
			this.projChanged(newValue)
		}, 100)
	}

	projChanged(newValue: string) {
		if(this.suppress) { return }
		if(!this.race?.scheduled || newValue === '' || newValue.toString().includes('NaN')) { return }
		const schedDate = this.race.scheduled ? date.parse(this.race.scheduled, 'YYYY-M-D HH:mm:ss', true) : new Date()
		const datePart = this.isoDateString(schedDate)
		const newDate = date.parse(`${datePart} ${newValue}`, 'YYYY-M-D HH:mm:ss', false)
		const newDateStr = `${datePart} ${newValue}:00`
		const d = date.parse(this.race.scheduled, 'YYYY-M-D HH:mm:ss', true)
		if(newDate < d) {
			setTimeout(() => {
				this.race.projected = this.race.scheduled
				this.projTime = false
				this.projTime = this.projTimeFormatted
			})
		}
		this.$emit('setVal', newDateStr)
		if(!(this.controlClass === 'race-form')) { bus.$emit('update_projected_time', this.race.id, newDate) }
	}

	timeField(r: string) {
		if(!r) { return '' }
		const d = date.parse(r, 'YYYY-M-D HH:mm:ss', true)
		return r ? this.getTime(d) : ''
	}

	getTime(utc_date: Date) {
		if (utc_date) {
			const zone = utc_date ? utc_date.toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2] : ''
			return date.format(utc_date, 'h:mm A', false) + ' ' + zone
		}
		return ''
	}

	get schedTimeFormatted() {
		if (typeof this.race.scheduled === 'string') {
			const d = date.parse(this.race.scheduled, 'YYYY-M-D HH:mm:ss', true)
			return `${date.format(d, 'hh:mm A')}`
		}
		return ''
	}

	get schedTimeUnformatted() {
		if (typeof this.race.scheduled === 'string') {
			const d = date.parse(this.race.scheduled, 'YYYY-M-D HH:mm:ss', true)

			const time = `${
					d.getHours().toString().padStart(2, '0')
				}:${
					d.getMinutes().toString().padStart(2, '0')
			}`
			return time
		}
		return ''
	}

	get projTimeFormatted() {
		if (typeof this.race.projected === 'string') {
			const d = date.parse(this.race.projected, 'YYYY-M-D HH:mm:ss', true)

			const time = `${
					d.getHours().toString().padStart(2, '0')
				}:${
					d.getMinutes().toString().padStart(2, '0')
			}`
			return time
		}
		return ''
	}

	mounted() {
		if(typeof(this.projTime) === 'string') { this.projChanged(this.projTime) }
	}
	// @Debounce(800)
	@Watch('race.projected', { immediate: true, deep: true})
	projectedChanged(newVal, oldVal) {
		if(newVal && oldVal && newVal !== oldVal) {
			this.projTime = this.projTimeFormatted
		}
	}}
