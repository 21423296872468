export function getRaceServer(socket) {

	// could be of form
	// wss://admin.ergrace.com/socket/rs3/55555
	// wss://admin.ergrace.com/ws-dev

	if(typeof(socket) !== 'string') { return '' }

	// preferred form, includes 'socket' : e.g. wss://admin.ergrace.com/socket/rs3
	if(socket.includes('/socket/')) {
		const abbr = socket.split('/socket/')
		if(abbr.length === 2) { return `${abbr[1].split('/')[0]}.ergrace.com`}
		return ''
	}

	// hard-coded form, e.g. wss://admin.ergrace.com/ws-dev

	let abbr = socket.split('com/')[1] || ''
	abbr = abbr.replace(/\/\d+/g, '')

	if(abbr === 'ws-dev') { return 'd.ergrace.com' }
	if(abbr === 'ws') { return 'rs1.ergrace.com' }

	const res = abbr.match(/ws\d+/g)
	if(res) {
		return 'rs' + res[0].replace('ws', '') + '.ergrace.com'
	}
	return ''
}
