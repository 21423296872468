
import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
import { Competitions, CompetitionsState } from 'shared/state/Competitions'
import { CompetitionType, RaceWithRac } from 'shared/types/competition'

import { formatDate } from 'shared/util'
import { RaceListItem } from 'shared/types/racelistitem'
import { RaceState } from 'shared/types/erg/RaceState'

import { RemoteErg, RemoteErgState, RemoteErgPen, RemoteErgStatePen, Erg, ErgState } from 'shared/state'
import { RaceDefinition } from 'shared/types/erg/'
import {
	RemoteErgRaceAction,
	remoteErgRaceActions,
	RemoteErgRaceState,
	remoteErgRaceStates,
	RemoteErgRaceStatus
} from 'shared/types/erg/index'
import { RemoteControl } from 'shared/state/RemoteControl'
import { RemoteControlPen } from 'shared/state/RemoteControlPen'

import CtrlButtonArea from './CtrlButtonArea.vue'
import AthleteConnectionStatus from './AthleteConnectionStatus.vue'
import Results from './Results.vue'
import { bus } from 'shared/state/Bus'
import { raceStateObjType, raceStateControls } from 'shared/types/raceStateControls'

@Component({ components: { CtrlButtonArea, AthleteConnectionStatus, Results } })
export default class MainRaceArea extends Vue {

	@Prop() raceState!: string
	@Prop() race!: RaceWithRac
	@ErgState() status!: RaceState
	showResult = true
	hasResult = false

	get ctrls(): raceStateObjType | string {
		const s = raceStateControls.find((s) => {
			return s.state === this.raceState
		})
		return s || this.status

	}

	requestAction(action: string, config?: any) {
		RemoteControlPen.remoteErgAction(action, config)
	}
	doCmd(command: string) {
		if (command.length && ['prepare', 'stop', 'start'].includes(command)) {
			this.requestAction(command)
		}
	}

	openDialog(message, title, callbackEvt) {
		this.$bvModal.msgBoxOk(message, {
			title,
			size: 'sm',
			buttonSize: 'sm',
			okVariant: 'primary',
			okTitle: 'Ok',
			bodyClass: 'bdy',
			footerClass: 'p-2',
			hideHeaderClose: false,
			centered: true,
			'no-stacking': true
		}).then(() => {
			if(callbackEvt) {
				bus.$emit(callbackEvt)
			}
		})
	}
	raceDialog(message, title, callEvt: string) {
		if(message) { this.openDialog(message, title, callEvt)}
	}
close() {
		this.showResult = false
		// bus.$emit('close_results_screen')
		// bus.$emit('tab')
	}

created() {
		bus.$on('race_dialog', this.raceDialog)

		// Handle edge case where page is refreshed after sending athletes to ergrace
		// and commands have not completed to advance to warmup status
		// send everyone back to the pen
		// if(this.status === 'inactive') {
		// 	RemoteControl.remoteErgAction('holding_pen')
		// }

		// Update: now moved to status Watcher routine.
		// now handles other cases when refresh occurs during the race
		// also, default status is 'unknown' to prevent holding+pen command if first race_status packet lost

		if(['race running - sound horn', 'race running'].includes(this.status)) {
		RemoteControl.remoteErgAction('athlete_status')
		}
	}
beforeDestroy() {
		bus.$off('race_dialog', this.raceDialog)
	}
@Watch('status', {immediate: true})
statusChanged(newVal, oldVal) {
		if(newVal === 'inactive' && oldVal === 'unknown') {
			// RemoteControl.remoteErgAction('holding_pen')
		}
		if(newVal === 'final results') {
			this.hasResult = true
		}
	}
@Watch('race.id', {immediate: true})
raceIdChanged() {
		this.hasResult = false
		this.showResult = true
	}
}
