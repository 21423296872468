
import { Component, Prop, Vue } from 'vue-property-decorator'
import LocaleSwitcher from 'shared/components/controls/LocaleSwitcher.vue'
import { BIcon, BIconPlusSquare, BIconDashSquare } from 'bootstrap-vue'

Vue.component('BIcon', BIcon)
Vue.component('BIconPlusSquare', BIconPlusSquare)
Vue.component('BIconDashSquare', BIconDashSquare)


@Component({components: { LocaleSwitcher,  BIcon, BIconPlusSquare, BIconDashSquare} })
export default class Footer extends Vue {
	expanded = false

	scroll() {
		this.expanded = !this.expanded
		setTimeout(() => {window.scrollTo(0, document.body.scrollHeight)}, 500)
	}
	get icon() {
		return this.expanded ? 'dash-square' : 'plus-square'
	}
}
