
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

import { ServerModule, PermissionClass } from 'shared/state'

import Reconnect from './Reconnect.vue'
import Spinner from './Spinner.vue'

@Component({ components: { Reconnect, Spinner } })
export default class ServerPermission extends Vue {

	get settings() {
		return typeof(this.permission) !== 'number' ? this.permission?.settings : {}
	}

	get ws() {
		return this.wsUrl
	}

	server = new ServerModule()
	permission: PermissionClass |  number = 0
	keepAlive = true
	keepAliveTimer: any

	@Prop({ default: 'en-US' }) lang!: string
	@Prop() wsUrl!: string // includes port

	setKeepAlive(b: boolean) {
		this.keepAlive = b
	}


	@Watch('lang')
	languageChange(newLang: string) {
		Vue.loadLang(newLang)
	}

	setTimer() {
		if (this.keepAliveTimer) { clearInterval(this.keepAliveTimer) }

		this.keepAliveTimer = setInterval(() => {
			this.ping()
		}, 900000) // 15 minutes
	}

	ping() {
		if (this.keepAlive && this.server.ws) {
			const msg: any = {action: 'ping'}
			this.server.send(msg)
		}
	}

// reconnect if browser gets focus and socket has disconnected
	addWindowFocusListener() {
		window.addEventListener('focus', () => {
			console.log(`${this.ws} ${this.server.state}`)

			if(this.server.state === 'disconnected') {
				this.connect()
				console.log(`${this.ws} ${this.server.state}`)
			}
		})
	}

	created() {
		this.connect()
		this.addWindowFocusListener()
	}

	connect() {
		if (this.ws && this.server.state !== 'connected') {
			this.server.setWsUrl(this.ws)
		}
		this.server.connect().then(() => {
			this.$emit('ready')
			this.permission = new PermissionClass({propsData: { Server: this.server}})
			this.permission.server.send({action: 'settings'})
			this.setTimer()
		})
	}

	beforeDestroy() {
		this.server.disconnect()
		clearInterval(this.keepAliveTimer)
	}
}
