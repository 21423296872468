
import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
import { Competitions, CompetitionsState } from 'shared/state/Competitions'
import { CompetitionType, RaceWithRac } from 'shared/types/competition'

import { formatDate } from 'shared/util'
import { RaceListItem } from 'shared/types/racelistitem'
import Wizard from './Wizard.vue'
import { RaceStatusData } from 'shared/types/erg/RaceStatus'
import { RaceState } from 'shared/types/erg/RaceState'

import { RemoteErg, RemoteErgState, Erg, ErgState, RemoteErgPen, RemoteErgStatePen } from 'shared/state'
import { RaceDefinition } from 'shared/types/erg/'
import {
	RemoteErgRaceAction,
	remoteErgRaceActions,
	RemoteErgRaceState,
	remoteErgRaceStates,
	RemoteErgRaceStatus
} from 'shared/types/erg/index'
import { RemoteControl } from 'shared/state/RemoteControl'
import { bus } from 'shared/state/Bus'
import { raceStateObjType, raceStateControls, IRaceStateObj } from 'shared/types/raceStateControls'
import { map } from 'lodash'
import { config, GlobalConfig } from 'display/config'
import { BootstrapVuePlugin } from 'bootstrap-vue'
import Athlete from './Athlete.vue'
import { AthleteStatus } from 'shared/types/erg/AthleteStatus'
import { eligable } from 'shared/util/eligable'
import CtrlLogic from './CtrlLogic.vue'
// tslint:disable-next-line
interface appEventType {
	error: boolean
	info?: boolean
	message?: string
	detail: any | { lanes: number[] }
}



@Component({ components: { CtrlLogic } })
export default class CtrlButtonArea extends Vue {
	@Prop() race!: RaceWithRac
	@Prop() raceState!: string
	@ErgState() status!: RaceState
	@ErgState() lanes!: RaceStatusData[]

	@GlobalConfig() devMode
	@GlobalConfig() recoverTime

	@RemoteErgState() errorMsg!: string
	@RemoteErgState() ergsNumbered!: boolean
	@RemoteErgState() anyNotReady!: boolean
	@RemoteErgState('anybodyOk') anybodyOkER!: boolean
	@RemoteErgState() everybodyOk!: boolean
	@RemoteErgState() transitioning!: boolean
	@RemoteErgState() problemErgs!: AthleteStatus[]
	@RemoteErgState() athleteStatus!: AthleteStatus[]
	@RemoteErgState('state') remoteErgState!: RemoteErgRaceState


	@RemoteErgStatePen('athleteStatus') HPathleteStatus!: AthleteStatus[]
	@RemoteErgStatePen() anybodyOk!: boolean

	pressed = ''
	timer
	timer2
	oldStatus: string = 'inactive'
	raceSaved = false // set to true after save_load
	appEvent: any = {}
	showConditionalButton = false

	done = false // 6 second delay after state change

	// autoPrepareAttempts = 0
	// autoPrepareUnderWay = false

	autoSendThingAttempts = 0
	autoSendThingUnderway = false
	errorMessage = ''
	forceMakeChangesVisisble = false
	forceBackToWarmupVisible = false
	waitingForEverybodyOk = false
	okToShowNoPMsConnectedMessage = true

	attemptsText = ['first attempt', 'second attempt', 'third attempt']
	errorStartingRaceWait: any = 0

	get showNoneJoinedMsg() {
		const visibleCloseEntriesBtn = this.ctrls.btns.find(b => b.cmd === 'pre_close_entries' && !b.hidden)
		return !this.HPathleteStatus.length
			&& !this.athleteStatus.length
			&& !this.transitioning
			&& this.okToShowNoPMsConnectedMessage
	}

	get ctrls(): raceStateObjType {
		return raceStateControls.find((s) => {
			return s.state === this.raceState
		}) || raceStateControls[0]
	}

	requestAction(action: string, config?: any) {
		RemoteControl.remoteErgAction(action, config)
	}
	press(b) {
		this.pressed = b.cmd
		if(b.disappearWhenClicked) { b.hidden = true}
		if(b.conditional) {
			this.showConditionalButton = false
			this.appEvent = { error: false, message: '', detail: {}}
		}
		// this.autoPrepareAttempts = 0
		// this.autoPrepareUnderWay = false

	}

	async doCmdConfirmed(btn) {
		this.press(btn)
		if(btn.disappearWhenClicked) { btn.hidden = true }
		if (btn.cmd.length && ['prepare', 'start'].includes(btn.cmd)) {
			this.requestAction(btn.cmd)
		}
		if(btn.cmd === 'stop') {
			bus.$emit('holding_pen', true)
			this.requestAction(btn.cmd)
		}
		if(btn.cmd === 'holding_pen') {
			RemoteControl.remoteErgAction('holding_pen')
			RemoteErg.clearMessage()
		}
		if(btn.cmd === 'warmup') {
			RemoteControl.remoteErgAction('warmup')
			RemoteErg.clearMessage()
		}
		if(btn.cmd === 'exit') {
			RemoteControl.remoteErgAction('holding_pen')
			RemoteControl.remoteErgAction('normal_mode')
			RemoteControl.remoteErgAction('exit')
			RemoteErg.clearMessage()
			bus.$emit('exit')
		}
		bus.$emit(btn.cmd)
	}

	async doCmd(btn) {
		if(btn.confirm) {
			this.$bvModal
			.msgBoxConfirm(
				this.$t('confirm.stopRace').toString(),
				{
					title: this.$t('confirm.stopRaceTitle').toString(),
					size: 'sm',
					buttonSize: 'sm',
					okVariant: 'danger',
					cancelVariant: 'success',
					okTitle: this.$t('buttons.stopRace').toString(),
					cancelTitle: this.$t('buttons.continueRace').toString(),
					bodyClass: 'bdy',
					footerClass: 'p-2',
					hideHeaderClose: false,
					centered: true,
					'hide-backdrop': true,
					'no-stacking': true
				}
			).then(answer => {
				if(answer) { this.doCmdConfirmed(btn) }
				})
		} else { this.doCmdConfirmed(btn) }
	}

	get definition() {
		return this.race.rac.data.race_definition
	}
	get flywheelsMoving(): boolean {
		return !!(this.status === 'stop rowing' && this.lanes && this.lanes.length)
	}

	get message(): string {
		let line1 = !this.autoSendThingUnderway
		? this.$t('raceInstructions.' + this.ctrls.message).toString() || ''
		: `<span class="error">${this.errorMessage || RemoteErg.errorMsg.replace(/\r?\n/g, '<br/>').replace('"', '').replace('"', '')}</span>`
		if(this.transitioning && this.raceState === 'Athlete Status') {
			line1 = 'Moving to warm up...'
		}
		if(this.transitioning && this.raceState === 'Warm Up') {
			line1 = 'Configuring Ergs for Race...'
		}
		if(this.appEvent.info) {
			line1 = this.appEvent.message
		}
		if(this.appEvent.error) { line1 = `<span class="error">${this.errorMessage}</span>` }
		if((this.status === 'stop rowing' && this.flywheelsMoving) ||
		(this.status === 'false start') ||
		(this.status === 'race aborted')) { line1 = this.$t(this.ctrls.error_message!).toString()}

		const line2 = `<span class="${this.flywheelsMoving ? 'flywheels' : 'error'}">`
		return line1.concat('<br/>', this.moreInfo, '<br/>', line2)
	}

	get enhancedLaneList(): string {
		const list: any = []
		if(!this.teamRace) {
			const boats = this.definition?.boats.filter(b => this.laneList.includes(b.lane_number)) || []

			if(boats.length) {
				boats.forEach(b => {list.push(`<br>${b.name} Lane ${b.lane_number}`)})
			}
			return list.toString()
		}

		const participants = RemoteErg.athleteStatus.filter
			(a => this.laneList.includes(parseInt(a.erg_status!.erg_number, 10)))

		if(participants.length) {
			participants.forEach(p => {
				this.definition.boats.forEach(b => {
					const raceParticipant = b.participants!.find(P => P.logbook_id === p.logbook_id)
					if(raceParticipant) {
						list.push(`<br>${raceParticipant.name} Lane ${b.lane_number}`)
					}
				})
			})
		}
		return list.toString()
	}

	get laneList(): number[] {
		return this.lanes.map(l => l.lane)
	}

	get moreInfo() {
		if(this.status === 'stop rowing') {
			return this.laneList ?
			`<span class="flywheels">${this.enhancedLaneList}</span>`
			: ''
		}
		return ''
	}

	get teamRace() {
		return this.definition.race_type.includes('team')
  	}

	get HPEligableConnectionsByLogbookId() {
		return this.HPathleteStatus.filter(a => eligable(a)).flatMap(a => a.logbook_id)
	}

	get HPEligableRacersByLogbookId() {
		return this.HPEligableConnectionsByLogbookId.filter((n) => {
			return this.participantIds.indexOf(n) !== -1
		})
	}

	showButton(b) {
		if(b.cmd === 'warmup' && this.raceState === 'loading race'
		&& this.errorMsg === 'Failed to Load Race Due to Erg Error') {
			return true
		}
		if(b.cmd === 'warmup' && this.raceState === 'stop rowing' && this.errorMsg === 'Some Ergs not OK.') {
			return true
		}
		if(b.cmd === 'holding_pen' && this.raceState === 'inactive' ) {
			return true
		}
		if(b.cmd === 'pre_close_entries') {
			return this.HPEligableRacersByLogbookId.length && !b.hidden
		}
		if(this.status === 'stop rowing' && b.cmd === 'warmup' && !this.everybodyOk) { return true }
		return ((!b.conditional && !b.hidden) || (b.conditional && this.showConditionalButton))
	}

	isDisabled(btn) {

		if(btn.cmd === this.pressed) { return true }
		if(btn.cmd === 'start' && this.flywheelsMoving) { return true }
		if(btn.cmd === 'start' && this.raceState === 'Prepare to Race') {
			return (this.appEvent.error || !this.everybodyOk)
		}
		// if(btn.cmd == 'holding_pen') { return !this.raceSaved && btn.disabled }
		// if(['prepare', 'start'].includes(btn.cmd) && (this.appEvent.error || !this.ergsNumbered)) {
		// 	return true
		// }
		// if(btn.cmd === 'warmup' && ['stop rowing', 'ready'].includes(this.status)) { return this.appEvent.error }
		if(btn.cmd === 'prepare' && this.status === 'warmup') {
			return !this.everybodyOk
		}
		if(btn.cmd === 'holding_pen' && ['warmup', 'sit ready'].includes(this.status) && this.forceMakeChangesVisisble) {
			return false
		}
		if(!!this.pressed.length) { return true }
		return false

	}

	resetButtons() {
		setTimeout(() => {
			if(this.ctrls.btns) { this.ctrls.btns.forEach(b => {
				b.hidden = (b.cmd === 'holding_pen' && b.conditional)
			})
			}
			this.pressed = ''
			this.forceMakeChangesVisisble = false
			this.forceBackToWarmupVisible = false
			clearTimeout(this.timer)
			clearTimeout(this.timer2)
			this.showConditionalButton = false
			this.appEvent = {}
			this.errorMessage = ''
		}, 250)
	}

	onAppEvent(event: any) {

		// show the back to warmup only IF no errors and flywheels moving?

		if(!event.detail?.checkbox) {
			// if making progress transitioning to ER (a checkbox was checked),
			// clear the timer to show button to Move Back to Pen
			if(event.detail?.clearTimer) { clearTimeout(this.timer) }

		}
		if(this.appEvent.error && !this.appEvent.level && event.level && this.done) {
			// ignore this error if there already is a higher level error (= one without a level > 1 )
			return
		}
		if(event.info || this.appEvent.info) {
			if(event.info) {
				this.appEvent = event.message ? event : {}
			}
			return
		}
		if(this.appEvent.error && !this.appEvent.level && event.level && ['warmup', 'ready'].includes(this.status)) {
		return
		}
		this.appEvent = event
	}
	@Watch('everybodyOk', {immediate: true})
	everybodyOkChanged(newVal, oldVal) {
		if(this.status === 'ready' && !newVal) {
			this.errorMessage = this.$t('errors.someErgsNotOk').toString()
			this.onAppEvent({ error: !this.everybodyOk,
			message: this.errorMessage + ', ' + this.$t('errors.waitingErgs').toString() })
		}
		if(this.status === 'warmup' && !newVal) {
			this.forceMakeChangesVisisble = true
			this.errorMessage = this.$t('errors.someErgsNotOk').toString()
			this.onAppEvent({ error: !this.everybodyOk,
			message: this.errorMessage + ', ' + this.$t('errors.waitingErgs').toString() })
		}
		if(this.waitingForEverybodyOk && newVal) {
			clearTimeout(this.errorStartingRaceWait)
			this.continueWithStart()
		}
		if(newVal) {
			this.errorMessage = ''
		}
	}

	continueWithStart() {
		this.waitingForEverybodyOk = false
		if(this.status === 'warmup') {
				this.timer2 = setTimeout(() => RemoteControl.remoteErgAction('prepare'), 6000)
			}
		if(this.status === 'inactive') {
				this.timer2 = setTimeout(() => RemoteControl.remoteErgAction('warmup'), 6000)
		}
	}

	@Watch('anyNotReady', {immediate: true})
	anyNotReadyChanged(newVal, oldVal) {

		// test condition if had a PM5 error but now it cleared
		// and is necessary to send back to Warmup.
		// Race state must be stop rowing (and no moving flywheels) or ready
		// also 6 seconds must have elapsed since change of race state
		// to allow Ergs to recover from normal 'Erg not ready' messages occuring on state change

		if(!newVal && oldVal) {
			// if(this.done && (this.status === 'stop rowing' && !this.flywheelsMoving) || this.status === 'ready') {
			// 	this.requestAction('warmup')
			// }
		}
	}

	onBadConnections(bad_connections: any[]) {
		if(bad_connections.length) {
			this.resetButtons()
		}
	}

	onCloseEntries() {
		this.appEvent = { error: false, message: 'Moving competitors into the race...' }
		clearTimeout(this.timer)
		// if the state does not change within a given time, re-enable the button
		this.timer = setTimeout(() => {
			this.pressed = ''
			this.appEvent = { error: true, message: '<strong style="color: red;">Taking too long...</strong>', detail: {}}
			this.showConditionalButton = true
		}, this.recoverTime * 1000)
	}
	sendBackToPen() {
		// it appears stuck in inactive state. Send everyone back to Pen.
		// this.autoPrepareUnderWay = false
		// this.autoPrepareAttempts = 0
		this.errorMessage = ''
		RemoteControl.remoteErgAction('holding_pen')
		bus.$emit('holding_pen')
		RemoteErg.clearMessage()
		this.resetButtons()
	}

	onErgError(error: any) {
		// TODO: 'Error Starting Race'

		if(['Error Starting the Race', 'Error Syncing Race Ergs',
		'Error Setting Race Parameters'].includes(error.message))  {
			bus.$emit('race_dialog', 'Error Starting the Race.', 'Error')
			this.sendBackToPen()
			return
		}
		// if (['Error Syncing Race Ergs',
		// 'Error Setting Race Parameters'
		// ].includes(error.message)) {
		// 	this.errorMessage = error.message + ', waiting for Ergs to recover'
		// 	this.appEvent = {error: true, message: error.message + ', waiting for Ergs to recover'}
		// 	if(this.status === 'inactive') {
		// 		RemoteControl.remoteErgAction('prepare')
		// 	}
		// 	this.waitingForEverybodyOk = true
		// 	this.autoPrepareUnderWay = true
		// 	this.errorStartingRaceWait = setTimeout(() => {
		// 		this.sendBackToPen()
		// 	}, 60000)


		// 	return
		// }
		if (['Error Going to Warm Up',
			'Error Downloading Participants',
			].includes(error.message)) {
			this.appEvent = {error: true, message: error.message + ', retrying'}
			this.errorMessage = error.message + ', retrying'

			if(['inactive', 'loading race'].includes(this.status)) {
				if(this.autoSendThingAttempts === 0) {
					setTimeout(() => bus.$emit('send_thing'), 6000)
					this.autoSendThingUnderway = true
					this.autoSendThingAttempts = 1
					return
				}
				if(this.autoSendThingAttempts < 2) {
					const message = 'Please wait, retrying command' + (!!this.autoSendThingAttempts ? ' for final time' : '')
					this.appEvent = { error: true, message }
					this.errorMessage = message
					setTimeout(() => bus.$emit('send_thing'), 6000)
					this.autoSendThingAttempts++
					return
				}

				this.autoSendThingUnderway = false
				this.autoSendThingAttempts = 0
				this.errorMessage = ''
				bus.$emit('race_dialog', 'After 3 tries, we still cannot properly close entries for the race. ' +
					'Concept2 suggests removing participants with poor or intermittent connections.', 'Error')
				RemoteControl.remoteErgAction('holding_pen')
				bus.$emit('holding_pen')
				RemoteErg.clearMessage()
				this.resetButtons()
			}
			return
		}
		this.appEvent = {error: true, message: error.message + ', retrying...'}
		this.errorMessage = error.message
		if(Erg.status !== 'race running') {
				setTimeout(() => {
				bus.$emit('holding_pen')
				RemoteControl.remoteErgAction('holding_pen')
				RemoteErg.clearMessage()
				this.errorMessage = ''
				this.appEvent = {}
				this.resetButtons()
				// this.autoPrepareUnderWay = false
				// this.autoSendThingAttempts = 0
			}, 6000)
		}
		bus.$emit('race_dialog', error.message, 'Error')
		// Failed to Load Race Due to Erg Error
	}

	get participantIds() {
		const r = this.race
		const boats = this.race.rac.data.race_definition.boats
		if (this.teamRace) {
			return boats.flatMap((boat) =>
				(boat.participants || []).map((participant) => participant.logbook_id)
			)
		} else {
			return boats.map((b) => b.logbook_id)
		}
	}

	created() {
		clearTimeout(this.timer)
		clearTimeout(this.timer2)
		bus.$on('race_dialog', this.resetButtons)
		bus.$on('app_event', this.onAppEvent)
		bus.$on('holding_pen', this.resetButtons)
		bus.$on('close entries', this.onCloseEntries)
		bus.$on('bad_connections', this.onBadConnections)
		bus.$on('run', this.resetButtons)
		bus.$on('error', this.onErgError)
	}

	beforeDestroy() {
		bus.$off('race_dialog', this.resetButtons)
		bus.$off('app_event', this.onAppEvent)
		bus.$off('holding_pen', this.resetButtons)
		bus.$off('close entries', this.onCloseEntries)
		bus.$off('bad_connections', this.onBadConnections)
		bus.$off('run', this.resetButtons)
		bus.$off('error', this.onErgError)
	}

	@Watch('status')
	statusChanged(newVal, oldValue) {
		this.done = false
		this.okToShowNoPMsConnectedMessage = false
		setTimeout(() => { this.okToShowNoPMsConnectedMessage = true }, 2000)
		setTimeout(() => this.done = true, 6000)
		this.oldStatus = this.status
		this.showConditionalButton = false
		clearTimeout(this.timer)
		if(newVal === 'loading race') { this.raceSaved = true }

		// if((newVal === 'warmup' || newVal === 'inactive') && this.autoPrepareUnderWay) {
		// 	if(this.autoPrepareAttempts < 2) {
		// 		const message = 'Please wait, retrying command' + (!!this.autoPrepareAttempts ? ' for final time' : '')
		// 		this.appEvent = { error: true, message }
		// 		RemoteControl.remoteErgAction('prepare')
		// 		this.autoPrepareAttempts++
		// 		return
		// 	}
		// 	this.autoPrepareUnderWay = false
		// 	this.autoPrepareAttempts = 0
		// 	bus.$emit('race_dialog', 'After 3 tries, we still cannot properly prepare the race. ' +
		// 		'Concept2 suggests removing participants with poor or intermittent connections.', 'Error')
		// 	RemoteControl.remoteErgAction('holding_pen')
		// 	bus.$emit('holding_pen')
		// 	RemoteErg.clearMessage()
		// 	this.errorMessage = ''

		// }
		if(newVal === 'warmup' && this.autoSendThingUnderway) {
			this.autoSendThingUnderway = false
			this.autoSendThingAttempts = 0
			this.errorMessage = ''
		}
		// if(newVal === 'ready' && this.autoPrepareUnderWay) {
		// 	// success
		// 	this.autoPrepareUnderWay = false
		// 	this.autoPrepareAttempts = 0
		// }
		this.appEvent = {}
		RemoteErg.clearMessage()
		this.errorMessage = ''
	}

	@Watch('ctrls', {deep: true})
	ctrlsChanged(newVal, oldVal) {
		oldVal.btns.forEach(b => {
				if(b.cmd === 'pre_close_entries') { b.hidden = false }
			})
		this.pressed = ''
	}

	@Watch('errorMsg', {immediate: true, deep: true})
	errorMsgChanged(newVal: string) {

		// ER gemerated errors. errorMsg will contain the returned error message
		if(newVal.length) {
			if(['inactive', 'loading_race', 'loaded', ].includes(this.status)) {
				this.showConditionalButton = true
				const closeEntriesBtn = this.ctrls.btns.find(b => b.cmd === 'pre_close_entries')
				if(closeEntriesBtn) {
					closeEntriesBtn.hidden = true
				}
				return
			}
		}

	}
}
