
import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
import { Competitions, CompetitionsState } from 'shared/state/Competitions'

import { CompetitionType, RaceWithRac } from 'shared/types/competition'
import { clone, cloneDeep } from 'lodash'

import { formatDate, sortByKeyDesc } from 'shared/util'
import { RaceListItem } from 'shared/types/racelistitem'
import { RaceStatusData } from 'shared/types/erg/RaceStatus'
import { RaceState } from 'shared/types/erg/RaceState'

import Athlete from './Athlete.vue'
import { union } from 'lodash'
import NotRegistered from './NotRegistered.vue'
import NoPM5s from './NoPM5s.vue'
import { sortByKeyAsc } from 'shared/util'
import Sound from './Sound.vue'
import Signal from './Signal.vue'
import {
	RemoteErgPen,
	RemoteErgStatePen,
	RemoteErg,
	RemoteErgState,
	Erg,
	ErgState,
	ServerState,
	LogBookUser,
} from 'shared/state'
import {
	RaceDefinition,
	Participant,
	RaceDefinitionBoat,
} from 'shared/types/erg/'
import {
	RemoteErgRaceAction,
	remoteErgRaceActions,
	RemoteErgRaceState,
	remoteErgRaceStates,
	RemoteErgRaceStatus,
} from 'shared/types/erg/index'

import CtrlButtonArea from './CtrlButtonArea.vue'

import Config from 'shared/components/config/_Config.vue'
import Select from 'shared/components/controls/Select.vue'

import {
	RemoteControl,
	RemoteState,
	RemoteScreen,
} from 'shared/state/RemoteControl'

import { Drag, Drop } from 'vue-drag-drop'
import { LogBookUserDefinition } from 'shared/types/logBook'
import { RemoteControlPen } from 'shared/state/RemoteControlPen'
import {
	AthleteStatus,
	defaultAthleteStatus,
} from 'shared/types/erg'
import { bus } from 'shared/state/Bus'
import App from 'display/App.vue'
import { config, GlobalConfig } from 'display/config'
import { map } from 'lodash'
import { eligable } from 'shared/util/eligable'
import _ from 'lodash'
import CommunicationsStats from './CommunicationsStats.vue'

import { bandwidth } from 'display/components/status/bandwidth'
import Boat from './Boat.vue'
import { BIcon, BIconExclamationCircleFill } from 'bootstrap-vue'

Vue.component('BIcon', BIcon)
Vue.component('BIconExclamationCircleFill', BIconExclamationCircleFill)

interface AthleteType {
	logbook_id: string
	name: string
	boat: RaceDefinitionBoat | null
	connectionQuality: string
}

interface RaceDefinitionSeat {
	index: number
	participant: Participant
}

@Component({ components: { Athlete, NotRegistered, NoPM5s, Signal,
	CommunicationsStats, BIcon, BIconExclamationCircleFill } })
export default class AthleteConnectionStatus extends Vue {
	@Prop() race!: RaceWithRac
	@ErgState() status!: RaceState
	@RemoteErgStatePen() athleteStatus
	@GlobalConfig() devMode!: boolean
	@ErgState() lanes!: RaceStatusData[]
	@RemoteErgState() problemErgs!: AthleteStatus[]
	HP = RemoteErgPen
	ER = RemoteErg

	modalShow = false
	logbook_id = ''
	name = ''
	boatName = ''

	affiliation = ''
	class_name = ''
	matchedOption = 1
	matchedSeatOption = 1

	connections: AthleteStatus[] = []
	oldconnections: any[] = []
	connectionsToErgRace: AthleteStatus[] = []
	registered: RaceDefinitionBoat[] = []
	transitioning = false
	once = false
	problemConnections: AthleteStatus[] = []
	nonParticipants: any[] = []

	matched: RaceDefinitionBoat[] = []
	// matchedSeats: RaceDefinitionSeat[] = [];

	stages: string[] = [
				'Marked No Shows',
				'Created Erg Map',
				'Disconnecting Ergs from Holding Pen',
				'Connecting to ErgRace',
				'All Ergs connected to ErgRace',
				'Stored New Race File',
				'Race File Received',
				'Loading',
				]
	progress: string[] = []
	config = config

	suppressCheckboxUpdates = false
	sound = new Sound()
	newNotRegistered: AthleteStatus | undefined = undefined
	noPM5s: AthleteStatus[] = []

	get participantTeamSizeMismatch() {
		if(!this.teamRace || !this.race.rac.data.race_definition) { return false }
		const participantNumber = this.definition.boats.reduce((a, currVal: any) => a + currVal.participants.length, 0)
		return participantNumber <
		(this.race.rac.data.race_definition.team_size || 9) * this.race.rac.data.race_definition.boats.length
	}

	get teamRace(): boolean {
		return (this.definition.team_size || 1) > 1
	}

	get nonConnectedLaneList(): string {
		if(this.transitioning) { return '' }
		return [
			...new Set([...map(this.lanes, 'lane'), ...this.nonConnectedLanes]),
		].toString()
	}
	get isDevMode() {
		return this.devMode
	}

	isFlyWheelSpinning(logbookId) {
		const ls = this.lanes.map(l => l.lane)
		const wheels: any = []
		this.connectionsToErgRace.filter(c => {
			if(ls.includes(parseInt(c.erg_status!.erg_number, 10))) {
				wheels.push(c.logbook_id)
			}
		})
		return wheels.includes(logbookId)
	}

	get nonConnectedLanes(): number[] {
		const n = this.boats
			.filter(
				(b) => {
					if (this.teamRace) {
						const participants = b.participants || []
						const nonConnectedParticipants = participants.map(p =>
							this.connStatus(b, this.connectedTo === 'Race', p) !== 'Ok'
						)
						return nonConnectedParticipants.includes(true)
					} else {
						return this.connStatus(b, this.connectedTo === 'Race') !== 'Ok'
					}
				}
			)
			.map((b) => b.lane_number)
		return n
	}

	get raceRunning() {
		return (['race running - sound horn', 'race running'].includes(this.status))
	}

	hasProblem(lb) {
		return !!this.problemErgs.find(pe => pe.logbook_id === lb)
	}

	get ergMessages() {
		return RemoteErg.messages
	}

	requestAction(action: string, config?: any) {
		RemoteControl.remoteErgAction(action, config)
	}

	get notRegistered(): AthleteStatus[] {

		return this.connections.filter((p, i) => {
			return !this.participantIds.includes(p.logbook_id) && p.erg_status?.status !== 'Offline'
		})
		.sort((a, b) => {
			return a.name.localeCompare(b.name)
		})
		// let notReg = this.connections.filter((p, i) => {
		// 	return !this.participantIds.includes(p.logbook_id)
		// })

		// const tmp: any = []
		// notReg.forEach(nr => {
		// 	tmp.push(nr)
		// })
		// return tmp
	}

	get boatOptions() {
		if (this.teamRace) {
			// Return boats with empty seats
			return this.boats
				.filter((boat) =>
					boat.participants?.map((p) => p.logbook_id || '').includes('')
				)
				.map((b) => ({
					text: b.name,
					value: b.lane_number,
				}))
		} else {
			const ms = this.matched.map((m) => m.logbook_id)
			const registeredAndNoLogbookId = this.registered
				.filter((r) => !r.logbook_id)
				.map((r) => r.lane_number)

			return this.boats
				? this.boats
						.filter((b) => {
							return !ms.includes(b.logbook_id)
						})
						.filter((r) => {
							return registeredAndNoLogbookId.includes(
								r.lane_number
							)
						})
						.map((b) => {
							return { text: b.name, value: b.lane_number }
						})
				: []
		}
	}

	get seatOptions(): Array<{ text: string; value: number }> {
		const index = this.boats.findIndex((b) => {
			return b.lane_number === this.matchedOption
		})
		if (index === -1) {
			return []
		}
		const boat = this.boats[index]
		const participants = boat.participants || []
		return participants
			.map(
				(p: Participant, i: number): RaceDefinitionSeat => ({
					participant: p,
					index: i,
				})
			)
			.filter(seat => (seat.participant.logbook_id || '') === '')
			.map((seat, j) => ({
				text: seat.participant.name || `Open Seat ${j + 1}`,
				value: seat.index
			}))
	}

	get boats(): RaceDefinitionBoat[] {
		return this.definition.boats
	}

	commStats(a: AthleteStatus) {
		if(!a) { return '' }
		return JSON.stringify(a, null, 4)

	}

	addRacer() {
		const lane_number = this.boats ? this.boats.length + 1 : 1
		let racer: RaceDefinitionBoat
		if (this.teamRace) {
			racer = {
				lane_number,
				name: this.boatName || `Team ${this.definition.boats.length + 1}`,
				affiliation: this.affiliation,
				class_name: this.class_name,
				participants: [
					{
						logbook_id: this.logbook_id,
						name: this.name || '',
					},
					...(_.range((this.definition.team_size || 1) - 1).map(n => ({logbook_id: '', name: ''})))
				],
			}
		} else {
			racer = {
				lane_number,
				name: this.name ? this.name : '',
				affiliation: this.affiliation,
				class_name: this.class_name,
				logbook_id: this.logbook_id,
			}
		}
		this.race.rac.data.race_definition.boats.push(racer)
		this.registered.push(racer)
		this.modalShow = false
		this.saveRace()
	}

	removeAthlete(a: AthleteStatus | RaceDefinitionBoat, withoutSave: boolean = false) {
		bus.$emit('remove_athlete', a)
		if(!this.teamRace) {
		const index = this.registered.findIndex(r => r.logbook_id === a.logbook_id)
		if(index !== -1) {
				this.registered.splice(index, 1)
				if(!this.teamRace) {
					const boatIndex = this.boats.findIndex(b => b.logbook_id === a.logbook_id)
					if(boatIndex !== -1) {
						this.race.rac.data.race_definition.boats.splice(boatIndex, 1)
					}
				}
				if(!withoutSave) {
					this.saveRace()
				}
			}
		} else {
			this.registered.forEach((r, index) => {
				if(r.participants) {
					const i = r.participants?.findIndex(p => p.logbook_id === a.logbook_id)
					if(i !== -1) {
						r.participants![i] = {logbook_id: '', name: ''}
						this.race.rac.data.race_definition!.boats[index]!.participants!.splice(i, 1, {logbook_id: '', name: ''})
					}
				}
				const n = this.race.rac.data.race_definition!.boats[index]!.participants!.filter(p => p.name === '').length
				if(n === this.race.rac.data.race_definition.team_size) {
					setTimeout(() => { this.race.rac.data.race_definition!.boats.splice(index, 1) }, 10)
				}
			})
			if(!withoutSave) {
					this.saveRace()
			}
		}
	}

	removeAll() {
		const promises: Array<Promise<any>> = []
		this.registered.forEach(a => {
			if(!this.teamRace) {
				promises.push(new Promise((resolve, reject) => {
						return setTimeout(() => {
							return resolve(this.removeAthlete(a, true))
						}, 10)
					})
				)
			} else {
			a.participants?.forEach(part => {
				promises.push(new Promise((resolve, reject) => {
					const found = this.connections.find((p) => {
							return p.logbook_id === part.logbook_id
					})
					return resolve (found ? this.removeAthlete(found, true) : null)
				})
				)
			})
			}
		})
		Promise.all(promises).then((values) => {
			this.saveRace()
		})
	}

	matchRacer() {
		const index = this.boats
			? this.boats.findIndex((b) => {
					return b.lane_number === this.matchedOption
			  })
			: -1

		if (index !== -1) {
			if (this.teamRace) {
				const boat = this.boats[index]
				const participant = boat.participants![this.matchedSeatOption]
				Vue.set(participant, 'logbook_id', this.logbook_id)
				participant.name = this.name
				// this.matchedSeats.push({
				// 	index: this.matchedSeatOption,
				// 	participant: {
				// 		logbook_id: this.logbook_id,
				// 		name: this.name,
				// 	},
				// });
			} else {
				const boat: any = {}
				Object.assign(boat, {}, this.boats[index])
				boat.logbook_id = this.logbook_id // do we really want one athlete posting results under another?

				this.race.rac.data.race_definition.boats.splice(index, 1, boat)
				this.registered.splice(index, 1, boat)
				this.matched.push(boat)
			}
		}
		this.modalShow = false
		this.saveRace()
	}

	get definition() {
		return this.race.rac.data.race_definition
	}

	connection(logbook_id): AthleteStatus | null {
	// returns connection based on logbook_id
		const connections = this.connectedTo === 'Race'
			? this.connectionsToErgRace
			: this.connections

		const found = connections.find((p) => {
			return p.logbook_id === logbook_id
		})
		return found || null
	}

	connStatus(
		b: RaceDefinitionBoat,
		toErgRace: boolean = false,
		participant: Participant | null = null
	): string {
		let logbook_id: string
		const connections = toErgRace
			? this.connectionsToErgRace
			: this.connections
		const NC = this.$t('text.notConnected').toString()

		if (!connections) {
			return NC
		}

		if (this.teamRace) {
			if (!participant) {
				// throw new Error('team race but no participants found')
				return this.$t('text.teamRaceNoParticipants').toString()
			}
			if (!participant.logbook_id) {
				return NC
			}
			logbook_id = participant.logbook_id
		} else {
			if (!b.logbook_id) {
				return NC
			}
			logbook_id = b.logbook_id
		}

		const found = connections.find((p) => {
			return p.logbook_id === logbook_id
		})

		if (found) {
			return found.erg_status!.status.replace(
				'Offline',
				'Connected / PM5 Offline'
			)
		}
		return NC
	}

	get participantIds() {
		const boats = this.boats
		if (this.teamRace) {
			return boats.flatMap((boat) =>
				(boat.participants || []).map((participant) => participant.logbook_id)
			)
		} else {
			return boats.map((b) => b.logbook_id)
		}
	}

	get hasClassOrAffiliations() {
		return this.definition.boats.find(b => b.class_name || b.affiliation)
	}

	addAthlete(c: AthleteStatus) {
		this.matchedOption = 1
		this.logbook_id = c.logbook_id || ''
		this.name = c.name
		this.boatName = this.$t('text.team') + ` ${this.definition.boats.length + 1}`
  if(!this.teamRace && !(this.hasClassOrAffiliations)) {
			this.addRacer()
		} else {
			this.modalShow = true
		}
	}

	onAthleteStatusSentPen(athleteStatus: AthleteStatus[]) {
		// console.log('heard it', JSON.stringify(athleteStatus, null, 4))
		// this.oldconnections = this.connections.map(c=>c.ranking_id)
		// const connection = athleteStatus.find(asc=>(!this.oldconnections.includes(asc.ranking_id)))
		// if(connection) {
		// 	this.connections.push(connection)
		// }

		if(this.devMode) { console.log(athleteStatus) }
		const oldNotRegistered = JSON.parse(JSON.stringify(this.notRegistered)).map(nr => nr.logbook_id)
		this.connections = []
		this.connections = athleteStatus

		this.newNotRegistered = this.notRegistered.find(o => !oldNotRegistered.includes(o.logbook_id))
		this.noPM5s = this.connections.filter(o => o.connected &&
				o.erg_status?.status === 'Offline' &&
				!this.participantIds.includes(o.logbook_id))

		if(this.newNotRegistered && !this.transitioning && this.status === 'inactive') {
			this.sound.playAthleteEntered()
		}

		const filtered = this.connections
			.filter(c => c.erg_status && this.participantIds.includes(c.logbook_id))

		const acceptedFull: any[] = filtered
			.map(c => ({logbook_id: c.logbook_id, name: c.name }))
		const accepted: any[] = filtered
			.map(c => ({ serial_number: c.erg_status!.serial_number}))
		RemoteErgPen.setAccepted(this.race.id, { connections: accepted }, acceptedFull)
	}

	onAthleteStatusSentErgRace(athleteStatus) {
		// console.log('message from ergrace', athleteStatus)
		this.connectionsToErgRace = []
		this.connectionsToErgRace = athleteStatus
	}

	get connectedTo() {
		return ['inactive', 'loading race'].includes(this.status) &&
			!this.transitioning && !this.ER.errorMsg.length

			? 'Pen'
			: 'Race'
	}

	resetCheckBoxes() {
		this.suppressCheckboxUpdates = true
		setTimeout(() => this.suppressCheckboxUpdates = false, 1000)

		this.$nextTick(() => {
			this.stages = [
				'Marked No Shows',
				'Created Erg Map',
				'Disconnecting Ergs from Holding Pen',
				'Connecting to ErgRace',
				'All Ergs connected to ErgRace',
				'Stored New Race File',
				'Race File Received',
				'Loading',
				]
			while (this.progress.length) {
					this.progress.pop()
				}
			config.set({ global: { closeEntriesStatus: this.progress } })
		})

	}

	async moveBackToPen(force?: boolean) {
		if(this.raceRunning && !force) {
			return
		}
		this.transitioning = false
		this.resetCheckBoxes()

		for (let i = this.registered.length; i > 0; i--) {
			this.registered.pop()
		}
		const noshows = (this.race.rac.data.race_definition && this.race.rac.data.race_definition.noshows)
				? this.race.rac.data.race_definition.noshows
				: []
		this.$nextTick(() => {this.race.rac.data.race_definition.boats.forEach(boat => {this.registered.push(boat)})})
		this.$nextTick(() => {if(noshows) {
			noshows.forEach(boat => {
				this.registered.push(boat)
				this.boats.push(boat)
				})

			for (let i = noshows.length; i > 0; i--) {
				noshows.pop()
		}

		}})

	}
	appEvent(evt) {
		if(evt.detail && evt.detail.checkbox) {
			// checkbox event
			const stageIndex = this.stages.findIndex(s => evt.detail.checkbox.includes(s))
			if(stageIndex !== -1 && !this.suppressCheckboxUpdates) {
				this.$nextTick(() => {
				this.stages.splice(stageIndex, 1, evt.detail.checkbox)
				this.progress.push(evt.detail.checkbox)
				})

			}
		}
		if(evt.message === 'holding_pen') {
			this.moveBackToPen()
		}
	}

	badConnections(bad_connections: AthleteStatus[]) {
		if(bad_connections.length) {
			this.transitioning = false
		}
		this.problemConnections = bad_connections
	}

	onNonParticipants(nonparticipants: any[]) {
		if(this.connections.length && nonparticipants.length
			&& JSON.stringify(this.nonParticipants) !== JSON.stringify(nonparticipants)) {
			this.transitioning = false
		} else {
			this.nonParticipants = nonparticipants
		}
	}

	addAll() {
		if(!this.teamRace && !this.hasClassOrAffiliations) {
		this.$bvModal
			.msgBoxConfirm(
				this.$t('confirm.addAll').toString(),
				{
					size: 'sm',
					buttonSize: 'sm',
					okVariant: 'success',
					cancelVariant: 'danger',
					okTitle: this.$t('text.yes').toString(),
					cancelTitle: this.$t('text.no').toString(),
					bodyClass: 'bdy',
					footerClass: 'p-2',
					hideHeaderClose: false,
					centered: true,
				}
			)
			.then(async (confirm) => {
				if (confirm) {
					if(this.devMode) {
						console.log(JSON.stringify(this.notRegistered.map(nr => ({ name: nr.name, logbook_id: nr.logbook_id}))))
					}
					this.notRegistered.forEach(nr => {
					if(this.devMode) {
						console.log('Adding ' + nr.name + ' ' + nr.logbook_id)
					}
					this.addAthlete(nr)
					})
				}
			})
		}
	}

	raceDialog(message) {
		this.transitioning = false
		this.resetCheckBoxes()
	}
	closeEntries() {
		this.transitioning = true
		this.nonParticipants = []
	}
	noDuplicateLogBookIdSanityCheck() {
		let boatsWithLogBookIds: any = []
		if(this.definition.race_type === 'individual') {
			boatsWithLogBookIds = this.definition.boats.filter(b => b.logbook_id && b.logbook_id.length).map(b => b.logbook_id)
		} else {
			this.definition.boats.forEach(b => {
				b.participants?.forEach(p => { if(p.logbook_id && p.logbook_id.length) {boatsWithLogBookIds.push(p.logbook_id)}})
			})
		}
		const unique = [...new Set(boatsWithLogBookIds)]
		if(this.devMode) { console.log(unique, unique.length, boatsWithLogBookIds, boatsWithLogBookIds.length) }
		if(boatsWithLogBookIds.length !== unique.length) {
			bus.$emit('app_event', {
				error: true,
				message: this.$t('errors.invalidRaceFile').toString(),
			})
		} else { bus.$emit('app_event', { error: false, message: '' })
		}
	}

	async saveRace() {
		const r = JSON.parse(JSON.stringify(this.race))
		const result = await Competitions.saveRace(r)
	}

	created() {
		bus.$on('race_dialog', this.raceDialog)
		bus.$on('close entries', this.closeEntries)
		bus.$on('app_event', this.appEvent)
		bus.$on('bad_connections', this.badConnections)
		bus.$on('non_participants', this.onNonParticipants)

		RemoteErgPen.$on('athlete_status_sent', this.onAthleteStatusSentPen)
		RemoteErg.$on('athlete_status_sent', this.onAthleteStatusSentErgRace)
		RemoteErg.$on('holding_pen', this.moveBackToPen)
		bus.$on('holding_pen', this.moveBackToPen)
	}

	beforeDestroy() {
		bus.$off('race_dialog', this.raceDialog)
		bus.$off('close entries', this.closeEntries)
		bus.$off('app_event', this.appEvent)
		bus.$off('bad_connections', this.badConnections)
		bus.$off('non_participants', this.onNonParticipants)

		RemoteErgPen.$off('athlete_status_sent', this.onAthleteStatusSentPen)
		RemoteErg.$off('athlete_status_sent', this.onAthleteStatusSentErgRace)
		RemoteErg.$off('holding_pen', this.moveBackToPen)
		bus.$off('holding_pen', this.moveBackToPen)
	}
	@Watch('status', { immediate: true, deep: true })
	statusChanged(newVal, oldVal) {
		if(newVal !== 'loading race') {
			setTimeout(() => { this.transitioning = false,
			// tslint:disable-next-line
			2000
			})
		}
	}

	@Watch('definition', { immediate: true, deep: true })
	definitionChanged(newVal, oldVal) {
		if(!this.transitioning && this.status === 'inactive') {
			// closing entries requires making changes to the race definition
			// e.g. boats, noshows...
			// but any other time the definition changes
			this.moveBackToPen()
		}
		this.noDuplicateLogBookIdSanityCheck()
	}
	@Watch('nonConnectedLanes', { immediate: true, deep: true })
	nonConnectedLanesChanged(n: number[], oldVal: number[]) {
		if (n.length && !this.transitioning && this.ER.anybodyOk && this.connectedTo === 'Race') {
			let message = ''
			message = this.$t('errors.issueWithCompetitor').toString()
			bus.$emit('app_event', {
				error: true,
				level: 2,
				message,
				detail: { lanes: n },
			})
		} else {
			bus.$emit('app_event', { error: false, level: 2 })
		}
	}
}
