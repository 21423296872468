
import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
import { DurationType, RaceDefinition, RaceType } from '../../../shared/types/erg'

import CompetitionForm from './CompetitionForm.vue'
import { bus } from '../../../shared/state/Bus'
import VueMaterialDateTimePicker from '../../../node_modules/vue-material-date-time-picker/dist/vue-material-date-time-picker.esm'

import { RaceFormType } from 'shared/types/competition'
import date from 'date-and-time'
import { Debounce } from 'vue-debounce-decorator'
import ProjectedTime from './ProjectedTime.vue'
import { RaceConfigType, RACE_CONFIG_MAP, MAX_SPLITS, MIN_SPLITS, MAX_TIME_CAP, MIN_TIME_CAP } from '../../../shared/util/raceConfig'
import { truncate } from 'fs'
import { formatUTCDate } from 'shared/util/formatDate'
import { toHHMMSS } from 'shared/util/formatTime'
import { config, GlobalConfig } from 'display/config'
import { Competitions, CompetitionsState } from 'shared/state/Competitions'
import { Competition } from 'shared/types/competition'

// Material
import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'

Vue.use(VueMaterial)
Vue.use(MdSwitch)


import MdSwitch from 'vue-material'


type LocalRaceType = 'individual' | 'team'
type LocalDurationType = 'meters' | 'time' | 'calories' | 'time calorie score'

@Component({ components: {
	VueMaterialDateTimePicker,
	ProjectedTime
} })
export default class RaceDetails extends Vue {
	MAX_TIME_CAP = MAX_TIME_CAP
	MIN_TIME_CAP = MIN_TIME_CAP
	toHHMMSS = toHHMMSS

	@Prop() race_definition!: RaceDefinition
	// @Prop() competition_form!: CompetitionForm;
	@Prop() race!: RaceFormType
	@CompetitionsState() competitions!: Competition[]
	@GlobalConfig() devMode
	comps = Competitions
	durationInvalid = false
	timeCapInvalid = false
	showDismissibleAlert: boolean = false
	scheduled: any = ''
	validationErrors: {[i: string]: null} = {}
	isValidRace = true
	splitsInvalidClass = ''
	durationInvalidClass = ''
	timeCapInvalidClass = ''
	raceNameFieldClass = ''
	schedDateClass = ''
	schedTimeClass = 'md-layout-nowrap'
	ignoreNextSchedChanged = false
	scheduledDatePart = this.scheduledDate
	hasScheduled: boolean = this.race.scheduled ? true : false
	clock: any = 0
	now = new Date()
	projectedError = ''

	get isPublic() {
		const currentCompetition = this.competitions.find(c => c.code.toString() === this.comps.currentCompetitionCode)
		return currentCompetition ? currentCompetition.promote : 'empty'
	}

	get usersAllowedToRunAutomatedRaces() {
		const serverAbbr = this.comps.currentRaceServer.split('.')[0] // rs1, rs2, d, etc. ...
		const permissionServer = this.comps.permissionServers?.find(ps => ps.wsUrl.includes(`/${serverAbbr}/60000`))
		return !!permissionServer?.permission?.settings.USERS_ALLOWED_TO_AUTOMATE_RACE
	}

	get race_type(): LocalRaceType {
		if (this.race_definition.race_type === 'individual calorie score') {
			return 'individual'
		} else if (this.race_definition.race_type === 'team calorie score') {
			return 'team'
		} else if (this.race_definition.race_type === 'individual') {
			return 'individual'
		} else if (this.race_definition.race_type === 'team') {
			return 'team'
		}
		throw new Error('invalid race type')
	}

	set race_type(newVal) {
		if (this.duration_type === 'time calorie score') {
			this.race_definition.duration_type = 'time'
			this.race_definition.time_cap = 0
			if (newVal === 'team') {
				this.race_definition.race_type = 'team calorie score'
			} else if (newVal === 'individual') {
				this.race_definition.race_type = 'individual calorie score'
			}
		} else {
			this.race_definition.race_type = newVal
		}
	}

	get timedRace() {
		return this.race_definition.duration_type === 'time'
	}

	get duration_type(): LocalDurationType {
		const _race_type = this.race_definition.race_type
		if (_race_type === 'team calorie score' || _race_type === 'individual calorie score') {
			return 'time calorie score'
		}
		return this.race_definition.duration_type
	}

	set duration_type(newVal: LocalDurationType) {
		if (newVal === 'time calorie score') {
			this.race_definition.duration_type = 'time'
			this.race_definition.time_cap = 0

			if (this.race_type === 'team') {
				this.race_definition.race_type = 'team calorie score'
			} else if (this.race_type === 'individual') {
				this.race_definition.race_type = 'individual calorie score'
			}

		} else {
			this.race_definition.duration_type = newVal
			this.race_definition.race_type = this.race_type
		}
	}

	_isValidRace(): boolean {
		return Object.keys(this.validationErrors).length === 0
	}

	get raceConfig(): RaceConfigType {
		return RACE_CONFIG_MAP[this.race_definition.duration_type]
	}

	get split_units(): string {
		return this.raceConfig.units
	}

	get durationMax(): number {
		return this.raceConfig.durationMax
	}

	get durationMin(): number {
		return this.raceConfig.durationMin
	}

	get split_total(): number {
		// Minimum Race Splits is 1. Fractional Splits Round up to nearest integer
		let total = this.race_definition.duration / this.race_definition.split_value
		total = (total <= 0) ? 1 : total
		return Math.ceil(total)
	}

	toSeconds(hours: number, minutes: number, seconds: number): number {
		return hours * 60 * 60 + minutes * 60 + seconds
	}

	get durationHours(): number {
		return Math.floor(this.race_definition.duration / 60 / 60)
	}

	set durationHours(val: number) {
		this.race_definition.duration = this.toSeconds(val, this.durationMinutes, this.durationSeconds)
		this.durationChanged()
	}

	get durationMinutes(): number {
		return Math.floor(this.race_definition.duration / 60 % 60)
	}

	set durationMinutes(val: number) {
		this.race_definition.duration = this.toSeconds(this.durationHours, val, this.durationSeconds)
		this.durationChanged()
	}

	get durationSeconds(): number {
		return Math.floor(this.race_definition.duration % 60)
	}

	set durationSeconds(val: number) {
		this.race_definition.duration = this.toSeconds(this.durationHours, this.durationMinutes, val)
		this.durationChanged()
	}

	get splitUnitsErrorMsg(): string {
		const min = RACE_CONFIG_MAP[this.race_definition.duration_type].splitValueMin
		const minSplitRequirement = min ? `at least ${min} ${this.split_units} and ` : ``
		return `Must be ${minSplitRequirement}less than or equal to duration. Split count must be between 1 and 50.`
	}

	get timeCapHours(): number {
		return Math.floor((this.race_definition.time_cap || 0) / 60 / 60)
	}

	set timeCapHours(val: number) {
		this.race_definition.time_cap = this.toSeconds(val, this.timeCapMinutes, this.timeCapSeconds)
		this.timeCapChanged()
	}

	get timeCapMinutes(): number {
		return Math.floor((this.race_definition.time_cap || 0) / 60 % 60)
	}

	set timeCapMinutes(val: number) {
		this.race_definition.time_cap = this.toSeconds(this.timeCapHours, val, this.timeCapSeconds)
		this.timeCapChanged()
	}

	get timeCapSeconds(): number {
		return Math.floor((this.race_definition.time_cap || 0) % 60)
	}

	set timeCapSeconds(val: number) {
		this.race_definition.time_cap = this.toSeconds(this.timeCapHours, this.timeCapMinutes, val)
		this.timeCapChanged()
	}
	sweepChanged(val) {
		// ensure boat table participants updates
		// by temporarily changing team_size value
		if (this.race_definition.race_type === 'team') {
			const oldTeamSize = this.race_definition.team_size || 2
			this.$set(this.race_definition, 'team_size', 30)
			setTimeout(() => this.$set(this.race_definition, 'team_size', oldTeamSize), 500)
		}
	}

	changeDurationType(event): void {
		const cfg = RACE_CONFIG_MAP[this.race_definition.duration_type]
		this.race_definition.duration = cfg.durationDefault
		this.race_definition.split_value = cfg.splitValueDefault(cfg.durationDefault)
		this.validationErrors = {}
	}

	changeRaceType(event): void {
		if (this.race_definition.race_type === 'team') {
			this.$set(this.race_definition, 'team_size', 2)
			this.$set(this.race_definition, 'team_scoring', 'avg')
		} else {
			this.$set(this.race_definition, 'team_size', undefined)
			this.$set(this.race_definition, 'team_scoring', undefined)
		}
	}

	setInvalid(target: Vue) {
		const field = target.$el.closest('.md-field') as HTMLElement
		field.classList.add('md-invalid')
		const wrapper = target.$el.parentElement?.parentElement as HTMLElement
		if(wrapper) {
			wrapper.classList.add('md-invalid')
		}
		this.validationErrors[(target as any).name] = null
		this.isValidRace = this._isValidRace()
		this.$emit('validity-changed', this.isValidRace)
	}

	setValid(target: Vue) {
		const field = target.$el.closest('.md-field') as HTMLElement
		field.classList.remove('md-invalid')
		const wrapper = target.$el.parentElement?.parentElement as HTMLElement
		if(wrapper) {
			wrapper.classList.remove('md-invalid')
		}
		delete this.validationErrors[(target as any).name]
		this.isValidRace = this._isValidRace()
		this.$emit('validity-changed', this.isValidRace)
	}
	get qualifiesAsAutomaticRace() {
		if(!this.race.scheduled) { return false }
		const scheduledDate = date.parse(this.race.scheduled, 'YYYY-M-D HH:mm:00', true)
		const fiveMinLaterThanNow = date.addMinutes(this.now, 5)
		return !!(this.usersAllowedToRunAutomatedRaces && date.subtract(scheduledDate, fiveMinLaterThanNow).toMinutes() >= 0)
	}

	@Debounce(100)
	durationChanged(value?: string): void {
		const duration = this.race_definition.duration
		const durationValid = (
			(duration >= this.durationMin) &&
			(duration <= this.durationMax)
		)
		if (durationValid) {
			this.durationInvalidClass = ''
			this.setValid(this.$refs.duration as Vue)
		} else {
			this.durationInvalidClass = 'md-invalid'
			this.setInvalid(this.$refs.duration as Vue)
		}
		this.race_definition.split_value = RACE_CONFIG_MAP[this.race_definition.duration_type].splitValueDefault(duration)
		this.splitValueChanged(this.race_definition.split_value.toString())
	}

	@Debounce(100)
	timeCapChanged(value?: string): void {
		const timeCap = this.race_definition.time_cap || 0
		const timeCapValid = (
			(timeCap >= MIN_TIME_CAP) &&
			(timeCap <= MAX_TIME_CAP)
		)
		if (timeCapValid) {
			this.timeCapInvalidClass = ''
			this.setValid(this.$refs.timeCap as Vue)
		} else {
			this.timeCapInvalidClass = 'md-invalid'
			this.setInvalid(this.$refs.timeCap as Vue)
		}
	}

	@Debounce(800)
	splitValueChanged(value: string): void {
		const duration = this.race_definition.duration
		const splitCountValid = (
			(this.split_total >= MIN_SPLITS) &&
			(this.split_total <= MAX_SPLITS)
		)
		const intValue = parseInt(value, 10)
		// tslint:disable-next-line:use-isnan
		const splitValueValid = intValue !== NaN && intValue <= duration &&
			intValue >= RACE_CONFIG_MAP[this.race_definition.duration_type].splitValueMin
		if (splitValueValid && splitCountValid) {
			this.splitsInvalidClass = ''
			this.setValid(this.$refs.splits as Vue)
		} else {
			this.setInvalid(this.$refs.splits as Vue)
			this.splitsInvalidClass = 'md-invalid'
		}
	}

	raceChange() {
		return this.race_definition
	}

	getTime(utc_date: string) {
		if (utc_date) {
			const d = date.parse(utc_date, 'YYYY-M-D HH:mm:ss', true)
			const zone = d ? d.toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2] : ''
			return date.format(d, 'h:mm A', false) + ' ' + zone
		}
		return ''
	}

	showTime(s: string) {
		if(s !== this.scheduled) {
			const d = new Date()
			const zone = d ? d.toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2] : ''
			return date.parse(s, 'YYYY-M-D HH:mm:ss', false).toLocaleTimeString() + ' ' + zone
		} else {
			return this.getTime(s)
		}
	}

	onNameLongInput(value) {
		const field = this.$refs.nameLong as Vue
		if (!field) { return }
		if (!value) {
			this.raceNameFieldClass = 'md-invalid'
			this.setInvalid(field)
		} else {
			this.raceNameFieldClass = ''
			this.setValid(field)
		}
	}
	setDefaults() {
		if(!this.race.id) {
			// new race...
			let later = date.addMinutes(new Date(), 9)

			for(let i = 1; later.getMinutes() % 10 !== 0; i++) {
				later = date.addMinutes(later, 1)
				console.log('loop:' + i, later.getMinutes(), later.getMinutes() % 10)
			}

			this.race.scheduled = date.format(later, 'YYYY-MM-DD HH:mm:ss', true)
			this.$nextTick(() => {this.scheduledDatePart = this.scheduledDate })

			if(this.isPublic) {
				this.race.automatic = true
				this.race.sweep = true
				return
			}
			this.race.automatic = false
			this.race.sweep = false
		}
	}

	created() {
		this.setDefaults()
		// every minute, validate the scheduled date and time
		this.clock = setInterval(() => {
			this.now = new Date()
			this.schedDateCtrlValid(this.scheduledDatePart)
			this.schedTimeCtrlValid(this.timeFormatted)
			this.projectedCtrlValidate()
			}, 3000
		)
	}
	mounted() {
		this.onNameLongInput(this.race_definition.name_long)
		this.schedDateCtrlValid(this.scheduledDatePart)
		this.schedTimeCtrlValid(this.timeFormatted)
	}
	beforeUpdate() {
		this.onNameLongInput(this.race_definition.name_long)
		this.schedDateCtrlValid(this.scheduledDatePart)
		this.schedTimeCtrlValid(this.timeFormatted)
	}
	@Watch('race.automatic')
	automaticChanged(newVal) {
		// this.race.sweep = this.race.automatic
	}
	@Watch('race.scheduled')
	scheduledChanged() {
		// needed to make reactive
	}
	@Watch('race.projected')
	projectedChanged(newVal) {
		// needed to make reactive
	}
	@Watch('race_definition', {deep: true, immediate: true})
	onRaceChanged() {
		this.onNameLongInput(this.race_definition.name_long)
	}

	isoDateString(d: Date): string {
		return d.getUTCFullYear() + '-'
			+ String((d.getUTCMonth() + 1)).padStart(2, '0')
			+ '-'
			+ String(d.getUTCDate()).padStart(2, '0')
	}
	schedDateValid(val) {
		const now = new Date()
		const today = date.format(now, 'YYYY-MM-DD')
		const test = !!(val >= today)
		return !!(val >= today)
	}

	schedDateCtrlValid(newValue) {
		const field = this.$refs.schedDate as Vue
		if(!field) { return false }
		if(!newValue) {
			this.schedDateClass = 'md-invalid'
			this.setInvalid(field)
			return false
		}
		const s = newValue.split('-')
		if(s[0] === '' || s[0] === 'NaN') { return }
		if (!this.schedDateValid(newValue)) {
			this.schedDateClass = 'md-invalid'
			this.setInvalid(field)
			return false
		}
		this.schedDateClass = ''
		this.setValid(field)
		return true
	}
	@Debounce(800)
	schedDatePartInput(newValue) {
		if(!this.schedDateCtrlValid(newValue)) { return }
		// handle issue where scheduled date date part is different from projected date date part.
		const d = this.race.scheduled ? date.parse(this.race.scheduled, 'YYYY-M-D HH:mm:ss', true) : new Date()
		const time = `${
				d.getHours().toString().padStart(2, '0')
			}:${
				d.getMinutes().toString().padStart(2, '0')
		}`
		const newDate = date.parse(`${newValue} ${time}`, 'YYYY-M-D HH:mm:ss', false)

		this.race.scheduled = formatUTCDate(newDate)

		this.ignoreNextSchedChanged = false
		setTimeout(() => this.setVal(this.race.scheduled, true), 250)
	}

	calcNewTime(newValue) {
		const schedDate = this.scheduledDatePart
			? date.parse(this.scheduledDatePart, 'YYYY-M-D', true)
			: this.race.scheduled ? date.parse(this.race.scheduled, 'YYYY-M-D HH:mm:ss', true) : new Date()
		const datePart = this.isoDateString(schedDate)
		return date.parse(`${datePart} ${newValue}`, 'YYYY-M-D HH:mm:ss', false)
	}

	schedTimeCtrlValid(newValue) {
		if(!newValue || newValue.includes('NaN')) { return false }
		const field = this.$refs.schedTimeInput as Vue
		if(!field) { return false }
		if(!newValue || newValue.includes('NaN')) {
			this.schedTimeClass = 'md-layout-nowrap md-invalid'
			this.setInvalid(field)
			this.hasScheduled = false
			this.race.automatic = false
			return false
		}

		const now = new Date()
		const fiveMinLaterThanNow = date.addMinutes(now, 5)
		const newDateTime = this.calcNewTime(newValue)
		if(this.race.automatic && date.subtract(newDateTime, fiveMinLaterThanNow).toMinutes() < 0
		|| !this.race.automatic && date.subtract(newDateTime, now).toMinutes() < 0) {
			this.schedTimeClass = 'md-layout-nowrap md-invalid'
			this.setInvalid(field)
			this.hasScheduled = false
			this.race.automatic = false
			return false
		}

		this.schedTimeClass = 'md-layout-nowrap'
		this.setValid(field)
		return true
	}

	@Debounce(800)
	schedTimeInput(newValue) {
		if(!this.schedTimeCtrlValid) { return }
		this.hasScheduled = true
  const newDateTime = this.calcNewTime(newValue)
		this.race.scheduled = formatUTCDate(newDateTime)
		if (!this.race.projected || this.race.projected && this.race.scheduled > this.race.projected) {
			setTimeout(() => this.setVal(this.race.scheduled, true), 250)
		}
	}

	get scheduledDate(): string {
		if(!this.race.scheduled) {
			return ''
		}
		const d = date.parse(this.race.scheduled, 'YYYY-M-D HH:mm:ss', true)
		const datePart = `${
		 		d.getFullYear()

		 		}-${
		 			(d.getMonth() + 1).toString().padStart(2, '0')
		 		}-${
		 			d.getDate().toString().padStart(2, '0')
		 	}`
		return datePart
	}

	get timeFormatted() {
		if (typeof this.race.scheduled === 'string') {
			this.ignoreNextSchedChanged = true
			const d = date.parse(this.race.scheduled, 'YYYY-M-D HH:mm:ss', true)
			const time = `${
					d.getHours().toString().padStart(2, '0')
				}:${
					d.getMinutes().toString().padStart(2, '0')
			}`
			return time
		}
	}
	projectedCtrlValidate() {
		const comp = this.$children.find(v => v.$refs && v.$refs.projTime) as Vue
		if(!comp) { return }
		const field = comp.$children[0] as Vue // input
		if(!field) { return }
		const now = new Date()
		const newDate = date.parse(this.race.projected, 'YYYY-M-D HH:mm:ss',  true)
		const fiveMinLaterThanNow = date.addMinutes(now, 5)
		if(this.race.automatic && date.subtract(newDate, fiveMinLaterThanNow).toMinutes() < 0) {
			setTimeout(() => this.setInvalid(field), 250)
			this.race.automatic = false
			this.projectedError = ''
		}
		if(date.subtract(newDate, now).toMinutes() < 0) {
			setTimeout(() => this.setInvalid(field), 250)
			this.projectedError = this.$t('errors.projectedTimeRange').toString()
			return
		}
		this.setValid(field)
	}
	// sets the projected time
	setVal(projected: string | boolean, utc: boolean) {
		projected = typeof(projected) === 'string' ? projected : ''

		const d = date.parse(this.race.scheduled, 'YYYY-M-D HH:mm:ss', true)
		const newDate = date.parse(projected, 'YYYY-M-D HH:mm:ss',  utc || false)
		this.race.projected = formatUTCDate(newDate)
		if(newDate < d) {
			this.race.projected = this.race.scheduled
		}
		this.projectedCtrlValidate()
	}
}
