import { Vue, Component, Watch } from 'vue-property-decorator'
import {
	RemoteErgRaceState,
	remoteErgRaceStates,
	RemoteErgRaceStatus,
	RaceDefinition,
	RaceState,
	RemoteErgStatusMsg,
	AthleteStatus, ErgStatus, defaultAthleteStatus
} from 'shared/types/erg'

import { makeComputedBinding } from 'shared/util'
import { RemoteControl } from './RemoteControl'
import { bus } from './Bus'
import { ErgState } from 'shared/state/Erg'
import { GlobalConfig } from 'display/config'

@Component
class RemoteErgModule extends Vue {
	@GlobalConfig() devMode!: boolean
	@GlobalConfig() suppressTachWarnings!: boolean
	@ErgState() status!: RaceState
	state: RemoteErgRaceState = 'ready'
	raceFiles: any = []
	resultsFiles: any = null
	ergMap: any = null
	ergStatus: any = null
	statusSummary: RemoteErgStatusMsg[] = []
	simulation: boolean = false
	race: RaceDefinition = {
		name_long: '',
		name_short: '',
		event_name: '',
		duration: 2000,
		duration_type: 'meters',
		race_type: 'individual',
		time_cap: 0,
		split_value: 0,
		boats: [],
		c2_race_id: ''
	}
	errorMsg: string = ''
	messages: string = ''
	public athleteStatus: AthleteStatus[]  = []

	_athlete_status_sent_timeout
	preventPolling = false
	connections
	lost_connections: AthleteStatus[] = []
	transitioning = false

	public get ergsNumbered(): boolean {
		return !(this.athleteStatus.find(c => c.erg_status?.erg_number === '255' || c.erg_status?.erg_number === '0'))
	}

	public addMessage(message: string) {
		// log any messages and bind to the message value
		this.messages += message + '\n'
	}
	public clearMessage() {
		this.messages = ''
		this.errorMsg = ''
	}

	public get anyNotReady() {
		return !!this.athleteStatus?.find(as => as.erg_status?.status === 'Erg not ready')
	}

	public get anybodyOk() {
		return !!this.athleteStatus?.find(as => as.erg_status?.status === 'Ok' && as.erg_status?.erg_number !== '0')
	}

	public get everybodyOk() {
		const Ok = this.athleteStatus?.filter(as => as.erg_status?.status === 'Ok'
			&& as.erg_status?.erg_number !== '0').length
		return Ok === this.athleteStatus.length
	}

	public get problemErgs() {
		return this.athleteStatus?.filter(as => as.erg_status?.status !== 'Ok' ||  as.erg_status?.erg_number === '0')
		.concat(this.lost_connections)
	}

	public ergNumber(lane)  {
		return this.athleteStatus.find(a => a.erg_status?.erg_number === lane)
	}

	public setStatus(s: RemoteErgRaceStatus) {
		if (this.devMode) { console.log('-->state received: ', s.state) }
		if (this.devMode) { console.log('Ergrace connections: ', this.athleteStatus) }

		if (!Object.values(remoteErgRaceStates).includes(s.state)) {
			throw new Error('Invalid state: ' + s.state)
		}
		this.state = s.state

		switch (s.state) {
			case 'error':
				this.errorMsg = JSON.stringify(s.detail && s.detail.message ? s.detail.message : s.detail, null, 4)
				this.$emit('error', s.detail)
				bus.$emit('error', s.detail)
				break
			case 'sent_connections':
				const c = s.detail.toString()
				.replace('Remote Config/Display Clients', '{"displayClients"')
				.replace('Mobile Clients', ', "mobileClients"') + '}'
				try {
					this.connections = JSON.parse(c)
				} catch {
					this.connections = { mobileClients: 0, displayClients: 0 }
				}
				break
			case 'sent_races':
				this.raceFiles = s.detail.races
				break
			case 'loaded_erg_map':
				this.ergMap = s.detail
				break
			case 'sent_results':
				this.resultsFiles = s.detail
				break
			case 'status_summary_sent':
				this.statusSummary = s.detail.status
				break
			case 'loaded_race':
				if (s.detail && s.detail.race_definition) {
					this.race = s.detail.race_definition
				}
				break
			case 'erg_status_sent':
				this.ergStatus = s.detail.erg_status
				break
			case 'cleared_erg_map':
				this.ergStatus = []
				break
			case 'prepare-syncRace':
				break
			case 'saved_loaded':
				if (s.detail && s.detail.race_definition) {
					this.race = s.detail.race_definition
				}
				bus.$emit('app_event', {error: false, detail: {checkbox: 'Race File Received'}})
				bus.$emit('app_event', {error: false, detail: {checkbox: 'Loading'}})
				this.$emit('athlete_status_sent', this.athleteStatus)
				break
			case 'error:save_load':
				if (this.devMode) { console.log('received error:save_load') }
				break
			case 'discover_done':
				this.$emit('discover_done', s.detail)  // 'Should be "ok"
				if(s.detail !== 'ok') { this.$emit('error', s.detail) }
				break
			case 'holding_pen_sent':
				this.$emit('holding_pen_sent')
				break
			case 'simulation_on':
					this.simulation = true
					this.$emit('simulation_on')
					break
			case 'simulation_off':
					this.simulation = false
					this.$emit('simulation_off')
				 break
			case 'sent_simulation':
				this.simulation = !!+s.detail
				this.$emit(this.simulation ? 'simulation_on' : 'simulation_off')
				break
			case 'athlete_status_sent':
				let _athleteStatus: AthleteStatus[]  = []
				_athleteStatus = s.detail.athlete_status
				_athleteStatus.forEach(as => {
					if(!as.erg_status) {
						// if(this.devMode) { console.log('no erg status ' + as.logbook_id) }
						as.erg_status = defaultAthleteStatus.erg_status
					} else {
						if (this.suppressTachWarnings && ['Low Battery', 'Tach wire issue'].includes(as.erg_status!.status)) {
							as.erg_status!.status = 'Ok'
						}
						const avg = parseInt(as.erg_status?.avg_rsp, 10)
						try {
							if (avg) {
								const lookup = this.athleteStatus.find(AS => AS.logbook_id === as.logbook_id)
								if(lookup && lookup.erg_status?.avg_rsp_log) {
									as.erg_status.avg_rsp_log = lookup.erg_status?.avg_rsp_log
									as.erg_status.avg_rsp_log?.push(avg)
								} else {
									as.erg_status.avg_rsp_log = [avg]
								}
								if(as.erg_status.avg_rsp_log!.length > 150) { as.erg_status.avg_rsp_log!.shift() }
								as.erg_status.avg_rsp_long = Math.floor(as.erg_status.avg_rsp_log!
									.reduce((a, b) => (a + b)) / as.erg_status.avg_rsp_log!.length)
							}

						} catch(err) {
							if (this.devMode) { console.log('in catch', err, as) }
						}
					}
					// remove from lost_connections
					const foundLostIndex = this.lost_connections.findIndex(lost => lost.logbook_id === as.logbook_id)
					if(foundLostIndex !== -1) { this.lost_connections.splice(foundLostIndex, 1) }
				})
				// add any lost connections
				const lost = this.athleteStatus.filter(AS => !_athleteStatus.map(s => s.logbook_id).includes(AS.logbook_id))
				lost.forEach(l => {
					l.erg_status!.whenDisconnected = new Date()
					this.lost_connections.push(l)
				})

				this.athleteStatus = _athleteStatus
				this.$emit('athlete_status_sent', this.athleteStatus)

			 break
		}

	}
	transition() {
		this.transitioning = true
	}
	transitionOff() {
		this.transitioning = false
	}
	created() {

		this._athlete_status_sent_timeout = setInterval(
			() => {
				if (!this.preventPolling) {
					if(this.devMode) { console.log('%cER sending remoteErgAction', 'font-size:large; color:blue; font-weight:bold;') }
					RemoteControl.remoteErgAction('athlete_status')
				}
			},
		2000
		)

		bus.$on('close entries', this.transition)
		bus.$on('loading race', this.transition)
		bus.$on('race_dialog', this.transitionOff)
		bus.$on('warmup', this.transition)
		bus.$on('prepare', this.transition)
		bus.$on('start', this.transitionOff)
		bus.$on('race running', this.transitionOff)
		bus.$on('holding_pen', this.transitionOff)
	}
	beforeDestroy() {
		clearInterval(this._athlete_status_sent_timeout)
		bus.$off('close entries', this.transition)
		bus.$off('loading race', this.transition)
		bus.$off('race_dialog', this.transitionOff)
		bus.$off('warmup', this.transition)
		bus.$off('prepare', this.transition)
		bus.$off('start', this.transitionOff)
		bus.$off('race running', this.transitionOff)
		bus.$off('holding_pen', this.transitionOff)
	}

	@Watch('status', {immediate: true, deep: true})
	statusChanged(newVal: RaceState) {
		this.transitioning = false
		this.preventPolling = ([
			'sit ready',
			'attention',
			'race running',
			'race running - sound horn']
			.includes(newVal))
		}
}

export const RemoteErg = new RemoteErgModule()
export const RemoteErgState = makeComputedBinding(RemoteErg)
