import { createDecorator } from 'vue-class-component'

export const makeComputedBinding = (module: any) => {
	return (selector: string | ((module: any) => any) | undefined = undefined) => {

		return createDecorator((component, key) => {

			if(!component.computed) {
				component.computed = {}
			}

			let selectFn
			if(selector === undefined) {
				selectFn = () => module[key]
			} else if(typeof selector === 'string') {
				selectFn = () => module[selector]
			} else {
				selectFn = () => selector(module)
			}

			component.computed[key] = () => {
				return selectFn()
			}
		})
	}
}

export const makeInjectedBinding = (name: string) => {
	return (selector: string | ((module: any) => any) | undefined = undefined) => {

		return createDecorator((component, key) => {

			if(typeof component.inject === 'undefined') {
				component.inject = {}
			}
			if(!Array.isArray(component.inject) && !component.inject![name]) {
				component.inject![name] = name
			}

			let selectFn
			if(selector === undefined) {
				selectFn = function(this: any) {
					return this[name][key]
				}
			} else if(typeof selector === 'string') {
				selectFn = function(this: any) {
					return this[name][selector]
				}
			} else {
				selectFn = function(this: any) {
					return selector(this[name])
				}
			}

			if(!component.computed) {
				component.computed = {}
			}

			component.computed[key] = selectFn

		})

	}
}
