
import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
import NewCompetitionForm from './NewCompetitionForm.vue'
import { Competitions, CompetitionsState } from 'shared/state/Competitions'
import { CompetitionType } from 'shared/types/competition'
import { LogBookUser } from 'shared/state/LogBookUser'
import { formatDate } from 'shared/util'

import { RemoteControl } from 'shared/state/RemoteControl'
import { RemoteErg, RemoteErgState, Erg, ErgState } from 'shared/state'

import { RaceDefinition, RaceDefinitionWrapper } from 'shared/types/erg/'
import {
	RemoteErgRaceAction,
	remoteErgRaceActions,
	RemoteErgRaceState,
	remoteErgRaceStates,
	RemoteErgRaceStatus
} from 'shared/types/erg/index'
import { RaceState } from 'shared/types/erg'
import { DurationType, RaceType } from 'shared/types/erg/index'
import RaceForm from './RaceForm.vue'
import FilePicker from './FilePicker.vue'
import { formatTime } from 'shared/util'
import TabRaceListRaces from './TabRaceListRaces.vue'
import TabRaceListRaceDetail from './TabRaceListRaceDetail.vue'
import { RaceListItem } from 'shared/types/racelistitem'
import { cloneDeep } from 'lodash'
import { bus } from 'shared/state/Bus'
import { RaceFormType, RacOnCompetition } from 'shared/types/competition'
import { LogBookUserDefinition } from 'shared/types/logBook'
import { GlobalConfig } from 'display/config'

@Component({ components: { TabRaceListRaces, TabRaceListRaceDetail } })
export default class TabRaceList extends Vue {
	static NewRaceTempId: number = 0
	@GlobalConfig() enableAnimation!: boolean
	@Prop() competition!: CompetitionType
	@Prop() loading!: boolean
	@Prop() status!: string
	@GlobalConfig() devMode!: boolean

	editRace: boolean = false
	showEdit: boolean = false

	race: any = {}

	Race() {
		return {
			automatic: false,
			sweep: true,
			scheduled: false,
			projected: false,
			rac: {
				data: {
					race_definition: {
						event_name: this.competition.name,
						// Uncomment if a default name is desired
						// name_long: 'My Race',
						name_long: '',
						name_short: '',
						duration: 2000,
						duration_type: 'meters',
						race_type: 'individual',
						split_value: 500,
						boats: []
					}
				}
			}
		}
	}
	cancelEdit(evt) {
		this.showEdit = false
	}

	editRaceHandler(race: any | undefined) {
		if (!race) {
			this.race = this.Race()

		} else {
			this.race = JSON.parse(JSON.stringify(race))
		}
		this.showEdit = true
	}

	importRaceHandler(race: RaceDefinitionWrapper) {
		this.race = {rac: {data: race}}

		// TODO if race definition is not valid, show edit view
		// this.showEdit = true

		this.race.event_name = Competitions.currentCompetition.name
		this.race.race_definition = (this.race.rac) ? this.race.rac.data.race_definition : this.race.race_definition

		// Prune empty boats. Boats must be named or have an ID or contain at
		// least one participant with a name or ID.
		if (this.race.race_definition) {
			this.race.race_definition.boats = this.race.race_definition.boats.filter(boat => (
				boat.name || (
					boat.logbook_id || boat.participants?.filter(p => p.name || p.logbook_id).length
				)
			))
		}

		const copyRace = JSON.parse(JSON.stringify(this.race))
		if(this.devMode) { console.log('copyRAce', copyRace) }
		const tempRace = copyRace
		const raceTemp = `{
		"id":0,
		"competition":${Competitions.currentCompetition.code},
		"order":${Competitions.currentCompetition.races.data.filter((d) => d.rac).length + 1},
		"boats":${this.race.rac.data.race_definition.boats.length},
		"name":"${this.race.rac.data.race_definition.name_short}",
		"projected":"${this.race.projected || ''}",
		"scheduled":"${this.race.scheduled || ''}",
		"automatic": "${!!this.race.automatic || false}",
		"sweep": "${!!this.race.sweep || true}",

		"rac":{
				"data": ${JSON.stringify(this.race.rac.data)}
			}
		}`

		bus.$emit('addRace', JSON.parse(raceTemp))
		bus.$emit('cancel')
	}

	created() {
		this.showEdit = this.editRace
		bus.$on('edit', this.editRaceHandler)
		bus.$on('cancel', this.cancelEdit)
	}
	beforeDestroy() {
		bus.$off('edit', this.editRaceHandler)
		bus.$off('cancel', this.cancelEdit)
	}
}
