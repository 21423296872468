
import { Vue, Component, Prop } from 'vue-property-decorator'
import 'vue-awesome/icons/volume-up'

@Component
export default class TestAudioControl extends Vue {

	@Prop() label?: string

}
