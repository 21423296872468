import { isEqual } from 'lodash'

type Modifier = 'Ctrl' | 'Alt' | 'Shift'
type Handler = (key: string, e: KeyboardEvent) => void
type Supported = 'keyup' | 'keydown'
interface Binding {
	match: RegExp
	modifiers: Modifier[] | null
	fn: Handler
	handle: number
}

export class Keybindings {

	private keydown: Binding[] = []
	private keyup: Binding[] = []
	private handle = 0
	private attached: Supported[] = []

	onKeyup(match: string | RegExp, modifiers: Modifier[] | null, fn: Handler) {
		return this.on('keyup', match, modifiers, fn)
	}

	onKeydown(match: string | RegExp, modifiers: Modifier[] | null, fn: Handler) {
		return this.on('keydown', match, modifiers, fn)
	}

	off(handle: number) {
		for(const type of ['keyup', 'keydown']) {
			const bindingIndex = this[type].findIndex((b: Binding) => b.handle === handle)
			if(bindingIndex !== -1) {
				this[type].splice(bindingIndex, 1)
				return
			}
		}
	}

	private handler(type: Supported, e: KeyboardEvent) {

		if((e.target as HTMLElement).tagName.toUpperCase() === 'INPUT') { return }

		if(e.code === 'F10') {
			e.preventDefault()
			return
		}
		if(/F[0-9]/.test(e.code)) { return }

		const modifiers: Modifier[] = []
		if(e.ctrlKey) { modifiers.push('Ctrl') }
		if(e.altKey) { modifiers.push('Alt') }
		if(e.shiftKey) { modifiers.push('Shift') }
		modifiers.sort()

		const key = this.normalizeKey(e)

		this[type].forEach(b => {
			const modifierMatch = !b.modifiers || isEqual(b.modifiers.slice().sort(), modifiers)
			if(modifierMatch && b.match.test(key)) {
				b.fn(key, e)
			}
		})
	}

	private on(type: Supported, match: string | RegExp, modifiers: Modifier[] | null, fn: Handler): number {
		if(this.attached.indexOf(type) === -1) {
			document.addEventListener(type, e => this.handler(type, e))
			this.attached.push(type)
		}
		this[type].push({
			match: typeof match === 'string' ? new RegExp(`^${match}$`) : match,
			modifiers,
			fn,
			handle: ++this.handle
		})
		return this.handle
	}

	private normalizeKey(e: KeyboardEvent): string {
		if(e.key === 'Subtract' || e.keyCode === 189) {
			return '-'
		}
		if(e.key === 'Add' || e.keyCode === 187) {
			return '+'
		}

		return e.key
	}

}

export const KeybindingsService = {
	install: (Vue, options) => {
		Vue.prototype.$keybindings = new Keybindings()
	}
}
