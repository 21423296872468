import { Vue, Component } from 'vue-property-decorator'
import { makeComputedBinding } from 'shared/util'
import { bus } from 'shared/state/Bus'
import { GlobalConfig } from 'display/config'
import { ConnectionStatus } from 'shared/types'


@Component
export class ServerModule extends Vue {
	@GlobalConfig() devMode!: boolean
	wsUrl = 'ws://admin.ergrace.com/ws:443'
	hostname = 'ergrace.com'
	state: ConnectionStatus = 'unconnected'
	ws: WebSocket | null = null
	packetListeners: Array<((channel: string, packet: any) => void) | undefined> = []

	setWsUrl(wsUrl) {
		if (!wsUrl || wsUrl === 'ws://localhost:443') {
			return
		}
		try {
			const url = new URL(wsUrl)
			this.wsUrl = wsUrl
			this.hostname = url.hostname
		} catch (e) {
			if (this.devMode) { console.error('Invalid websocket url received: ' + wsUrl) }
		}
	}

	connect() {
		return new Promise((resolve, reject) => {
			this.state = 'unconnected'

			try {
				this.ws = new WebSocket(this.wsUrl)
			} catch (err) {
				return reject(err)
			}

			this.ws.addEventListener('open', () => {
				this.state = 'connected'
				resolve(null)
			})

			this.ws.addEventListener('close', () => {
				this.state = 'disconnected'
				if (this.devMode) {
					console.log(`lost connection to ${this.ws} at ${new Date()}`)
				}
			})

			this.ws.addEventListener('message', (msg) => {
				try {
					const data = JSON.parse(msg.data)

					Object.keys(data).forEach((channel) => {
						this.packetListeners
							.filter((listener) => listener)
							.forEach((listener) => listener!(channel, data[channel]))
					})
				} catch(err) {
					if (this.devMode) { console.error('invalid JSON from ergrace: ', msg) }
					bus.$emit('app_event', {error: true, message: 'Failed to parse JSON from Ergrace' + msg.data})
				}

			})
		})
	}

	disconnect() {
		this.ws?.close()
	}

	send(message: any) {
		// document.getElementById('ergIn')!.innerText! += JSON.stringify(message)
		if (!this.ws || this.ws.readyState !== this.ws.OPEN) {
			return
		}
		this.ws.send(JSON.stringify(message))
	}

	addListener(listener: (type: string, packet: any) => void): number {
		this.packetListeners.push(listener)
		return this.packetListeners.length - 1
	}

	removeListener(id: number) {
		this.packetListeners[id] = undefined
	}
}

export const Server = new ServerModule()
export const ServerState = makeComputedBinding(Server)
