
import { Component, Vue, Watch } from 'vue-property-decorator'
import Header from './components/Header.vue'
import LoginPageContent from './components/LoginPageContent.vue'
import CompetitionPageLanding from './components/CompetitionPageLanding.vue'
import Footer from './components/Footer.vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { ServerState, ErgState, Erg } from 'shared/state'
import { Server } from 'shared/state'
import ServerRace from 'shared/components/ServerRace.vue'
import ServerPenConn from 'shared/components/ServerPenConn.vue'
import 'font-awesome/css/font-awesome.min.css'
import { LogBookUser, LogBookUserState } from 'shared/state/LogBookUser'
import Announcement from '_display/_pen/components/Announcement.vue'

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false

@Component({
	components: {
		Header,
		Announcement,
		CompetitionPageLanding,
		LoginPageContent,
		Footer
	}
})
export default class LoginPage extends Vue {
	@LogBookUserState() loggedIn!: boolean
	@LogBookUserState('logBookUserInfo.code') code!: boolean
}
