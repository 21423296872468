
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

import Login from './Login.vue'
import Config from 'shared/components/config/_Config.vue'
import { config } from 'display/config'
import { displayType } from 'shared/util'
import { RemoteControl } from 'shared/state/RemoteControl'
import { RemoteControlPen } from 'shared/state/RemoteControlPen'

import { ServerState, RemoteErgState, RemoteErg, RemoteErgStatePen } from 'shared/state'
import { ConnectionStatus } from 'shared/types/ConnectionStatus'
import { bus } from 'shared/state/Bus'
import { Erg, ErgState } from 'shared/state'
import { RaceState } from 'shared/types/erg'

@Component({ components: { Config, Login }})
export default class ConfigModal extends Vue {

	@RemoteErgState('simulation') ERsimMode!: boolean
	@RemoteErgStatePen('simulation') HPsimMode!: boolean
	@ErgState() status!: RaceState
	@ServerState() state!: ConnectionStatus
	config = config
	loggedIn = false
	simMode = false
	flyWheels = false

	get ergConnected() {
		return this.state === 'connected'
	}

	get page() {
		return displayType(this.$route.name!)
	}

	toggle() {
		const modal = this.$refs.modal as any
		if(modal.visible) {
			modal.hide()
		} else {
			modal.show()
		}
	}

	toggleSim() {
		RemoteControl.remoteErgAction('simulation_' + (this.ERsimMode ? 'off' : 'on'))
		RemoteControlPen.remoteErgAction('simulation_' + (this.ERsimMode ? 'off' : 'on'))
	}

	resendSim() {
		RemoteControl.remoteErgAction('simulation_' + (this.ERsimMode ? 'on' : 'off'))
		RemoteControlPen.remoteErgAction('simulation_' + (this.ERsimMode ? 'on' : 'off'))
	}

	created() {
		this.$root.$on('toggle-config', this.toggle)
		this.loggedIn = !!localStorage.getItem('ERGLoggedIn')
		bus.$on('resend_sim', this.resendSim)
	}

	beforeDestroy() {
		bus.$off('resend_sim', this.resendSim)
	}

	destroyed() {
		this.$root.$off('toggle-config', this.toggle)
	}

	cmd(command: string) {
		if(command === 'exit') {
			this.$bvModal
			.msgBoxConfirm(
				`Are you sure?`,
				{
					title: 'Confirm Restart',
					size: 'sm',
					buttonSize: 'sm',
					okVariant: 'success',
					cancelVariant: 'danger',
					okTitle: 'Restart',
					cancelTitle: 'Cancel',
					bodyClass: 'bdy',
					footerClass: 'p-2',
					hideHeaderClose: false,
					centered: true
				}
			)
			.then(async (confirm) => {
				if (confirm) {
					RemoteControl.remoteErgAction('holding_pen')
					RemoteControl.remoteErgAction('normal_mode')
					RemoteControl.remoteErgAction('exit')
					bus.$emit('exit')
				}
			})
		} else {
			RemoteControl.remoteErgAction(
			command === 'sim' ? 'simulation_on' : 'holding_pen')
			if(command === 'pen') {
				bus.$emit('holding_pen')
			}
		}

	}

	get simAvailable() {
		return ['prepare', 'ready', 'attention', 'stop rowing'].includes(this.status)
	}

	simFlywheels() {
		Erg.simMovingFlywheels(this.flyWheels ? [1, 2] : [])
	}
	@Watch('ERsimMode', {immediate: true})
	simChanged(newVal) {
		this.simMode = newVal && this.HPsimMode
		this.flyWheels = false
	}
	@Watch('HPsimMode', {immediate: true})
	HPsimChanged(newVal) {
		this.simMode = newVal && this.ERsimMode
	}
	@Watch('status', {immediate: true})
	statusChanged(newVal) {
		if(!['prepare', 'ready', 'attention', 'stop rowing'].includes(newVal)) {
			this.flyWheels = false
		}
	}
}
