import { AthleteStatus } from 'shared/types/erg/AthleteStatus'

export const eligable = (a: AthleteStatus): boolean => {
	if(!a.connected) { return false }
	if(a.erg_status!.status === 'Offline') { return false }
	const errors =
   [
	'Firmware MUST be updated',
	'Offline',
	'Low Battery',
	'Firmware should be updated',
	'Average response time too high',
	'Max response time too high',
	'Erg Not Ready',
	'Tach wire issue',
   ]
	for(const e in errors) {
		if( a.erg_status!.status.includes(errors[e])) { return false }
  }
	return true
}
