
import { Vue, Component, Watch } from 'vue-property-decorator'

import { ServerState, ErgState, Erg } from 'shared/state'
import ViewportSize from 'display/ViewportSize'
// import { config, GlobalConfig, GlobalTheme } from 'display/config'
import { config, GlobalConfig } from 'display/config'
import { ViewportSizeModule } from 'display/ViewportSize'
import { displayType } from 'shared/util'
import { version } from '../package.json'
import globalCssVars from './cssVars'

import ServerRace from 'shared/components/ServerRace.vue'
import ServerPenConn from 'shared/components/ServerPenConn.vue'
import ConfigModal from 'display/components/ConfigModal.vue'
import { Server } from 'shared/state'
import { RemoteIdentify, ClientType } from 'shared/types/config-server'
import { LogBookUser, LogBookUserState } from 'shared/state/LogBookUser'
import { RemoteErg, RemoteErgState, RemoteErgPen, RemoteErgStatePen } from 'shared/state'

import { RemoteControl, RemoteState, RemoteScreen } from 'shared/state/RemoteControl'
import { RemoteErgRaceState } from 'shared/types/erg/index'
import { getCookie } from 'shared/util/cookie'
import PermissionModal from './_pen/components/PermissionModal.vue'
import CobrowseIO from 'cobrowse-sdk-js'
import { bus } from 'shared/state/Bus'

CobrowseIO.license = 'snphY4ahJJoc9w'
CobrowseIO.start()

@Component({ components: { ServerRace, ServerPenConn, ConfigModal, PermissionModal } })
export default class App extends Vue {
	@RemoteState() screens!: Record<string, RemoteScreen>
	@RemoteState() screenOptions!: string[]
	@LogBookUserState()

	@ServerState('state') serverState
	@ErgState() definition
	@GlobalConfig() lang
	@GlobalConfig() devMode
	@GlobalConfig() route
	@RemoteErgStatePen('state') penState!: RemoteErgRaceState
	@RemoteErgState() state!: RemoteErgRaceState
	@RemoteErgState() last

	config = config.vals
	viewport = ViewportSize
	version = version

	get cssVars() {
		return globalCssVars()
	}
	setLanguage(lang: string) {
		this.$i18n.locale = lang
	}

	created() {
		this.$keybindings.onKeydown(' ', ['Ctrl'], () => this.$root.$emit('toggle-config'))
		if (getCookie('logbooktoken')) {
			LogBookUser.getUser()
		}
		bus.$on('setLang', this.setLanguage)
	}

	@Watch('route')
	changeRoute() {
		if (this.$route.name !== this.route) {
			this.$router.push({ name: this.route })
		}
	}
}
