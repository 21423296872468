
import { Vue, Component } from 'vue-property-decorator'
import 'vue-awesome/icons/exclamation-triangle'

@Component
export default class Reconnect extends Vue {
	count = 2
	interval

	countdown() {
		if (this.count === 1) {
			clearInterval(this.interval)
			this.$emit('reconnect')
		} else {
			this.count--
		}
	}

	mounted() {
		this.interval = setInterval(this.countdown, 200)
	}
}
