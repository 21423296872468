
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Competitions, CompetitionsState } from 'shared/state/Competitions'
import { createDecorator } from 'vue-class-component'
import { bus } from 'shared/state/Bus'

@Component({ components: {} })
export default class Announcement extends Vue {
@CompetitionsState() currentRaceServer!: string

comp = Competitions
settings = {}
stage = window.location.hostname.toLowerCase().includes('dev') ? 'DEV' : 'PROD'
announcement = ''
lastUpdated = ''
handleBusSettings(s) {
	 if(this.stage && s.WEB_SERVERS && s.WEB_SERVERS[this.stage]
		&& s.WEB_SERVERS[this.stage].hasOwnProperty('ANNOUNCEMENT') && s.LAST_UPDATED) {
			// only accept the most current change
			if(this.lastUpdated < s.LAST_UPDATED) {
				this.announcement = s.WEB_SERVERS[this.stage].ANNOUNCEMENT
				this.lastUpdated = s.LAST_UPDATED
			}
		}
	}

created() {
  bus.$on('settings', this.handleBusSettings)
}
beforeDestroy() {
 bus.$off('settings', this.handleBusSettings)
}
}
