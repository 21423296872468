
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { AthleteStatus } from 'shared/types/erg/AthleteStatus'
import { config, GlobalConfig } from 'display/config'
import { RaceState } from 'shared/types/erg'
import { RemoteErgState, ErgState } from 'shared/state'
import { bus } from 'shared/state/Bus'
import 'vue-awesome/icons/battery-quarter'
import 'vue-awesome/icons/wrench'
import 'vue-awesome/icons/sync-alt'

/*
 * Avg response below 250ms - 4 bars
 * Avg response between 250-700ms - 3 bars
 * Avg response between 700-900ms - 2 bars
 * Avg response above 900ms - 1 bar
 */

@Component({ components: {} })
export default class Signal extends Vue {
	@Prop() athleteStatus!: AthleteStatus
	@Prop() scale!: number
	@GlobalConfig() devMode!: boolean
	@RemoteErgState() transitioning
	@ErgState() status!: RaceState

	imageWidth = 890
	imageHeight = 550

	responseTimes: number[] = []
	bins = ['', 'error_bin', 'warning_bin', 'ok_bin', 'good_bin']
	imageText = ''
	done = true
	afterCreatedDelay = false

	get showHistogram() {
		return !!this.athleteStatus?.com_stats
	}

	get connectedImage() {
		if (this.athleteStatus && this.athleteStatus.erg_status?.type && (this.transitioning || !this.done)) {
			this.imageText = this.athleteStatus.erg_status?.type
			return `/assets/img/icons/${this.athleteStatus.erg_status?.type}.svg`
		}
		if (this.transitioning && !this.athleteStatus) {
			this.imageText = 'connected'
			return '/assets/img/icons/noun_connected.svg'
		}

		if (this.transitioning && this.athleteStatus && this.athleteStatus.erg_status?.type) {
			this.imageText = this.athleteStatus.erg_status?.type
			return `/assets/img/icons/${this.athleteStatus.erg_status?.type}.svg`
		}

		if (
			this.athleteStatus &&
			this.athleteStatus.erg_status?.status === 'Offline'
		) {
			this.imageText = 'PM not connected'
			return '/assets/img/icons/PM5-x.png'
		}

		if (this.athleteStatus && this.athleteStatus.erg_status?.type) {
			this.imageText = this.athleteStatus.erg_status?.type
			return `/assets/img/icons/${this.athleteStatus.erg_status?.type}.svg`
		}

		if (this.athleteStatus && (this.athleteStatus.connected) && !this.transitioning && !this.done) {
			this.imageText = 'connected'
			return '/assets/img/icons/noun_connected.svg'
		}
		this.imageText = 'disconnected'
		return '/assets/img/icons/noun_disconnected.svg'
	}

	get lowBattery() {
		return !!(this.athleteStatus?.erg_status?.status === 'Low Battery')
	}

	get notOk() {
		return this.athleteStatus?.erg_status?.status !== 'Ok' && this.athleteStatus?.erg_status?.status !== 'Offline'
	}

	get ergStyle() {
		return {
			height: `${14 * (this.scale || 2)}px`,
			width: `${14 * (this.scale || 2)}px`,
			'border-radius': '2px',
			background: `${
				this.scale && this.scale !== 1 ? 'rgb(196, 196, 196)' : 'transparent'
			}`,
			'margin-right': '8px',
			'vertical-align': 'middle',
		}
	}

	get svgStyle() {
		return {
			width: `${24 * (this.scale || 1)}px`,
			height: `${14 * (this.scale || 1)}px`,
		}
	}
	get imgStyle() {
		return {
			height: `${14 * (this.scale || 1)}px`,
			'margin-right': '5px',
			'border-radius': '2px',
		}
	}

	barOffsets(i) {
		return `translate(${
			Math.floor(((i - 1) * (this.imageWidth * 1.05)) / 4) + 'px'
		}, ${this.imageHeight - this.height(i)}px)`
	}

	barBgStyle(i) {
		return {
			fill: '#C4C4C4',
			transform: `translateX(${
				Math.floor(((i - 1) * this.imageWidth * 1.05) / 4) + 'px'
			}`,
		}
	}

	signalStyle(i) {
		if (!this.athleteStatus) { return {} }
		return {
			fill: i === 1 ? 'red' : i === 2 ? '#FE9202' : '#02B84B',
			transform: this.barOffsets(i),
		}
	}

	signalStrength(val) {
		if (val === 0) { return 0 }
		if (val < 250) { return 4 }
		if (val < 700) { return 3 }
		if (val < 900) { return 2 }
		return 1
	}

	height(i) {
		if (!this.athleteStatus) { return 0 }
		return (
			Math.floor(
				(this.imageHeight / 15) * this.athleteStatus.com_stats[this.bins[i]],
			) || 0
		)
	}

	get avgResponseTime() {
		return this.athleteStatus && this.athleteStatus.erg_status
			? this.athleteStatus.erg_status.avg_rsp
			: 0
	}

	get imageGrade() {
		if (!this.athleteStatus) { return 'no' }
		switch (this.athleteStatus.com_stats.quality) {
			case 0:
				return 'weak'
			case 1:
				return 'fair'
			case 2:
				return 'good'
			case 3:
				return 'strong'
			default:
				return 'no'
		}
	}
	backToPen() {
		this.done = false
		setTimeout(() => {this.done = true}, 8000)
  	}
	created() {
		bus.$on('holding_pen', this.backToPen)
		setTimeout(() => {this.afterCreatedDelay = true}, 2000)
	}
  	beforeDestroy() {
		bus.$off('holding_pen', this.backToPen)
  	}

	@Watch('status')
	statusChanged(newVal, oldValue) {
		this.done = false
		setTimeout(() => {this.done = true}, 8000)
	}

	@Watch('athleteStatus.connected')
	connectedChanged(newVal, oldValue) {
		this.done = false
		setTimeout(() => {this.done = true}, 8000)
	}

}
