import Vue from 'vue'
import Router from 'vue-router'
import Participant from './components/Participant.vue'

import { displayType } from 'shared/util'
import { config } from './config'

Vue.use(Router)

const router = new Router({
	mode: 'history',
	routes: [
		{
			path: '/',
			name: 'home',
			component: () => import('./_pen/_LandingPage.vue'),
			meta: {
				title: 'ErgRace Virtual Racing'
			}
		},
		{
			path: '/competition/:code',
			name: 'competition',
			meta: {
				title: 'ErgRace Competitions'
			},
			component: () => import('./_pen/_CompetitionPage.vue'),
			props: {
				default: true,
				CompetitionPage: route => ({ code: route.query.code })
			}
		},
		// {
		// 	path: '/editcompetition/:code',
		// 	name: 'competitionedit',
		// 	meta: {
		// 		title: 'ErgRace Competition'
		// 	},
		// 	component: () => import('./_pen/_CompetitionEditPage.vue')
		// }
	]
})

router.afterEach((to, from) => {
	if (to.name !== null) {
		config.set({ global: { route: to.name } })
		// if (config.vals.global.remoteId) {
		// 	config.setRemoteId(config.vals.global.remoteId, displayType(to.name))
		// }
		document.title = to?.meta?.title || 'Ergrace Online'
	}
})

export default router
