
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({ components: {} })
export default class Chevron extends Vue {
	@Prop() active!: boolean

	get fill() {
		return this.active ? '#0567C1' : '#C8C8C8'
	}
	get opacity() {
		return this.active ? '0.4' : '1'
	}
}
