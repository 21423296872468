
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Competitions, CompetitionsState } from 'shared/state/Competitions'
import { CompetitionType } from 'shared/types/competition'
import { LogBookUser } from 'shared/state/LogBookUser'
import { prettyDate } from 'shared/util/formatDate'
import ViewportSize from '_display/ViewportSize'
import VueClipboard from 'vue-clipboard2'
import {} from 'bootstrap-vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faTrashAlt)

VueClipboard.config.autoSetContainer = true // add this line
Vue.use(VueClipboard)
Vue.component('font-awesome-icon', FontAwesomeIcon)

export interface Competition {
	id: number
	logBookId: number
	name: string
	competition_code: number
	date: Date
	races: any[]
}

@Component({ components: {} })
export default class RaceForm extends Vue {}

const subapp: any = []
function loadFile() {
	let input
	let file
	let fr

	if (typeof window.FileReader !== 'function') {
		alert('The file API isn\'t supported on this browser yet.')
		return
	}

	input = document.getElementById('fileinput')
	if (!input.files) {
		alert('This browser doesn\'t seem to support the `files` property of file inputs.')
	} else if (!input.files[0]) {
		alert('Please choose a file before clicking \'Load Race\'')
	} else {
		file = input.files[0]
		fr = new FileReader()
		fr.onload = receivedText
		fr.readAsText(file)
	}

	function receivedText(e) {
		const lines = e.target.result
		const newArr = JSON.parse(lines)
		subapp.race_definition = newArr.race_definition
		updateClasses(subapp.race_definition.boats)
	}
	function updateClasses(boats) {
		boats.forEach((boat) => {
			const found = subapp.classes.some((el) => {
				return el.name === boat.class_name
			})
			if (!found && boat.class_name) {
				subapp.classes.unshift({ name: boat.class_name, key: boat.class_name })
			}
		})
	}
}
