
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { AthleteStatus, ComStats, defaultAthleteStatus, ErgStatus } from 'shared/types/erg'
import Athlete from './Athlete.vue'
import { config, GlobalConfig } from 'display/config'
import Signal from './Signal.vue'
import { eligable } from 'shared/util/eligable'
import { RaceState } from 'shared/types/erg'
import { RemoteErgState, ErgState } from 'shared/state'
import { bus } from 'shared/state/Bus'

@Component({ components: { Signal } })
export default class CommunicationsStats extends Vue {
	@Prop() athleteStatus!: AthleteStatus
  @Prop() transitioning!: boolean
  @GlobalConfig() devMode!: boolean
  @ErgState() status!: RaceState

  warnings: string[] = []
  done: boolean = false

  get a() {
	return this.athleteStatus
  }

  get ergStatusKeys() {
	return this.a && this.a.erg_status ? Object.keys(this.a.erg_status).sort() : []
  }
  show() {
	this.$bvModal.show('modalCommStats-' + this.a.logbook_id)
  }

  get statsHtml() {
	if(!(this.a && this.a.erg_status )) {
	return this.transitioning ? 'Connecting to ErgRace' : this.done ? 'Not connected' : ''
  }
	if(this.a.erg_status.status === 'Offline' && !this.transitioning && this.done) { return 'no PM5' }
 if(!this.a.erg_status || this.a.erg_status.avg_rsp === '0') { return '' }
	return `${this.a.erg_status.avg_rsp}ms avg ${this.a.erg_status.max_rsp}ms max`
  }

  backToPen() {
	this.done = false
	setTimeout(() => {this.done = true}, 8000)
  }

  @Watch('athleteStatus', {immediate: true, deep: true})
  athleteStatusChanged(a) {
	if((a?.erg_status?.avg_rsp_log?.length || 0) > 14
  && (a?.com_stats?.bin_checksum && a?.com_stats?.bin_checksum !== 15) ) {
		this.warnings.push(`Checksum Failed: ${a.com_stats.bin_checksum} not 15. (${a.com_stats.error_bin} + ${a.com_stats.warning_bin} +  ${a.com_stats.ok_bin} + ${a.com_stats.good_bin})`)
	}
  }

  @Watch('status')
	statusChanged(newVal, oldValue) {
		this.done = false
		setTimeout(() => {this.done = true}, 8000)
	}

  created() {
	bus.$on('holding_pen', this.backToPen)
  }
  beforeDestroy() {
	bus.$off('holding_pen', this.backToPen)
  }

}
