
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import NewCompetitionForm from './NewCompetitionForm.vue'
import { Competitions, CompetitionsState } from 'shared/state/Competitions'
import { CompetitionType } from 'shared/types/competition'
import { Instance, RaceServer } from 'shared/types/logBook'
import { formatDate } from 'shared/util'
import ViewportSize from '_display/ViewportSize'
import VueClipboard from 'vue-clipboard2'
import CompetitionForm from './CompetitionForm.vue'
import { bus } from 'shared/state/Bus'
import { sortByKeyDesc } from 'shared/util'
import { LogBookUser } from 'shared/state'
import { config, GlobalConfig } from 'display/config'


VueClipboard.config.autoSetContainer = true // add this line
Vue.use(VueClipboard)

@Component({ components: { NewCompetitionForm, CompetitionForm } })
export default class CompetitionPageLanding extends Vue {
  @CompetitionsState() competitions!: CompetitionType[]
  @CompetitionsState() instances!: Instance[]
  @CompetitionsState() servers!: RaceServer[]
  @GlobalConfig() devMode!: boolean

  fields = [
	{ key: 'name', label: 'event' },
	{ key: 'code', label: 'code' },
	{ key: 'date', label: 'date' },
	{ key: 'competition_code', label: 'event code' },
  ]
  sorted: any = []
  creating = false
  deleting = false
  raceServers: any = []
  formatDate = formatDate

  get sortedCompetitions() {
	return this.competitions.slice().sort((a, b) => {
				return sortByKeyDesc(a, b, 'start')
			})
  }

  get dialogMessage() {
	return this.creating
	? 'Creating your competition and setting things up...'
	: 'Removing your competition and cleaning things up...'
  }

  async createCompetition(e: CompetitionType, rs?: string) {
	this.creating = true
	this.showDialog()
	const result = await Competitions.createCompetition(e, rs)
	.catch(err => err)

	this.creating = false
	this.hideDialog()

	if(this.devMode) { console.log(result) }

	if(typeof(result) === 'string') {
		Competitions.clearCurrentCompetition()
		this.$bvModal.msgBoxOk(result, {
		title: 'Error',
		size: 'sm',
		buttonSize: 'sm',
		okVariant: 'primary',
		okTitle: 'Ok',
		bodyClass: 'bdy',
		footerClass: 'p-2',
		hideHeaderClose: false,
		contentClass:'shadow',
		centered: true,
		noStacking: true,
		})
	}
  }

  hideDialog() {
	this.$bvModal.hide('newcompmodal')
  }

  showDialog() {
	const h = this.$createElement
	const messageVNode = [h('div', { class: ['msg'] }, [
		h('p', { class: ['text-center'] }, [
		h('strong', this.dialogMessage),
		]),
		h('p', { class: ['text-center'] }, [h('b-spinner')]),

		])]
	this.$bvModal.msgBoxOk(messageVNode, {
		id: 'newcompmodal',
		size: 'sm',
		bodyClass: 'bdy',
		centered: true,
		hideHeader: true,
		hideFooter: true,
		noCloseOnBackdrop: true,
		noCloseOnEsc: true,
		noStacking: true,
		footerClass: 'hidden',
		contentClass:'shadow'
	})
  }

  get onMobile(): boolean {
	return ViewportSize.width < 500
  }

  nav(path: string, subpage: string) {
	this.$router.push({ path: path + (subpage ? '/' + subpage + '/' : '') })
  }
  deleteComp() {
  this.deleting = true
  this.showDialog()
  }
  deleteDone() {
  this.deleting = false
  this.hideDialog()
  }
  async created() {
	   Competitions.getCompetitionsWithInstanceAndAdminsInfo()
	   this.raceServers = this.servers
	   bus.$on('deleting_competition', this.deleteComp)
	   bus.$on('deleting_competition_done', this.deleteDone)
  }

  beforeDestroy() {
  bus.$off('deleting_competition', this.deleteComp)
  bus.$on('deleting_competition_done', this.deleteDone)
  }

  @Watch('competitions', { deep: true, immediate: true })
  competitionsChanged(newVal) {
	this.creating = false
  }

}
