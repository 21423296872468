export interface IRaceStateObj {
	state: string
	message?: string
	error_message?: string
	btns: Array<{ text: string
		cmd: string
		variant?: string
		confirm?: boolean
		confirmMsg?: string
		confirmTitle?: string
		conditional?: boolean
		disappearWhenClicked?: boolean
		hidden?: boolean
		disabled?: boolean
		devMode?: boolean }>
}

const raceStateObject: IRaceStateObj[] = [
	{
		state: 'Athlete Status',
		message: 'reviewList',
		btns: [{ text: 'buttons.makeChanges', cmd: 'holding_pen', variant: 'warning', conditional: true},
			   { text: 'buttons.closeEntries', disappearWhenClicked: true, cmd: 'pre_close_entries' }]
	},
	{
		state: 'Warm Up',
		message: 'warmUp',
		btns: [{ text: 'buttons.makeChanges', cmd: 'holding_pen', variant: 'warning'},
			   { text: 'buttons.prepare', cmd: 'prepare' }]
	},
	{
		state: 'Prepare to Race',
		message: 'prepare',
		btns: [{ text: 'buttons.makeChanges', cmd: 'holding_pen' , variant: 'warning'},
			   { text: 'buttons.backToWarmUp', cmd: 'warmup', disabled: true},
			   { text: 'buttons.startRace', variant: 'success', cmd: 'start' }]
	},
	{
		state: 'Start Race',
		message: 'startRace',
		btns: [ { text: 'buttons.makeChanges', cmd: 'holding_pen', variant: 'warning'},
			{ text: 'buttons.stopRace',
			cmd: 'stop',
			variant: 'danger',
			confirm: true,
			confirmMsg: 'confirm.stopRace', confirmTitle: 'confirm.stopRaceTitle' },
			{ text: 'buttons.startRace', cmd: 'start', variant: 'success', disabled: true},
		]
	},
	{
		state: 'loading race',
		message: 'loadingRace',
		btns: []
	},
	{ state: 'Results', btns: [] },
	{
		state: 'stop rowing',
		message:'blankText',
		error_message:'errors.flyWheelsMoving',
		btns: [{ text: 'buttons.backToWarmUp', cmd: 'warmup', disabled: true}]
	},
	{
		state: 'sit ready',
		message: 'buttons.sitReady',
		btns:[ { text: 'buttons.makeChanges', cmd: 'holding_pen', variant: 'warning' },
		{ text: 'buttons.stopRace', cmd: 'stop', variant: 'danger', confirm: true,
		  confirmMsg: 'confirm.stopRace', confirmTitle: 'confirm.stopRaceTitle' }]
	},
	{
		state: 'attention',
		message: 'buttons.attention',
		btns:[ { text: 'buttons.makeChanges', cmd: 'holding_pen', variant: 'warning' },
		{ text: 'buttons.stopRace', cmd: 'stop', variant: 'danger', confirm: true,
		  confirmMsg: 'confirm.stopRace', confirmTitle: 'confirm.stopRaceTitle' }]
	},
	{
		state: 'race running',
		message: 'raceRunning',
		btns: [
			{ text: 'buttons.stopRace',
			  cmd: 'stop',
			  variant: 'danger',
			  confirm: true,
			  confirmMsg: 'confirm.stopRace',
			  confirmTitle: 'confirm.stopRaceTitle' }
		]
	},
	{
		state: 'false start',
		error_message: 'errors.falseStart',
		btns: [ { text: 'buttons.ok', cmd: 'prepare'}]
	},
	{
		state: 'race aborted',
		error_message: 'errors.raceAborted',
		btns: []
	},
]
export type raceStateObjType = IRaceStateObj
export const raceStateControls = raceStateObject
