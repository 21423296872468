import { AthleteStatus } from './AthleteStatus'

export const defaultAthleteStatus: AthleteStatus = {
	connected: false,
	name: '',
	logbook_id: '',
	ranking_id: '',

	client_OS_version: '',
	client_platform: '',
	client_version: '',

	erg_status: {
		avg_rsp: '0',
		battery: '',
		comm_err: '',
		erg_number: '',
		lane_number: '',
		max_rsp: '',
		name: '',
		pace: '',
		place: '',
		pm: '',
		pm_fw: '',
		pm_hw: '',
		ps: '' ,
		score: '',
		serial_number: '',
		status: 'Offline',
		to_go: '',
		type: 'row',
	},

	com_stats: {
		avg_ble_rsp: 0,
		avg_network_rsp: 0,
		max_ble_rsp: 0,
		max_network_rsp: 0,
		min_network_rsp: 0,
		min_ble_rsp: 0,
		quality: 0,
		error_bin: 0,
		warning_bin: 0,
		ok_bin: 0,
		good_bin: 0,
		bin_checksum: 0
	}
}



