
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import Header from './components/Header.vue'
import LoginPageContent from './components/LoginPageContent.vue'
import CompetitionDetail from './components/CompetitionDetail.vue'
import Footer from './components/Footer.vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { ServerState, ErgState, Erg } from 'shared/state'
import { Server } from 'shared/state'
import ServerRace from 'shared/components/ServerRace.vue'
import ServerPenConn from 'shared/components/ServerPenConn.vue'
import 'font-awesome/css/font-awesome.min.css'
import { LogBookUser, LogBookUserState } from 'shared/state/LogBookUser'
import { Competitions, CompetitionsState } from 'shared/state'
import { CompetitionType } from 'shared/types/competition'
import { Instance } from 'shared/types/logBook'
import { bus } from 'shared/state/Bus'
import { GlobalConfig } from 'display/config'
import { getRaceServer } from 'shared/util/getRaceServer'
import draggable from 'vuedraggable'

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false

@Component({
	components: {
		Header,
		CompetitionDetail,
		LoginPageContent,
		Footer,
		draggable
	}
})
export default class CompetitionPage extends Vue {
	@GlobalConfig() enableAnimation!: boolean
	@LogBookUserState() loggedIn!: boolean
	@Prop() loading!: boolean
	competition: CompetitionType|null = null
	found: boolean = false

	competitions: any = []
	hideFooter = false


	created() {
		if(this.loggedIn) {
			this.loadCompetition()
		}
		bus.$on('reloadcompetition', this.loadCompetition)
	}
	beforeDestroy() {
		bus.$off('reloadcompetition', this.loadCompetition)
	}

	get serverUrl() {
		return getRaceServer(Competitions.currentInstance?.ergrace_url || '')
	}

	async loadCompetition() {
		try {
			if(this.loggedIn) {
				this.hideFooter = true
				const h = this.$createElement
				const messageVNode = [h('div', { class: ['msg'] }, [
					h('p', { class: ['text-center'] }, [
						h('strong', 'Loading your competition'),
					]),
					this.enableAnimation && h('p', { class: ['text-center'] }, [h('b-spinner')]),

					])]
				this.$bvModal.msgBoxOk(messageVNode, {
						id: '1',
						size: 'sm',
						buttonSize: 'sm',
						bodyClass: 'bdy',
						centered: true,
						footerClass: 'hidden',
						noCloseOnBackdrop: true,
						noStacking: true
					})
				this.competitions = await Competitions.getCompetitionsWithInstanceAndAdminsInfo()

				if(this.competitions.map(c => c.code.toString()).includes(this.code)) {



					const result = await Competitions.getCompetition(this.code)
					this.hideFooter = false
					window.scrollTo(0, 0)
					if(typeof(result) === 'string') {
						this.$router.push({ path: '/' }).then(() => this.openDialog(result,   this.$t('errors.failure')))
						return
					}
					if (result.name) {
						this.competition = result
						this.found = true
						this.$bvModal.hide('1')
					}
				} else {
					this.$bvModal.hide('1')
					this.$router.push({ path: '/' }).then(() =>
					this.$bvModal.msgBoxOk('You do not have permission to access this competition', {
						title: 'Unauthorized',
						size: 'sm',
						buttonSize: 'sm',
						okVariant: 'primary',
						okTitle: 'Ok',
						bodyClass: 'bdy',
						footerClass: 'p-2',
						hideHeaderClose: false,
						'content-class':'shadow',
						centered: true,
						'no-stacking': true
					})
					)

				}
				return
			}
			window.location.href = '/'
		} catch {
			window.location.href = '/'
		}
	}
	get code() {
		return this.$route.params.code
	}

	openDialog(message, title) {
		this.$bvModal.msgBoxOk(message, {
			title,
			size: 'sm',
			buttonSize: 'sm',
			okVariant: 'outline-primary',
			okTitle: 'Ok',
			bodyClass: 'bdy',
			footerClass: 'p-2',
			hideHeaderClose: false,
			centered: true
		})
	}

	@Watch('loggedIn')
	loggedChanged(newVal) {
		if (newVal) {
			this.loadCompetition()
			Competitions.getCompetitionsWithInstanceAndAdminsInfo()
			return
		}
		this.$bvModal.hide('1')
	}
	@Watch('code')
	codeChanged(newVal) {
		this.loadCompetition()
	}
}
