
import { Component, Prop, Vue } from 'vue-property-decorator'
import { LogBookUser } from 'shared/state/LogBookUser'
import { Erg, ErgState } from 'shared/state/Erg'
import {
	RaceDefinition,
	Participant,
	RaceDefinitionBoat,
} from 'shared/types/erg/RaceDefinition'
import {
	AthleteStatus,
	defaultAthleteStatus,
} from 'shared/types/erg'
import Athlete from './Athlete.vue'
import { config, GlobalConfig } from 'display/config'
import Signal from './Signal.vue'

@Component({ components: { Athlete, Signal } })
export default class NotRegistered extends Vue {
	@Prop() notRegistered!: AthleteStatus[]
	@Prop() boats!: RaceDefinitionBoat[]
	@GlobalConfig() twoColThreshhold



	addAthlete(a: AthleteStatus) {
		this.$emit('addAthlete', a)
	}

}
