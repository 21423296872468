
import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
import { Competitions, CompetitionsState } from 'shared/state/Competitions'
import { CompetitionType } from 'shared/types/competition'
import { LogBookUser, LogBookUserState } from 'shared/state/LogBookUser'
import { prettyDate } from 'shared/util/formatDate'
import ViewportSize from '_display/ViewportSize'
import VueClipboard from 'vue-clipboard2'
import {} from 'bootstrap-vue'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { Instance, CompetitionInstance, AdminUser } from 'shared/types/logBook'
import RaceDetails from './RaceDetails.vue'
import { bus } from 'shared/state/Bus'
import Skeleton from './Skeleton.vue'
import { RaceServer } from 'shared/types/logBook'
import { GlobalConfig } from 'display/config'
import { getRaceServer } from 'shared/util'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import LogbookIdField from './LogbookIdField.vue'
import Admins from './Admins.vue'
import { Debounce } from 'vue-debounce-decorator'
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

VueClipboard.config.autoSetContainer = true // add this line
Vue.use(VueClipboard)
Vue.component('font-awesome-icon', FontAwesomeIcon)

@Component({ components: {Skeleton, Admins} })
export default class CompetitionForm extends Vue {
	@Prop() editMode!: boolean
	@Prop() e!: CompetitionType
	@Prop() race_details!: RaceDetails
	@CompetitionsState('servers') raceServers!: RaceServer[]

	@GlobalConfig() enableAnimation!: boolean
	@CompetitionsState('instances') instances!: Instance[]
	@CompetitionsState('intialLoading') intialLoading!: boolean
	@LogBookUserState() logBookUserInfo

	comps = Competitions

	form: any = { administrators: [], public: false, promote: false }
	newAdminEmail = ''
	hideNewAdminEmail = true
	updateSpinning = false
	deleteSpinning = false
	runSpinning = false
	gone = false // to immediately hide form after competition is deleted

	valid = false
	submitted = false

	isEditMode: boolean = this.editMode

	admins: AdminUser[] = []

	fields = [
		{ key: 'name', label: 'event' },
		{ key: 'date', label: 'date' },
		{ key: 'competition_code', label: 'event code' }
	]
	raceServer = ''
	serverselectionchanged = false
	oldServer = ''

	newNum: number = 0

	timedOut = 0
	cancelForm = {}

	async runCompetition(code: string) {
		if(!this.displayUrl.length) {
			// the instance was never determined when compeitition created,
			// for example, if it was created when the max concurrent instances was exceeded
			if(this.isAdmin) {
				this.openDialog('You must select a race server before you can run this competition.', 'Action Required')
			} else {
				const temp = this.raceServer || this.raceServers.find(s => s.type === 'general')?.name
				if(!temp) {
					this.openDialog('No available race server to run your competition. Try again later.', 'Alert')
					return
				}
				this.runSpinning = true
				const result = await Competitions.createOrRenewInstance(this.form.code, temp, true)
				this.runSpinning = false
				if(typeof(result) === 'string') {
							this.cancel()
							this.raceServer = this.serverUrl
							this.openDialog(result,   this.$t('errors.failure'))
						} else {
							this.$router.push({ path: `/competition/${code}`, query: { id: this.myId }})
						}

			}

		} else {
			this.$router.push({ path: `/competition/${code}`, query: { id: this.myId }})
		}
	}
	get serverAvailable() {
		return !!LogBookUser.raceServers.find(rs => rs.name.includes(this.raceServer))
	}

	selectionChanged() {
		this.serverselectionchanged = true
	}
	@Debounce(100)
	async showCompetitionChanged() {
		if(!this.timedOut || this.isEditMode) { return }
		await Competitions.updateCompetition(this.form, this.raceServer)
	}

	get isAdmin() {
		return this.logBookUserInfo.roles.includes('ergrace-admin')
	}

	get options() {
		return this.raceServers.map(s => ({ value: s.name, text: `${s.name} (${s.type}) ` }))
	}

	async addAdmin() {
		if(this.newNum) {
			const result: any = await LogBookUser.addAdmin(this.e.code, this.newNum)
			if(result.id) {
				this.admins.push(result)
			}
		}
	}

	async removeAdmin(logBookId: number) {
			const result = await LogBookUser.removeAdmin(this.e.code, logBookId)
			if(result) {
			const foundIndex = this.admins.findIndex(a => a.id === logBookId)
			if(foundIndex !== -1) { this.admins.splice(foundIndex, 1) }
		}
	}

	initialize() {
		this.form = {
			// handle_false_start: this.form.handle_false_start
			// 	? this.form.handle_false_start
			// 	: 'race stops',
			administrators: [],
			...this.e
		}
		this.raceServer = this.serverUrl
		this.admins = this.e.admins?.data || []
	}

	get myId() {
		return document.location.search.replace('?id=', '')
	}

	edit() {
		this.isEditMode = true
		this.cancelForm = {...this.form }
	}
	xEditMode(bln) {
		this.isEditMode = bln
	}

	created() {
		this.initialize()
		bus.$on('edit-mode', this.xEditMode)
		setTimeout(() => { this.timedOut = 1 }, 3000)
	}
	beforeDestroy() {
		bus.$off('edit-mode', this.xEditMode)
	}

	get onMobile(): boolean {
		return ViewportSize.width < 500
	}

	doCopy(text: string) {
		console.log(text)
		this.$copyText(text)
	}

	async deleteCompetition(code: string) {
		this.$bvModal
			.msgBoxConfirm(
				`Please confirm that you want to delete this competition. This will remove the competition and all races associated with it. This action cannot be undone.`,
				{
					title: 'Confirm Removal',
					size: 'sm',
					buttonSize: 'sm',
					okVariant: 'success',
					cancelVariant: 'danger',
					okTitle: 'Remove',
					cancelTitle: 'Cancel',
					bodyClass: 'bdy',
					footerClass: 'p-2',
					hideHeaderClose: false,
					centered: true
				}
			)
			.then(async (confirm) => {
				if (confirm) {
					this.deleteSpinning = true
					bus.$emit('deleting_competition')
					Competitions.deleteCompetition(code).then((result) => {
						if (result) {
							this.gone = true
							Competitions.getCompetitionsWithInstanceAndAdminsInfo()
						} else {
							this.gone = false
							this.openDialog('Delete Failed. Try Again.',   this.$t('errors.failure'))
						}
						this.deleteSpinning = false
						bus.$emit('deleting_competition_done')
					})
				}
			})
			.catch((err) => {
				this.openDialog('Delete Failed. Try Again.',   this.$t('errors.failure'))
				this.deleteSpinning = false
				this.gone = false
				bus.$emit('deleting_competition_done')
			})
	}


	get displayUrl() {
		return this.instances.find(i => i.competition_code === this.e.code)?.display_url || ''
	}
	get serverUrl() {
		const server = this.instances.find(i => i.competition_code === this.e.code)?.ergrace_url || ''

		return getRaceServer(server)
	}

	prettyUrl(url: string) {
		return url.replace('https://', '').replace('http://', '')
	}

	async onSubmit(evt) {
		this.updateSpinning = true
		this.valid = true
		if (this.valid) {
			this.submitted = true

			await Competitions.updateCompetition(this.form, this.raceServer).then(async (result: any) => {
				this.updateSpinning = false
				if (result) {
					this.isEditMode = false
					if(this.serverselectionchanged) {
						const temp = this.raceServer
						// oldServer = '' means new Competition
						// oldServer not included in raceServers means
						// no need to shut it down, since the server cannot be reached
						const raceServers = LogBookUser.raceServers
						const oldServerResponded = !!LogBookUser.raceServers.find(rs => rs.name.includes(this.oldServer))

						if(this.oldServer !== '' && oldServerResponded) {
							await Competitions.deleteInstance(this.form.code)
						}

						// if(!result1) {
						// 	this.openDialog(this.$t('errors.competitionUpdateServerChangedFail'),   this.$t('errors.failure'))
						// 	return
						// }
						const result = await Competitions.createOrRenewInstance(this.form.code, temp, true)
						bus.$emit('refresh_competition_request', this.form.code)
						if(typeof(result) === 'string') {
							this.raceServer = this.serverUrl
							this.openDialog(result,   this.$t('errors.failure'))
							this.cancel()
						}
						this.serverselectionchanged = false
					}
				} else {
					this.openDialog(this.$t('errors.competitionUpdateFail'),   this.$t('errors.failure'))
				}
			}).catch(() => {
				this.openDialog(this.$t('errors.competitionUpdateFail'),   this.$t('errors.failure'))
			})

		}
	}

	openDialog(message, title) {
		this.$bvModal.msgBoxOk(message, {
			title,
			size: 'sm',
			buttonSize: 'sm',
			okVariant: 'outline-primary',
			okTitle: 'Ok',
			bodyClass: 'bdy',
			footerClass: 'p-2',
			hideHeaderClose: false,
			centered: true
		})
	}

	cancel() {
		this.form = { ...this.cancelForm }
		this.raceServer = this.serverUrl
		this.isEditMode = false
	}
	prettyDate(dt: string) {
		return prettyDate(dt)
	}

	@Watch('e', { deep: true, immediate: true })
	competitionChanged(newVal) {
		return
	}

	@Watch('instances', { deep: true, immediate: true })
	instancesChanged(newVal) {
		if(!this.serverselectionchanged) { this.raceServer = this.serverUrl }
	}

	@Watch('raceServer', { deep: true, immediate: true })
	raceServerChanged(newVal, oldVal) {
		if(!this.serverselectionchanged) { this.oldServer = oldVal }
	}

	reformat(code) {
	  return  code.replace(/ /g, '-')
	}
	get running() {
		return !!this.raceServers.find(s => s.runningInstances?.find(i => i.name.includes(this.e.code)))
	}

}
