export function setCookie(cname, cvalue, exdays) {
	const d = new Date()
	d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000))
	const expires = 'expires=' + d.toUTCString()
	document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/'
  }
export function setCookieInMinutes(cname, cvalue, minutes) {
	const d = new Date()
	d.setTime(d.getTime() + (minutes * 24 * 60 ))
	const expires = 'expires=' + d.toUTCString()
	document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/'
  }

export function getCookie(cname) {
	const name = cname + '='
	const ca = document.cookie.split(';')
	for (let c of ca) {
		while (c.charAt(0) === ' ') {
		c = c.substring(1)
		}
		if (c.indexOf(name) === 0) {
		return c.substring(name.length, c.length)
		}
	}
	return ''
  }

