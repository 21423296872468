
import { Component, Vue, Prop } from 'vue-property-decorator'
import { CompetitionType } from 'shared/types/competition'
import { Competitions, CompetitionsState } from 'shared/state/Competitions'
import RaceForm from './RaceForm.vue'
import RaceDetails from './RaceDetails.vue'
// import RaceLoader from './RaceLoader.vue'
import Boats from './Boats.vue'
import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'
import { RaceListItem } from 'shared/types/racelistitem'
import { DurationType, RaceDefinition, RaceType } from 'shared/types/erg/RaceDefinition'
import { bus } from 'shared/state/Bus'
import { spinner } from 'vue-spinner'
import { RaceWithRac, RaceFormType } from 'shared/types/competition'
import { watch } from 'fs'
import SpinLoad from 'shared/components/SpinLoad.vue'
import { GlobalConfig } from 'display/config'

Vue.use(VueMaterial)

Vue.config.productionTip = false

@Component({
	components: {
		// RaceLoader,
		RaceForm,
		RaceDetails,
		Boats,
		SpinLoad
	}
})
export default class TabRaceListRaceDetail extends Vue {
	@Prop() race!: RaceFormType
	@Prop() editMode!: boolean
	@GlobalConfig() devMode!: boolean

	isEditMode = this.editMode
	spinning: boolean = false
	showAlert: boolean = false

	boatsIsInvalid = false
	detailsIsInvalid = false

	get isInvalid(): boolean {
		return this.boatsIsInvalid || this.detailsIsInvalid
	}

	errMsg: string[] = []
	formUtil: any = {
		  updateRace: async (myrace, ths) => {
			const copyRace = JSON.parse(JSON.stringify(myrace))
			if(this.devMode) { console.log('on save here: ', myrace) }
			delete copyRace.race_definition
			delete copyRace.event_name
			bus.$emit('updateRace', copyRace)
			bus.$emit('cancel')
		},
		  saveNewRace: async (newrace, ths) => {
			const copyRace = JSON.parse(JSON.stringify(newrace))
			if(this.devMode) { console.log('copyRace', copyRace) }
			const tempRace = copyRace
			const raceTemp = `{
			"id":0,
			"competition":${Competitions.currentCompetition.code},
			"order": ${!Competitions.currentCompetition.races.data.length ? 1
			: Math.max.apply(Math, Competitions.currentCompetition.races.data.map((o) => o.order)) + 1 },
			"boats":${newrace.rac.data.race_definition.boats.length},
			"name":"${newrace.rac.data.race_definition.name_short}",
			"projected":"${newrace.projected || newrace.scheduled || ''}",
			"scheduled":"${newrace.scheduled || ''}",
			"automatic":${!!newrace.automatic || false},
			"sweep":${!!newrace.sweep || true},
			"rac":{
					"data": ${JSON.stringify(newrace.rac.data)}
				}
			}`

			bus.$emit('addRace', JSON.parse(raceTemp))
			bus.$emit('cancel')
		}
	}
	@CompetitionsState() competitions!: CompetitionType[]

	classes: any[] = []

	async onSubmit(evt) {
		evt.preventDefault()
		this.spinning = true
		this.race.event_name = Competitions.currentCompetition.name
		this.race.race_definition = (this.race.rac) ? this.race.rac.data.race_definition : this.race.race_definition

		// Prune empty boats. Boats must be named or have an ID or contain at
		// least one participant with a name or ID.
		if (this.race.race_definition) {
			this.race.race_definition.boats = this.race.race_definition.boats.filter(boat => (
				boat.name || (
					boat.logbook_id || boat.participants?.filter(p => p.name || p.logbook_id).length
				)
			))
		}

		if (this.race.id) {
			this.formUtil.updateRace(this.race, this)
		} else {
			this.formUtil.saveNewRace(this.race, this)
		}
	}

	created() {
		const boats = this.race.rac?.data.race_definition.boats || []
		this.classes = boats.map((b) => ({
			name: b.class_name,
			key: b.class_name
		}))
		if(!this.race.automatic) {
			// this.race.automatic = false
			// this.race.sweep = true
		}
	}

	cancel() {
		bus.$emit('cancel')
	}

	onValidityChanged(newValidity: boolean): void {
		this.detailsIsInvalid = !newValidity
	}

	onBoatsValidityChanged(newValidity: boolean): void {
		this.boatsIsInvalid = !newValidity
	}

	get teamSize() {
		return this.race.rac?.data.race_definition.team_size
	}
}
